export const GET_PATIENT_BP_CUSTOM_PARAMETER_REQUEST = "GET_PATIENT_BP_CUSTOM_PARAMETER_REQUEST"
export const GET_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS = "GET_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS"
export const GET_PATIENT_BP_CUSTOM_PARAMETER_FAIL = "GET_PATIENT_BP_CUSTOM_PARAMETER_FAIL"

export const GET_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST = "GET_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST"
export const GET_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS = "GET_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS"
export const GET_PATIENT_OXI_CUSTOM_PARAMETER_FAIL = "GET_PATIENT_OXI_CUSTOM_PARAMETER_FAIL"

export const GET_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST = "GET_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST"
export const GET_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS = "GET_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS"
export const GET_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL = "GET_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL"