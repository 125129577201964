import axios from "axios"

export const TempAxiosGet = async (token, url) => {
  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  } catch (err) {
    // console.log('error-=-->',err)
    window.webkit?.messageHandlers.logout.postMessage({ value: "harish" })
  }
}

export const TempAxiosPost = async (token, payload, url) => {
  try {
    const { data } = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  } catch (err) {
    // console.log('error--post',err)
    window.webkit?.messageHandlers.logout.postMessage({ value: "harish" })
  }
}
