import { useEffect, useState } from "react"

import moment from "moment"
import { Modal, List } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { getManualLogTrack } from "../../redux/actions/patientAction"

import Loading from "../layouts/Loading/Loading"

const PatientManualLogModal = props => {
  const { manualLogOpen, setManualLogOpen, patientDetails, manualCountType } = props
  const dispatch = useDispatch()
  const { loading, manualLogTrack } = useSelector(state => state.patientManualLogTrack)
  const [filteredManualLog, setFilteredManualLog] = useState([])
  const [IsfilterLoading, setIsFilterLoading] = useState(true)

  useEffect(() => {
    if (manualLogOpen && patientDetails ) {
      dispatch(getManualLogTrack(patientDetails?.id))
    }
    if(!manualLogOpen)
    {
      dispatch({type: 'GET_PATIENT_MANUAL_LOG_RESET'})
      setFilteredManualLog([])
    }
  }, [manualLogOpen, dispatch])

  useEffect(() => {
    setIsFilterLoading(true)
    if(!loading && manualLogTrack?.length){
      if(manualCountType === "rpm")
      {
        setFilteredManualLog(() => manualLogTrack?.filter(manual => manual.careType === "rpm"))
      }else if(manualCountType === "ccm")
      {
        setFilteredManualLog(() => manualLogTrack?.filter(manual => manual.careType === "ccm"))
      }else {
        setFilteredManualLog(() => [...manualLogTrack])
      }
      setIsFilterLoading(false)
    }
  }, [loading, dispatch])


  return (
    <Modal
      footer={null}
      open={manualLogOpen}
      onCancel={() => setManualLogOpen(false)}
      title={<h4 className="text-xl capitalize">{patientDetails?.name || "Patient"} Log</h4>}>
      <div className="pt-4">
        {loading && IsfilterLoading? (
          <Loading />
        ) : filteredManualLog?.length && filteredManualLog?.filter(i => i?.count > 0)?.length ? (
          <List
            dataSource={filteredManualLog?.filter(i => i?.count > 0)}
            renderItem={(values, index) => {
              const isLastItem = filteredManualLog.filter(i => i?.count > 0).length - 1 === index
              return (
                <div className={`mb-3 ${!isLastItem ? "border-b-2 border-current pb-3" : ""}`}>
                  <div className="flex items-center gap-2">
                    <span className="min-w-[80px]">Count: </span>
                    <span className="font-medium">{values?.count} Mins</span>
                  </div>
                  <div className="flex items-center gap-2 my-1">
                    <span className="min-w-[80px]">Care Type: </span>
                    <span className="font-medium uppercase">{values?.careType}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="min-w-[80px]">Care Date: </span>
                    <span className="font-medium uppercase">
                      {new Date(values?.careDate).toISOString().substring(0, 10)}{" "}
                      {moment.utc(values?.careDate).format("HH:mm a")}
                    </span>
                  </div>
                </div>
              )
            }}
          />
        ) : (
          <div className="text-center text-xl">No Time Logged for this patient!!</div>
        )}
      </div>
    </Modal>
  )
}

export default PatientManualLogModal

