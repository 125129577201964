import React, { useMemo, useState } from "react"
import { Button, Divider, Dropdown, Radio, Space, theme } from "antd"
import { DownOutlined } from "@ant-design/icons"
import OnboardingActionConfirmationModal from "./OnboardingActionConfirmationModal"
import {
  BulkMessageMailActionIntroduction,
  BulkMessageMailActionWelcome,
  OnBoardingPendingStage
} from "./Types"

const { useToken } = theme

const OnboardingStageActionDropdown = props => {
  const { onboardingSelectedStage, onBoardingStageSelectionClear, selectedRowKeys } = props
  const { token } = useToken()
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState()
  const [dropDownActionText, setDropDownActionText] = useState()
  const [actionOptions, setActionOptions] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary
    // width: "140%"
  }

  useMemo(() => {
    if (onboardingSelectedStage === OnBoardingPendingStage.SignUpIntiated) {
      setDropDownActionText("Send Bulk Mails/Messages")
      setActionOptions(BulkMessageMailActionWelcome)
    } else if (onboardingSelectedStage === OnBoardingPendingStage.SignUpPending) {
      setDropDownActionText("Send Bulk Mails/Messages")
      setActionOptions(BulkMessageMailActionIntroduction)
    }
  }, [onboardingSelectedStage])
  const handleApply = () => {
    setIsModalOpen(true)
    setOpen(false)
    // setCaretakerAssigned(values)
    // onCaretakerSelection(selectedCaretakers, values)
  }

  const onOpenChange = open => {
    // if (!open) {
    //   setValues(action)
    // }
    setOpen(open)
  }

  const onDropdownSelectionChange = e => {
    setValues(e.target.value)
  }
  return (
    <>
      <Dropdown
        open={open}
        onOpenChange={onOpenChange}
        destroyPopupOnHide
        menu={{ items: [] }}
        trigger={["click"]}
        dropdownRender={_ => (
          <div style={contentStyle}>
            <div className="pt-2 px-4 mr-8">
              <Radio.Group onChange={onDropdownSelectionChange} value={values}>
                <Space direction="vertical">
                  {Object.keys(actionOptions)?.map(key => (
                    <Radio key={key} value={key}>
                      {actionOptions[key]}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </div>
            <Divider className="my-3" />

            <div className="px-8 pb-4 flex justify-center">
              <Button type="primary" onClick={handleApply}>
                Done
              </Button>
            </div>
          </div>
        )}>
        <Button
          className="color-primary"
          style={{
            // minWidth: 150,
            height: "inherit"
          }}>
          <div className="flex items-center justify-center gap-2">
            <>
              {dropDownActionText}
              <DownOutlined />
            </>
          </div>
        </Button>
      </Dropdown>
      {isModalOpen && (
        <OnboardingActionConfirmationModal
          selectedRowKeys={selectedRowKeys}
          setIsModalOpen={setIsModalOpen}
          fromDropdown={dropDownActionText}
          selectedStage={values}
          onBoardingStageSelectionClear={onBoardingStageSelectionClear}
        />
      )}
    </>
  )
}

export default OnboardingStageActionDropdown
