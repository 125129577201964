import React from "react"
// import AddIcon from "@mui/icons-material/Add";
// import { Button } from "@mui/material";

const handleTextAreaAutoSize = ref => {
  if (ref && ref?.scrollHeight !== null && ref?.scrollHeight > 0) {
    ref.style.height = ref.scrollHeight + 10 + "px"
  }
}

function CCMViewFormInput({
  ques,
  handleChange,
  handleInput,
  handleAddInputField,
  otherInputs,
  setFormInputs
}) {
  // console.log("ques", ques);
  // console.log("quesType", ques.quesType);
  // console.log("ques-Value-type", typeof ques.value);

  switch (ques.quesType) {
    case "text":
      return ques.value !== undefined && ques.notApplicable === false ? (
        <div style={{ marginTop: "10px" }}>
          <textarea
            disabled
            id={ques.id}
            ref={handleTextAreaAutoSize}
            style={{ width: "100%", padding: "10px 0px", resize: "none", whiteSpace: "pre-wrap" }}
            // value={ques.value ? ques.value : ""}
            onChange={e => {
              handleChange(ques.id, e)
            }}>
            {ques.value ? ques.value : ""}
          </textarea>
        </div>
      ) : (
        <div className="d-flex">
          <input
            disabled="true"
            style={{ marginRight: "5px" }}
            type="checkbox"
            // id={`custom-checkbox-${index}`}
            name="dsa"
            checked={true}
            // onChange={(e) => handleChange(ques.id, e, index)}
          />
          <label style={{ fontSize: "12px" }}>Not Applicable</label>
        </div>
      )
    case "multi":
      return ques.value !== undefined && ques.notApplicable === false ? (
        ques.questionaryOptions?.map((opt, index) => (
          <div style={{ marginTop: "10px" }}>
            <input
              disabled="true"
              style={{ marginRight: "5px" }}
              type="checkbox"
              id={`custom-checkbox-${index}`}
              name="dsa"
              checked={ques.value ? ques.value[index] : false}
              onChange={e => handleChange(ques.id, e, index)}
            />
            <label style={{ fontSize: "12px" }}>{opt.name}</label>
            <br />
            {opt.name.trim() === "other" && ques.value && ques.value[index] === true && (
              <textarea
                // type="text"
                disabled="true"
                ref={handleTextAreaAutoSize}
                style={{
                  width: "100%",
                  padding: "10px 0px",
                  marginTop: "10px",
                  resize: "none",
                  whiteSpace: "pre-wrap"
                }}
                // value={otherInputs[ques.id] ? otherInputs[ques.id].value : ""}
                onChange={e => {
                  setFormInputs({
                    ...otherInputs,
                    [ques.id]: { value: e.target.value }
                  })
                }}>
                {/* {ques.value['other']} */}
                {ques.value[ques.questionaryOptions.length + 1]?.other &&
                  ques.value[ques.questionaryOptions.length + 1].other}
                {/*{otherInputs[ques.id] ? otherInputs[ques.id].value : ""}*/}
              </textarea>
            )}
          </div>
        ))
      ) : ques.ccmType === "CONSENT" ? (
        <>
          {ques.questionaryOptions?.map((opt, index) => (
            <div style={{ marginTop: "10px" }}>
              <input
                disabled="true"
                style={{ marginRight: "5px" }}
                type="checkbox"
                id={`custom-checkbox-${index}`}
                name="dsa"
                checked={ques.value ? ques.value[index] : false}
                onChange={e => handleChange(ques.id, e, index)}
              />
              <label style={{ fontSize: "12px" }}>{opt.name}</label>
              <br />
              {opt.name.trim() === "other" && ques.value && ques.value[index] === true && (
                <textarea
                  // type="text"
                  disabled="true"
                  ref={handleTextAreaAutoSize}
                  style={{
                    width: "100%",
                    padding: "10px 0px",
                    marginTop: "10px",
                    resize: "none",
                    whiteSpace: "pre-wrap"
                  }}
                  // value={otherInputs[ques.id] ? otherInputs[ques.id].value : ""}
                  onChange={e => {
                    setFormInputs({
                      ...otherInputs,
                      [ques.id]: { value: e.target.value }
                    })
                  }}>
                  {/* {ques.value['other']} */}
                  {ques.value[ques.questionaryOptions.length + 1]?.other &&
                    ques.value[ques.questionaryOptions.length + 1].other}
                  {/*{otherInputs[ques.id] ? otherInputs[ques.id].value : ""}*/}
                </textarea>
              )}
            </div>
          ))}
        </>
      ) : (
        <div className="d-flex">
          <input
            disabled="true"
            style={{ marginRight: "5px" }}
            type="checkbox"
            // id={`custom-checkbox-${index}`}
            name="dsa"
            checked={true}
            // onChange={(e) => handleChange(ques.id, e, index)}
          />
          <label style={{ fontSize: "12px" }}>Not Applicable</label>
        </div>
      )
    case "bool-text":
      return ques.value !== undefined && ques.notApplicable === false ? (
        <div style={{ marginTop: "10px" }}>
          <div style={{ display: "flex" }}>
            <div
              className="form-input"
              style={{
                display: "flex",
                alignItem: "center",
                marginRight: "20px",
                marginBottom: 0
              }}>
              <input
                type="radio"
                disabled={true}
                id={ques.id}
                value={ques.value}
                name={ques.id}
                size="100"
                checked={ques?.value?.toString() === "true"}
                style={{ marginRight: "5px" }}
                onChange={e => handleChange(ques.id, e)}
              />
              <label style={{ fontSize: "14px" }}>YES</label>
            </div>
            <div
              className="form-input"
              style={{
                display: "flex",
                alignItem: "center",
                marginRight: "20px"
              }}>
              <input
                type="radio"
                disabled="true"
                id={ques.id}
                value={ques.value}
                name={ques.id}
                size="100"
                checked={ques?.value?.toString() === "false"}
                style={{ marginRight: "5px" }}
                onChange={e => handleChange(ques.id, e)}
              />
              <label style={{ fontSize: "14px" }}>NO</label>
            </div>
          </div>
          {((ques.onCondition && ques.value) || (!ques.onCondition && !ques.value)) &&
            ques.value !== undefined &&
            ques.questionaryOptions?.map((que, index) => (
              <div key={index} className="d2">
                <label
                  style={{
                    marginTop: "20px",
                    fontSize: "14px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "10px"
                  }}>
                  {que.name}
                </label>
                <textarea
                  id={que.id}
                  // type="text"
                  disabled="true"
                  style={{
                    width: "100%",
                    padding: "10px 0px",
                    resize: "none",
                    whiteSpace: "pre-wrap"
                  }}
                  ref={handleTextAreaAutoSize}
                  // value={que.value ? que.value : ""}
                  onChange={e => {
                    handleInput(ques.id, que.id, e)
                  }}>
                  {que.value ? que.value : ""}
                </textarea>
              </div>
            ))}
        </div>
      ) : (
        <div className="d-flex">
          <input
            disabled="true"
            style={{ marginRight: "5px" }}
            type="checkbox"
            // id={`custom-checkbox-${index}`}
            name="dsa"
            checked={true}
            // onChange={(e) => handleChange(ques.id, e, index)}
          />
          <label style={{ fontSize: "12px" }}>Not Applicable</label>
        </div>
      )
    case "bool":
      return ques.value !== undefined && ques.notApplicable === false ? (
        ques.questionaryOptions?.length !== 0 ? (
          ques.questionaryOptions?.map((opt, index) => (
            <div style={{ display: "flex", marginBottom: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  marginRight: "20px",
                  marginBottom: 0
                }}>
                <input
                  type="radio"
                  disabled="true"
                  id={ques.id}
                  value="true"
                  name={ques.id}
                  checked={ques.value ? ques.value[index] : false}
                  style={{ marginRight: "5px" }}
                  size="100"
                  onChange={e => handleChange(ques.id, e)}
                />
                <label style={{ fontSize: "14px", marginRight: "16px" }}>YES</label>
                <input
                  type="radio"
                  disabled="true"
                  id={ques.id}
                  value="false"
                  name={ques.id}
                  checked={ques.value ? ques.value[index] : false}
                  size="100"
                  style={{ marginRight: "5px" }}
                  onChange={e => handleChange(ques.id, e)}
                />
                <label style={{ fontSize: "14px" }}>NO</label>
              </div>
            </div>
          ))
        ) : (
          <div style={{ display: "flex", marginBottom: "30px" }}>
            <div
              style={{
                display: "flex",
                alignItem: "center",
                marginRight: "20px",
                marginBottom: 0
              }}>
              <input
                type="radio"
                disabled="true"
                id={ques.id}
                value="true"
                name={ques.id}
                checked={ques?.value?.toString() === "true"}
                style={{ marginRight: "5px" }}
                size="100"
                onChange={e => handleChange(ques.id, e)}
              />
              <label style={{ fontSize: "14px", marginRight: "16px" }}>YES</label>
              <input
                type="radio"
                disabled="true"
                id={ques.id}
                value="false"
                name={ques.id}
                checked={ques?.value?.toString() === "false"}
                size="100"
                style={{ marginRight: "5px" }}
                onChange={e => handleChange(ques.id, e)}
              />
              <label style={{ fontSize: "14px" }}>NO</label>
            </div>
          </div>
        )
      ) : (
        <div className="d-flex">
          <input
            disabled="true"
            style={{ marginRight: "5px" }}
            type="checkbox"
            // id={`custom-checkbox-${index}`}
            name="dsa"
            checked={true}
            // onChange={(e) => handleChange(ques.id, e, index)}
          />
          <label style={{ fontSize: "12px" }}>Not Applicable</label>
        </div>
      )
    case "text-multi":
      if (ques["value"] === undefined) {
        ques["value"] = [""]
      }
      return ques.value !== undefined && ques.notApplicable === false ? (
        <div style={{ marginTop: "10px" }}>
          {ques["value"]?.map((val, index) => {
            // debugger
            return (
              <textarea
                id={ques.id}
                // type="text"

                ref={handleTextAreaAutoSize}
                disabled="true"
                style={{
                  width: "100%",
                  resize: "none",

                  padding: "10px 0px",
                  whiteSpace: "pre-wrap",
                  marginBottom: "8px"
                }}
                value={ques["value"][index] !== "" ? ques["value"][index] : ""} //prev => Not added anything! in place of ""
                // value={que["value"] ? que["value"][ind] : ""}
                onChange={e => {
                  handleChange(ques.id, e, index)
                }}
              />
              /*<textarea
                            style={{ width: "100%", marginBottom: "8px" }}
                            id={ques.id}
                            // type="text"
                            disabled="true"
                            defaultValue={ques.value[index].length !== '' ? ques.value[index] : 'Nothing is selected'}
                            // value={ques.value ? ques.value[index] : ""}
                            onChange={(e) => handleChange(ques.id, e, index)}
                        />*/
            )
          })}
        </div>
      ) : (
        <div className="d-flex">
          <input
            disabled="true"
            style={{ marginRight: "5px" }}
            type="checkbox"
            // id={`custom-checkbox-${index}`}
            name="dsa"
            checked={true}
            // onChange={(e) => handleChange(ques.id, e, index)}
          />
          <label style={{ fontSize: "12px" }}>Not Applicable</label>
        </div>
      )
    case "bool-text-multi":
      ques.questionaryOptions.forEach((que, index) => {
        if (que["value"] === undefined) {
          que["value"] = [""]
        }
      })
      return ques.value !== undefined && ques.notApplicable === false ? (
        <div style={{ marginBottom: "20px" }}>
          <div style={{ display: "flex" }}>
            <div
              className="form-input"
              style={{
                display: "flex",
                alignItem: "center",
                marginRight: "20px",
                marginBottom: "16px"
              }}>
              <input
                type="radio"
                disabled="true"
                id={ques.id}
                value="true"
                name={ques.id}
                size="100"
                checked={ques?.value?.toString() === "true"}
                style={{ marginRight: "5px" }}
                onChange={e => handleChange(ques.id, e)}
              />
              <label style={{ fontSize: "14px" }}>YES</label>
            </div>
            <div
              className="form-input"
              style={{
                display: "flex",
                alignItem: "center",
                marginRight: "20px",
                marginBottom: "16px"
              }}>
              <input
                type="radio"
                disabled="true"
                id={ques.id}
                value="false"
                name={ques.id}
                size="100"
                checked={ques?.value?.toString() === "false"}
                style={{ marginRight: "5px" }}
                onChange={e => handleChange(ques.id, e)}
              />
              <label style={{ fontSize: "14px" }}>NO</label>
            </div>
          </div>
          {((ques.onCondition && ques.value) || (!ques.onCondition && !ques.value)) &&
            ques.value !== undefined &&
            ques.questionaryOptions?.map((que, index) => {
              return (
                <div key={index} className="d2">
                  <h5 style={{ marginBottom: "10px", fontSize: "14px" }}>{que.name}</h5>
                  {que["value"]?.map((val, ind) => (
                    <textarea
                      id={que.id}
                      ref={handleTextAreaAutoSize}
                      // type="text"
                      disabled="true"
                      style={{
                        width: "100%",
                        resize: "none",
                        padding: "10px 0px",
                        whiteSpace: "pre-wrap",
                        marginBottom: "10px"
                      }}
                      // value={que["value"] ? que["value"][ind] : ""}
                      onChange={e => {
                        handleInput(ques.id, que.id, e, ind)
                      }}>
                      {que["value"] ? que["value"][ind] : ""}
                    </textarea>
                  ))}
                  {/* <Button
                    id={ques.id}
                    onClick={(e) => handleAddInputField(ques.id, true, index)}
                    variant="contained"
                    style={{
                      backgroundColor: "#D8D7D7",
                      marginTop: "10px",
                      color: "#838181",
                      marginBottom: "-30px",
                      textTransform: "capitalize",
                    }}
                    startIcon={
                      <AddIcon
                        style={{
                          color: "#fff",
                          backgroundColor: "#2E5276",
                          borderRadius: "50%",
                        }}
                      />
                    }
                  >
                    Add another
                  </Button> */}
                </div>
              )
            })}
        </div>
      ) : (
        <div className="d-flex">
          <input
            disabled="true"
            style={{ marginRight: "5px" }}
            type="checkbox"
            // id={`custom-checkbox-${index}`}
            name="dsa"
            checked={true}
            // onChange={(e) => handleChange(ques.id, e, index)}
          />
          <label style={{ fontSize: "12px" }}>Not Applicable</label>
        </div>
      )

    default:
      return null
  }
}

export default CCMViewFormInput
