export const GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST = "GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST";
export const GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS = "GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS";
export const GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL = "GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL";

export const GET_ALL_ONBOARD_PATIENT_STATISTICS_REQUEST = "GET_ALL_ONBOARD_PATIENT_STATISTICS_REQUEST";
export const GET_ALL_ONBOARD_PATIENT_STATISTICS_SUCCESS = "GET_ALL_ONBOARD_PATIENT_STATISTICS_SUCCESS";
export const GET_ALL_ONBOARD_PATIENT_STATISTICS_FAIL = "GET_ALL_ONBOARD_PATIENT_STATISTICS_FAIL";

export const UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_REQUEST = "UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_REQUEST";
export const UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_SUCCESS = "UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_SUCCESS";
export const UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_FAIL = "UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_FAIL";

export const UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST = "UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST";
export const UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS = "UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS";
export const UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL = "UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL";

export const POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST = "POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST";
export const POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS = "POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS";
export const POST_BULK_MAIL_MESSAGE_PATIENT_FAIL = "POST_BULK_MAIL_MESSAGE_PATIENT_FAIL";