import {
  ADD_CARETAKER_FAIL,
  ADD_CARETAKER_REQUEST,
  ADD_CARETAKER_SUCCESS,
  CARETAKER_CARD_FAIL,
  CARETAKER_CARD_REQUEST,
  CARETAKER_CARD_SUCCESS,
  CARETAKER_LIST_FAIL,
  CARETAKER_LIST_REQUEST,
  CARETAKER_LIST_SUCCESS,
  UPDATE_CARETAKER_REQUEST,
  UPDATE_CARETAKER_SUCCESS,
  UPDATE_CARETAKER_FAIL,
  SINGLE_CARETAKER_REQUEST,
  SINGLE_CARETAKER_SUCCESS,
  SINGLE_CARETAKER_FAIL,
  CARETAKER_PATIENT_REQUEST,
  CARETAKER_PATIENT_SUCCESS,
  CARETAKER_PATIENT_FAIL,
  GET_CARE_TYPES_FAIL,
  GET_CARE_TYPES_REQUEST,
  GET_CARE_TYPES_SUCCESS,
  UPDATE_CARETAKER_MANUAL_TIME_REQUEST,
  UPDATE_CARETAKER_MANUAL_TIME_SUCCESS,
  UPDATE_CARETAKER_MANUAL_TIME_FAIL,
  GET_ORG_ADMIN_REQUEST,
  GET_ORG_ADMIN_SUCCESS,
  GET_ORG_ADMIN_FAIL,
  GET_CARETAKER_ORG_DETAILS_REQUEST,
  GET_CARETAKER_ORG_DETAILS_SUCCESS,
  GET_CARETAKER_ORG_DETAILS_FAIL
} from "../types/caretakerConstant"

export const caretakerCardReducer = (state = {}, action) => {
  switch (action.type) {
    case CARETAKER_CARD_REQUEST:
      return { loading: true }
    case CARETAKER_CARD_SUCCESS:
      return { loading: false, caretakerCards: action.payload }
    case CARETAKER_CARD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const caretakerListReducer = (state = {}, action) => {
  switch (action.type) {
    case CARETAKER_LIST_REQUEST:
      return { loading: true }
    case CARETAKER_LIST_SUCCESS:
      return { loading: false, caretakerLists: action.payload }
    case CARETAKER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addCaretakerReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_CARETAKER_REQUEST:
      return { loading: true }
    case ADD_CARETAKER_SUCCESS:
      return { loading: false, addCaretaker: action.payload }
    case ADD_CARETAKER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateCaretakerReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CARETAKER_REQUEST:
      return { loading: true }
    case UPDATE_CARETAKER_SUCCESS:
      return { loading: false, UpdateCaretaker: action.payload }
    case UPDATE_CARETAKER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const singleCaretakerReducer = (state = {}, action) => {
  switch (action.type) {
    case SINGLE_CARETAKER_REQUEST:
      return { loading: true }
    case SINGLE_CARETAKER_SUCCESS:
      return { loading: false, singleCaretaker: action.payload }
    case SINGLE_CARETAKER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const caretakerPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case CARETAKER_PATIENT_REQUEST:
      return { loading: true }
    case CARETAKER_PATIENT_SUCCESS:
      return { loading: false, caretakerPatient: action.payload }
    case CARETAKER_PATIENT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getCareTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CARE_TYPES_REQUEST:
      return { loading: true }
    case GET_CARE_TYPES_SUCCESS:
      return { loading: false, careTypes: action.payload }
    case GET_CARE_TYPES_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateCaretakerManualTimeReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CARETAKER_MANUAL_TIME_REQUEST:
      return { loading: true }
    case UPDATE_CARETAKER_MANUAL_TIME_SUCCESS:
      return { loading: false, careTakerManualTime: action.payload }
    case UPDATE_CARETAKER_MANUAL_TIME_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getOrgAdminsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORG_ADMIN_REQUEST:
      return { loading: true }
    case GET_ORG_ADMIN_SUCCESS:
      return { loading: false, orgAdmins: action.payload }
    case GET_ORG_ADMIN_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getCaretakerOrgDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CARETAKER_ORG_DETAILS_REQUEST:
      return { loading: true }
    case GET_CARETAKER_ORG_DETAILS_SUCCESS:
      return { loading: false, caretakerOrgDetails: action.payload }
    case GET_CARETAKER_ORG_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
