import moment from "moment/moment"
import { axiosGet, axiosPost } from "../axios"
import {
    GET_GROUPS_REQUEST,
    GET_GROUPS_SUCCESS,
    GET_GROUPS_FAIL,
    GET_GROUP_DETAIL_REQUEST,
    GET_GROUP_DETAIL_SUCCESS,
    GET_GROUP_DETAIL_FAIL,
    ADD_GROUP_REQUEST,
    ADD_GROUP_SUCCESS,
    ADD_GROUP_FAIL,
    UPDATE_GROUP_REQUEST,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_FAIL,
    GET_ADMIN_CARETAKER_FOR_GROUP_REQUEST,
    GET_ADMIN_CARETAKER_FOR_GROUP_SUCCESS,
    GET_ADMIN_CARETAKER_FOR_GROUP_FAIL,
    MAP_GROUP_TO_PATIENT_REQUEST,
    MAP_GROUP_TO_PATIENT_SUCCESS,
    MAP_GROUP_TO_PATIENT_FAIL,
    VALIDATE_GROUP_NAME_REQUEST,
    VALIDATE_GROUP_NAME_SUCCESS,
    VALIDATE_GROUP_NAME_FAIL,
    GET_PATIENTS_FOR_GROUP_REQUEST,
    GET_PATIENTS_FOR_GROUP_SUCCESS,
    GET_PATIENTS_FOR_GROUP_FAIL,
    SET_GROUP_PATIENT_ADVANCE_FILTER,
    RESET_GROUP_PATIENT_ADVANCE_FILTER,
    SET_GROUP_PATIENT_LIST_ADVANCE_FILTER,
    RESET_GROUP_PATIENT_LIST_ADVANCE_FILTER,
    GET_PATIENT_GROUP_NAME_REQUEST,
    GET_PATIENT_GROUP_NAME_SUCCESS,
    GET_PATIENT_GROUP_NAME_FAIL
} from "../types/groupsConstants"
import { userLogoutAction } from "./userActions"

// get all the grouos Action
export const GetGroups = (caretakerId) => async dispatch => {
    try {
        dispatch({
            type: GET_GROUPS_REQUEST
        })
        const { data } = await axiosGet(`/api/cs/groups/caretaker/${caretakerId}/fetch`)
        dispatch({
            type: GET_GROUPS_SUCCESS,
            payload: data.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_GROUPS_FAIL,
            payload: error.response?.data?.detail
        })
    }
}

// get the group detail Action
export const GetGroupDetail = (groupId) => async dispatch => {
    try {
        dispatch({
            type: GET_GROUP_DETAIL_REQUEST
        })
        const { data } = await axiosGet(`/api/cs/groups/${groupId}/fetch`)
        dispatch({
            type: GET_GROUP_DETAIL_SUCCESS,
            payload: data.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_GROUP_DETAIL_FAIL,
            payload: error.response?.data?.detail
        })
    }
}

// add a group Action
export const AddGroup = (formData) => async dispatch => {
    try {
        dispatch({
            type: ADD_GROUP_REQUEST
        })

        const { data } = await axiosPost(`/api/cs/groups/register`, {
            ...formData,
            date: moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
        })
        dispatch({
            type: ADD_GROUP_SUCCESS,
            payload: data.details
        })
        return data
    } catch (error) {
        console.error(error, 'error')
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: ADD_GROUP_FAIL,
            payload: error.response?.data?.details
        })
    }
}

// update a group Action
export const UpdateGroup = (formData, groupId) => async dispatch => {
    try {
        dispatch({
            type: UPDATE_GROUP_REQUEST
        })
        const { data } = await axiosPost(`/api/cs/groups/${groupId}/update`, {
            ...formData,
            date: moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
        })
        dispatch({
            type: UPDATE_GROUP_SUCCESS,
            payload: data.details
        })
        return data
    } catch (error) {
        console.error(error, 'error')
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: UPDATE_GROUP_FAIL,
            payload: error.response?.data?.details
        })
    }
}

// get admin caretaker for group Action
export const GetAdminCaretakerForGroup = (value) => async dispatch => {
    try {
        dispatch({
            type: GET_ADMIN_CARETAKER_FOR_GROUP_REQUEST
        })
        const { data } = await axiosGet(`/api/cs/config/search/groups/${value}`)
        dispatch({
            type: GET_ADMIN_CARETAKER_FOR_GROUP_SUCCESS,
            payload: data.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_ADMIN_CARETAKER_FOR_GROUP_FAIL,
            payload: error.response?.data?.detail
        })
    }
}

// map group to patient Action
export const MapGroupToPatient = (groupId, patientId) => async dispatch => {
    try {
        dispatch({
            type: MAP_GROUP_TO_PATIENT_REQUEST
        })
        const { data } = await axiosPost(`/api/cs2/groups/${groupId}/patient/${patientId}/create`, {date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")})
        dispatch({
            type: MAP_GROUP_TO_PATIENT_SUCCESS,
            payload: data.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: MAP_GROUP_TO_PATIENT_FAIL,
            payload: error.response?.data?.details
        })
        throw error.response?.data
    }
}

// validate the group name
export const ValidateGroupName = (value) => async (dispatch) => {
    try {
        dispatch({
            type: VALIDATE_GROUP_NAME_REQUEST
        })
        const { data } = await axiosGet(`/api/cs/groups/validate/${value}`)
        dispatch({
            type: VALIDATE_GROUP_NAME_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: VALIDATE_GROUP_NAME_FAIL,
            payload: error.response?.data?.detail
        })
    }
}

export const GetPatientsForGroup = (groupIds) => async dispatch => {
    try {
        dispatch({
            type: GET_PATIENTS_FOR_GROUP_REQUEST
        })
        const { data } = await axiosPost(`/api/cs/groups/patients/fetch`, groupIds)
        dispatch({
            type: GET_PATIENTS_FOR_GROUP_SUCCESS,
            payload: data.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_PATIENTS_FOR_GROUP_FAIL,
            payload: error.response?.data?.detail
        })
    }
}

export const saveGroupPatientAdvanceFilter = (data) => async dispatch => {
    dispatch({
        type: SET_GROUP_PATIENT_ADVANCE_FILTER,
        payload: data
    })
}
export const resetGroupPatientAdvanceFilter = () => async dispatch => {
    dispatch({
        type: RESET_GROUP_PATIENT_ADVANCE_FILTER,
    })
}
export const saveGroupPatientsListAdvanceFilter = (data) => async dispatch => {
    dispatch({
        type: SET_GROUP_PATIENT_LIST_ADVANCE_FILTER,
        payload: data
    })
}
export const resetGroupPatientsListAdvanceFilter = () => async dispatch => {
    dispatch({
        type: RESET_GROUP_PATIENT_LIST_ADVANCE_FILTER,
    })
}

export const GetPatientGroupName = (patientId) => async dispatch => {
    try {
        dispatch({
            type: GET_PATIENT_GROUP_NAME_REQUEST
        })
        const { data } = await axiosGet(`/api/cs/groups/patient/${patientId}/fetch`)
        dispatch({
            type: GET_PATIENT_GROUP_NAME_SUCCESS,
            payload: data.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_PATIENT_GROUP_NAME_FAIL,
            payload: error.response?.data?.details
        })
    }
}