import {
  GET_RTM_PAIN_MANAGEMENT_DATA_REQUEST,
  GET_RTM_PAIN_MANAGEMENT_DATA_SUCCESS,
  GET_RTM_PAIN_MANAGEMENT_DATA_FAIL,
  POST_RTM_PAIN_MANAGEMENT_DATA_REQUEST,
  POST_RTM_PAIN_MANAGEMENT_DATA_SUCCESS,
  POST_RTM_PAIN_MANAGEMENT_DATA_FAIL,
  GET_RTM_PAIN_MANAGEMENT_READING_REQUEST,
  GET_RTM_PAIN_MANAGEMENT_READING_SUCCESS,
  GET_RTM_PAIN_MANAGEMENT_READING_FAIL,
  GET_RTM_ADDICTION_MEDICINE_DATA_REQUEST,
  GET_RTM_ADDICTION_MEDICINE_DATA_SUCCESS,
  GET_RTM_ADDICTION_MEDICINE_DATA_FAIL,
  POST_RTM_ADDICTION_MEDICINE_DATA_REQUEST,
  POST_RTM_ADDICTION_MEDICINE_DATA_SUCCESS,
  POST_RTM_ADDICTION_MEDICINE_DATA_FAIL,
  GET_RTM_ADDICTION_MEDICINE_READING_REQUEST,
  GET_RTM_ADDICTION_MEDICINE_READING_SUCCESS,
  GET_RTM_ADDICTION_MEDICINE_READING_FAIL,
  GET_RTM_MENTAL_HEALTH_DATA_REQUEST,
  GET_RTM_MENTAL_HEALTH_DATA_SUCCESS,
  GET_RTM_MENTAL_HEALTH_DATA_FAIL,
  POST_RTM_MENTAL_HEALTH_DATA_REQUEST,
  POST_RTM_MENTAL_HEALTH_DATA_SUCCESS,
  POST_RTM_MENTAL_HEALTH_DATA_FAIL,
  GET_RTM_MENTAL_HEALTH_READING_REQUEST,
  GET_RTM_MENTAL_HEALTH_READING_SUCCESS,
  GET_RTM_MENTAL_HEALTH_READING_FAIL
} from "../types/rtmConstants"

export const postRTMPainManagementDataReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_RTM_PAIN_MANAGEMENT_DATA_REQUEST:
      return { loading: true }
    case POST_RTM_PAIN_MANAGEMENT_DATA_SUCCESS:
      return { loading: false, data: action.payload }
    case POST_RTM_PAIN_MANAGEMENT_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getRTMPainManagementDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RTM_PAIN_MANAGEMENT_DATA_REQUEST:
      return { loading: true }
    case GET_RTM_PAIN_MANAGEMENT_DATA_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_RTM_PAIN_MANAGEMENT_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getRTMPainManagementReadingReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RTM_PAIN_MANAGEMENT_READING_REQUEST:
      return { loading: true }
    case GET_RTM_PAIN_MANAGEMENT_READING_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_RTM_PAIN_MANAGEMENT_READING_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postRTMAddictionMedicineDataReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_RTM_ADDICTION_MEDICINE_DATA_REQUEST:
      return { loading: true }
    case POST_RTM_ADDICTION_MEDICINE_DATA_SUCCESS:
      return { loading: false, data: action.payload }
    case POST_RTM_ADDICTION_MEDICINE_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getRTMAddictionMedicineDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RTM_ADDICTION_MEDICINE_DATA_REQUEST:
      return { loading: true }
    case GET_RTM_ADDICTION_MEDICINE_DATA_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_RTM_ADDICTION_MEDICINE_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getRTMAddictionMedicineReadingReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RTM_ADDICTION_MEDICINE_READING_REQUEST:
      return { loading: true }
    case GET_RTM_ADDICTION_MEDICINE_READING_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_RTM_ADDICTION_MEDICINE_READING_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postRTMMentalHealthDataReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_RTM_MENTAL_HEALTH_DATA_REQUEST:
      return { loading: true }
    case POST_RTM_MENTAL_HEALTH_DATA_SUCCESS:
      return { loading: false, data: action.payload }
    case POST_RTM_MENTAL_HEALTH_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getRTMMentalHealthDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RTM_MENTAL_HEALTH_DATA_REQUEST:
      return { loading: true }
    case GET_RTM_MENTAL_HEALTH_DATA_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_RTM_MENTAL_HEALTH_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getRTMMentalHealthReadingReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RTM_MENTAL_HEALTH_READING_REQUEST:
      return { loading: true }
    case GET_RTM_MENTAL_HEALTH_READING_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_RTM_MENTAL_HEALTH_READING_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
