import React from "react"
import { useLocation } from "react-router-dom"
import RPMEditTreatement from "../components/RPMAdmin/RPMEditTreatement/RPMEditTreatement"

const RPMEditTreatementScreen = () => {
  const location = useLocation()
  const title = location.state && location.state
  return <RPMEditTreatement title={title && title} />
}

export default RPMEditTreatementScreen
