import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { useToasts } from "react-toast-notifications"
import { Progress, Card, Button, Checkbox } from "antd"
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons"

import { useDispatch, useSelector } from "react-redux"
import { postRPMUserActions } from "../../../../redux/actions/rpmAction"
import RPMOnlyFormInput from "../../RPMAdminQuestions/RPMOnlyFormInput"

const RPMFollowUpQa = ({ treatementPlan, patientInfoDetail }) => {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState([])
  const [reRenderer, setReRendered] = useState([])
  const [rpmTypeProgress, setRPMypeProgress] = useState(0)
  const [showModal, setShowModal] = useState(false) // eslint-disable-line

  // const { patientInfoDetail } = useSelector(state => state.patientInfo)
  const { error } = useSelector(state => state.postRPMData)

  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const {orgId, orgName} = useParams()

  const userInfo = useSelector(state => state.userInfo);
  const UserName = `${userInfo?.userInfo.firstName} ${userInfo?.userInfo.lastName}`;
  const addingBySecondary = { name: UserName, userType: "Caretaker" }

  useEffect(() => {
    setShowModal(true)
  }, [])

  useEffect(() => {
    if (treatementPlan !== null || treatementPlan !== undefined) {
      const newState = []
      Object.values(treatementPlan)
        .filter(
          x =>
            x?.name !==
            "Is Monday, Wednesday and Friday between 10am-12pm still a good time to contact you next month for your RPM check-up call? Yes or No"
        )
        .map((value, index) => {
          value.id = index + 1;
          newState.push(value);
        })

      setQuestions(newState)
    }
  }, [treatementPlan])

  const nextQues = () => {
    if (page < questions?.length) {
      setPage(page + 1)
      setRPMypeProgress((page / questions?.length) * 100)
    }
  }
  const prevQues = () => {
    if (page > 1) {
      setPage(page - 1)
      setRPMypeProgress((page / questions?.length) * 100)
    }
  }
  useEffect(() => {
    if (page === questions?.length) {
      setRPMypeProgress(100)
    }
  }, [page, questions.length])

  const handleNormalCheck = (quesId, i, e) => {
    questions[quesId - 1].questionaryOptions[i].checked = e.target.checked
    if (questions[quesId - 1].questionaryOptions[i].alert !== undefined) {
      if (questions[quesId - 1].questionaryOptions[i].checked === true) {
        if (
          questions[quesId].name !== undefined &&
          questions[quesId].name === "blood pressure machine"
        ) {
          window.alert("Advise the patient to check their blood pressure now.")
        }
      }
    }
    questions[quesId - 1].questionaryOptions.forEach(item => {
      if (item.checked === true) {
        return (questions[quesId - 1].anyValue = true)
      }
    })
    setQuestions([...questions])
    setReRendered([...reRenderer])
    // console.log('all question list',questions)
  }

  const handleText = (quesId, i, e) => {
    // console.log('2',questions[quesId-1])
    // console.log(e.target.checked,quesId,i)
    // console.log(questions[quesId-1].questionaryOptions[i].text)
    questions[quesId - 1].questionaryOptions[i].text = e.target.value
    questions[quesId - 1].questionaryOptions[i].text !== ""
      ? (questions[quesId - 1].anyValue = true)
      : (questions[quesId - 1].anyValue = false)
    // questions[quesId-1].questionaryOptions.forEach((item)=>{
    //     if(item.checked === true){
    //         return questions[quesId-1].anyValue = true;
    //     }
    // })
    setQuestions(questions)
    setReRendered([...reRenderer])
  }

  const handleNormalText = (quesId, e) => {
    // console.log('3',questions[quesId-1])
    // console.log(quesId,e.target.value)
    // console.log(questions[quesId-1].questionaryOptions[i].text)
    questions[quesId - 1].text = e.target.value
    questions[quesId - 1].text !== ""
      ? (questions[quesId - 1].anyValue = true)
      : (questions[quesId - 1].anyValue = false)

    setQuestions(questions)
    setReRendered([...reRenderer])
    // console.log('all question list',questions)
  }

  const handleSingle = (quesId, i, e) => {
    // console.log('4',questions[quesId-1])
    // console.log(e.target.value)
    questions[quesId - 1].questionaryOptions.map(item => {
      if (item !== questions[quesId - 1].questionaryOptions[i]) {
        item.checked = false
      }
      return item
    })
    questions[quesId - 1].questionaryOptions[i].checked = e.target.checked
    questions[quesId - 1].questionaryOptions.forEach(item => {
      if (item.checked === true) {
        return (questions[quesId - 1].anyValue = true)
      }
    })
    setQuestions(questions)
    setReRendered([...reRenderer])
  }

  const handleBool = (quesId, e) => {
    // console.log('5',questions[quesId-1])
    questions[quesId - 1].questionaryOptions[0] = e.target.value
    questions[quesId - 1].questionaryOptions?.length
      ? (questions[quesId - 1].anyValue = true)
      : (questions[quesId - 1].anyValue = false)

    setQuestions(questions)
    setReRendered([...reRenderer])
  }

  const handleBpmNone = (quesId, i, e) => {
    // console.log('6',questions[quesId-1])
    questions[quesId - 1].questionaryOptions[i].checked = e.target.checked
    questions[quesId - 1].questionaryOptions.forEach(item => {
      if (
        item.name !== "None of the above" &&
        questions[quesId - 1].questionaryOptions[i].checked === true
      ) {
        item.disabled = true
        item.checked = false
        item.text = ""
      } else {
        item.disabled = false
      }
    })
    questions[quesId - 1].questionaryOptions.forEach(item => {
      if (item.checked === true) {
        return (questions[quesId - 1].anyValue = true)
      }
    })
    setQuestions(questions)
    setReRendered([...reRenderer])
  }

  //= >used for both weight machine and oximeter
  const handleWeightMachineCheck = (quesId, i, e, ii, iii) => {
    // console.log('7',questions[quesId-1])
    // i index first
    // ii index of sub child
    // iii index of sub sub child
    if (ii !== undefined && iii !== undefined) {
      questions[quesId - 1].questionaryOptions[i].subQuestion[ii].questionaryOptions[iii].checked =
        e.target.checked

      // alert
      if (
        questions[quesId - 1].questionaryOptions[i].subQuestion[ii].questionaryOptions[iii]
          .alert !== undefined
      ) {
        if (
          questions[quesId - 1].questionaryOptions[i].subQuestion[ii].questionaryOptions[iii]
            .checked === true
        ) {
          if (
            questions[quesId - 1].name !== undefined &&
            questions[quesId - 1].name === "weight machine"
          ) {
            window.alert("Advise the patient to check their weight now.")
          }
          if (
            questions[quesId - 1].name !== undefined &&
            questions[quesId - 1].name === "oximeter" &&
            i < 3 &&
            ii === 3
          ) {
            window.alert("Advise the patient to check their oxygen level now.")
          }
          if (
            questions[quesId - 1].name !== undefined &&
            questions[quesId - 1].name === "oximeter" &&
            i > 2 &&
            ii === 3
          ) {
            window.alert("Advise the patient to check their heart rate now.")
          }
        }
      }
    } else {
      questions[quesId - 1].questionaryOptions[i].checked = e.target.checked
    }
    questions[quesId - 1].questionaryOptions.forEach(item => {
      if (item.checked === true) {
        return (questions[quesId - 1].anyValue = true)
      }
    })
    setQuestions(questions)
    setReRendered([...reRenderer])
    // console.log(questions[quesId-1].questionaryOptions[i])
  }

  //= >used for both weight machine and oximeter
  const handleWeightMachineOtherText = (quesId, i, e, ii, iii) => {
    // console.log('8',questions[quesId-1])
    questions[quesId - 1].questionaryOptions[i].subQuestion[ii].questionaryOptions[iii].text =
      e.target.value
    questions[quesId - 1].questionaryOptions.forEach(item => {
      if (item.checked === true) {
        return (questions[quesId - 1].anyValue = true)
      }
    })
    setQuestions(questions)
    setReRendered([...reRenderer])
    // console.log(questions[quesId-1].questionaryOptions[i])
  }

  //= >used for both weight machine and oximeter
  const handleWeightMachineNone = (quesId, i, e, ii, iii) => {
    // console.log('9',questions[quesId-1])
    questions[quesId - 1].questionaryOptions[i].subQuestion[ii].questionaryOptions[iii].checked =
      e.target.checked
    questions[quesId - 1].questionaryOptions[i].subQuestion[ii].questionaryOptions.map(item => {
      if (
        item.name !== "None of the above" &&
        questions[quesId - 1].questionaryOptions[i].subQuestion[ii].questionaryOptions[iii]
          .checked === true
      ) {
        item.disabled = true
        item.checked = false
        item.text = ""
      } else {
        item.disabled = false
      }
      return item
    })
    questions[quesId - 1].questionaryOptions.forEach(item => {
      if (item.checked === true) {
        return (questions[quesId - 1].anyValue = true)
      }
    })
    setQuestions(questions)
    setReRendered([...reRenderer])
    // console.log(questions[quesId-1].questionaryOptions[i].subQuestion[ii])
  }

  const handleNotApplicable = (quesId, e) => {
    questions[quesId - 1].notApplicable = e.target.checked
    setQuestions(questions)
    setReRendered([...reRenderer])
  }

  const submitForm = async () => {
    // console.log("answer to submit", questions)
    setLoading(true)
    dispatch(postRPMUserActions(patientInfoDetail?.id, { ...questions, addingBy: addingBySecondary })).then(res => {
      if (res?.message?.toLowerCase() === "success") {
        setQuestions([])
        addToast("Treatement Plan Added Successfully", { appearance: "success", autoDismiss: true })
        navigate(`/${orgId}/${orgName}/rpm/patients`)
        setLoading(false)
      } else {
        addToast("Unable To Add Care Plan!", { appearance: "error", autoDismiss: true })
      }
    })
  }

  return (
    questions?.length && (
      <div className="bottom_section">
        <div className="p-4 border-2 border-current my-4 rounded">
          <h3 className="text-xl mb-3">Progress:</h3>
          <Progress percent={rpmTypeProgress} showInfo={false} />
        </div>
        {/* eslint-disable */}
        <Card>
          {questions?.length > 0 &&
            questions.map((ques, index) => {
              if (page === ques?.id) {
                return (
                  <div key={index}>
                    {(ques?.device || ques?.devType) && (
                      <div className="p-2">
                        <h6 className="text-lg font-medium">Device</h6>
                        <h4 className="text-lg mt-2 text-current capitalize">{ques?.device || ques?.devType}</h4>
                      </div>
                    )}

                    <h4 className="text-lg my-3">{ques?.name}</h4>
                    <RPMOnlyFormInput
                      ques={ques}
                      handleNormalCheck={handleNormalCheck}
                      handleText={handleText}
                      handleNormalText={handleNormalText}
                      handleSingle={handleSingle}
                      handleBool={handleBool}
                      handleBpmNone={handleBpmNone}
                      handleWeightMachineCheck={handleWeightMachineCheck}
                      handleWeightMachineOtherText={handleWeightMachineOtherText}
                      handleWeightMachineNone={handleWeightMachineNone}
                    />
                    <div className="not_applicable mt-4">
                      {ques?.notApplicable !== undefined && (
                        <Checkbox
                          checked={!!ques?.notApplicable}
                          onChange={e => handleNotApplicable(ques?.id, e)}>
                          Not Applicable
                        </Checkbox>
                      )}
                    </div>
                  </div>
                )
              }
            })}

          <div className="flex flex-wrap gap-3 items-center mt-4">
            {page > 1 && (
              <Button onClick={prevQues} className="flex items-center gap-2">
                <ArrowLeftOutlined />
                Back
              </Button>
            )}
            {page < questions?.length ? (
              <Button type="primary" className="flex items-center gap-2" onClick={nextQues}>
                Next
                <ArrowRightOutlined />
              </Button>
            ) : (
              <Button type="primary" onClick={submitForm} disabled={loading}>
                {loading ? "Saving..." : "Submit"}
              </Button>
            )}
            {error !== undefined && typeof error === "string" && (
              <p className="text-danger text-center">
                <small>{error}</small>
              </p>
            )}
            {error !== undefined &&
              typeof error === "object" &&
              error.map((value, i) => (
                <p key={i} className="text-danger text-center">
                  <small>{`${value?.loc?.[1]["0"]?.toUpperCase() + value?.loc?.[1]?.slice(1)} ${value?.msg
                    }`}</small>
                </p>
              ))}
          </div>
        </Card>
      </div>
    )
  )
}

export default RPMFollowUpQa
