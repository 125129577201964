import React from "react"
import { useSelector } from "react-redux"

const SimpleHeader = () => {
  const { userInfo } = useSelector(state => state.userInfo)

  return (
    <div
      className="flex justify-end shadow-sm py-4 items-center px-4"
      style={{ height: "86px", backgroundColor: "#fff" }}>
      <div>
        <h6 className="text-capitalize">
          {userInfo !== null ? `${userInfo?.firstName} ${userInfo?.lastName}` : "Example Name"}
        </h6>
        <h6 className=" text-secondary text-capitalize">
          {userInfo !== null ? userInfo?.orgDetails?.name : "Example Org"}
        </h6>
      </div>
      <div>
        <img
          className="img-fluid header-user-image"
          src={
            userInfo !== null && userInfo?.profileImage !== null
              ? `data:image/png;base64,${userInfo?.profileImage}`
              : "/assets/fitpeo_logo.png"
          }
          alt="User Profile"
        />
      </div>
    </div>
  )
}

export default SimpleHeader
