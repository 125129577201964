import React, { useEffect, useMemo, useState } from "react"

import moment from "moment"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.module.css"
import { Modal, Row, Col, Button, Select, Input } from "antd"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import debounce from 'lodash/debounce';

import { useDispatch, useSelector } from "react-redux"
import { criticalPatientAction } from "../../redux/actions/dashboardAction"
import {

  getAdvancedFiltersOptions,
  postAddPatient,
  addPatientClearErrorsAction,
  postFacilityPatientAddressAction,
  patientDetailsValidateAction,
  patientDetailsValidateResetAction
} from "../../redux/actions/patientAction"
import { useToasts } from "react-toast-notifications"
import { USA_DATE_FORMAT } from "../../utils/usaDateFormat"
import { PatternFormat } from "react-number-format"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import { ExclamationCircleFilled } from "@ant-design/icons"
import { useParams } from "react-router-dom"

const emailRegex =
  /^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/
const alphaRegex = /^[a-zA-Z\s]+$/

const getUnits = (arr, facilityId) => arr?.find(i => i.facilityId === facilityId)

const getRooms = (arr, facilityId, facilityUnit) =>
  arr
    ?.find(i => i.facilityId === facilityId)
    ?.filterOptions?.find(r => r.units.unit === facilityUnit)?.units?.rooms

const getBedPositions = (arr, facilityId, facilityUnit) =>
  arr
    ?.find(i => i.facilityId === facilityId)
    ?.filterOptions?.find(r => r.units.unit === facilityUnit)?.units?.bedPositions


const schema = yup
  .object()
  .shape(
    {
      userName: yup.string(),
      dob: yup.string().required("Invalid DOB"),
      gender: yup.string().required("Invalid Gender"),
      // email: yup.string().email().required("Invalid Email"),
      patientType: yup.string().required("Invalid patientType"),
      // mobileNumber: yup.string().min(10).max(10).required("Invalid Phone Number"),
      middleName: yup
        .string()
        .trim()
        .test("checkMiddleName", "", (value, { createError, path }) => {
          if (value.length && !alphaRegex.test(value)) {
            return createError({
              path,
              message: "Middle Name should contain alphabets only"
            })
          } else {
            return true
          }
        }),
      lastName: yup
        .string()
        .trim()
        .min(1)
        .max(100)
        .matches(alphaRegex, "Last Name should contain alphabets only")
        .required("Invalid Last Name"),
      firstName: yup
        .string()
        .trim()
        .min(1)
        .max(100)
        .matches(alphaRegex, "First Name should contain alphabets only")
        .required("Invalid First Name"),
      email: yup
        .string()
        .matches(emailRegex, "Invalid email address")
        .when("mobileNumber", {
          is: value => !value || value.length === 0,
          then: () =>
            yup
              .string()
              .matches(emailRegex, "Invalid email address")
              .required("Enter either Email or mobileNumber"),
          otherwise: () =>
            yup.string().test("checkEmail", "", (value, { createError, path }) => {
              if (value.length && !emailRegex.test(value)) {
                return createError({
                  path,
                  message: "Invalid email address"
                })
              } else {
                return true
              }
            })
        }),
      mobileNumber: yup
        .string()
        .min(10)
        .max(10)
        .when("email", {
          is: value => !value || value.length === 0,
          then: () => yup.string().required("Enter either Email or mobileNumber"),
          otherwise: () =>
            yup.string().test("mobileNumber", (value, { createError, path }) => {
              if (value.length && value.replace(/[^\d]/g, '').length !== 10) {
                return createError({
                  path,
                  message: "Mobile Number should be of 10 digits"
                })
              } else {
                return true
              }
            })
        }),
      telephone: yup.string().when({
        is: value => value && value.length > 0,
        then: () => yup.string().required("Invalid Telephone")
      }),
      teleExtension: yup.string().when("telephone", {
        is: value => value && value.length > 0,
        then: () => yup.string().min(1).max(5).required("Invalid Extension")
      }),
      facility: yup.string().when("patientType", {
        is: value => value === "facility",
        then: () => yup.string().required("Invalid Facility Name")
      }),
      // unit: yup.string().when("patientType", {
      //   is: value => value === "facility",
      //   then: () => yup.string().required("Invalid Unit")
      // }),
      room: yup.string().when(["patientType", "unit"], {
        is: (patientType, unit) => patientType === "facility" && unit.length,
        then: () => yup.string().required("Invalid Room No.")
      }),
      bedPosition: yup.string().when(["patientType", "unit"], {
        is: (patientType, unit) => patientType === "facility" && unit.length,
        then: () => yup.string().required("Invalid Bed Position")
      }),
      street: yup.string().when("patientType", {
        is: value => value === "outpatient",
        then: () =>
          yup
            .string()
            // .matches(/^[A-Za-z1-9]*$/, "Street should be alphaNumeric")
            .required("Invalid Street")
      }),
      city: yup.string().when("patientType", {
        is: value => value === "outpatient",
        then: () => yup.string().required("Invalid City")
      }),
      state: yup.string().when("patientType", {
        is: value => value === "outpatient",
        then: () => yup.string().required("Invalid State")
      }),
      zipcode: yup.string().when("patientType", {
        is: value => value === "outpatient",
        then: () => yup.string().min(5).max(5).required("Invalid Zipcode")
      })
    },
    ["email", "mobileNumber"]
  )
  .required()

const defaultValues = {
  city: "",
  email: "",
  state: "",
  street: "",
  gender: "",
  zipcode: "",
  lastName: "",
  userName: "",
  firstName: "",
  telephone: "",
  middleName: "",
  patientType: "",
  dob: null,
  mobileNumber: "",
  teleExtension: "",
  patientFacility: "",
  unit: "",
  room: "",
  bedPosition: "",
  facility: ""
}

const AddPatientPhaseOne = props => {
  const { addPatientShow, handleAddPatientClose } = props

  const [userTypes, setUserTypes] = useState(null)
  const [facilityName, setFacilityName] = useState(null)
  const [isFocused, setIsFocused] = useState(false);
  const [isTelephoneFocused, setIsTelephoneFocused] = useState(false);

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [dob, setDob] = useState(null)
  const [debounceTimeout, setDebounceTimeout] = useState(800);

  const { addToast } = useToasts()
  const {orgId, orgName} = useParams()

  const dispatch = useDispatch()
  const { loading, error } = useSelector(state => state.addPatient)
  const { advancedFiltersOptions } = useSelector(state => state.advancedFiltersOptions)
  const { criticalPatient } = useSelector(state => state.criticalPatient)
  const { error: patientValidateError } = useSelector(state => state.patientDetailsValidate)

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    shouldFocusError: false,
    resolver: yupResolver(schema)
  })

  const debounceFetcher = useMemo(() => {
    const validatePatient = (validationData) => {
      if (!validationData?.firstName || !validationData?.lastName || !validationData?.dob) {
        return
      }
      dispatch(patientDetailsValidateAction(validationData))
    }

    return debounce(validatePatient, debounceTimeout);
  }, [dispatch, debounceTimeout]);

  useEffect(() => {
    if (firstName && lastName && dob) {
      const validationData = {
        firstName: firstName,
        lastName: lastName,
        dob: moment(dob)?.format("YYYY-MM-DD")
      }
      debounceFetcher(validationData)
    }
  }, [firstName, lastName, dob])

  useEffect(() => {
    const subscription = watch(["email", "mobileNumber"])
    return () => (subscription && subscription?.unsubscribe ? subscription.unsubscribe() : null)
  }, [watch])

  useEffect(() => {
    const subscription = watch(["facility", "unit", "room", "bedPosition"])
    return () => (subscription && subscription?.unsubscribe ? subscription.unsubscribe() : null)
  }, [watch])

  useEffect(() => {
    if (addPatientShow) {
      // dispatch(getFacilityAddress())
      dispatch(getAdvancedFiltersOptions(orgId))
    }
  }, [addPatientShow])

  useEffect(() => {
    setValue("city", "")
    setValue("street", "")
    setValue("state", "")
    setValue("zipcode", "")
    setValue("facility", "")
    setValue("room", "")
    setValue("unit", "")
    setValue("bedPosition", "")
  }, [userTypes])

  useEffect(() => {
    setValue("room", "")
    setValue("unit", "")
    setValue("bedPosition", "")

  }, [facilityName])

  const handleClose = () => {
    reset()
    setUserTypes(null)
    setFacilityName(null)
    dispatch(addPatientClearErrorsAction())
    dispatch(patientDetailsValidateResetAction())
    setDob(null)
    setFirstName("")
    setLastName("")
    handleAddPatientClose()
  }

  const onSubmit = async data => {
    const {
      dob,
      email,
      city,
      state,
      street,
      gender,
      zipcode,
      userName,
      lastName,
      firstName,
      telephone,
      middleName,
      mobileNumber,
      teleExtension,
      facility
    } = data
    dispatch(
      postAddPatient({
        gender,
        lastName,
        firstName,
        userTypes,
        countryCode: "US",
        dob: moment(dob).format("YYYY-MM-DD"),
        userName: userName.length ? userName : `${firstName}${middleName || ""}${lastName}`,
        ...(userTypes === "facility"
          ? { addressId: facility }
          : {
            addressDetails: {
              city,
              state,
              street,
              zipcode
            }
          }),
        ...(telephone.length ? { telephone: telephone?.replace(/[^\d]/g, ''), extension: teleExtension } : {}),
        ...(middleName.length
          ? {
            middleName
          }
          : {
            middleName: null
          }),
        ...(email.length ? { email } : {}),
        ...(mobileNumber.length ? { mobileNumber: mobileNumber?.replace(/[^\d]/g, '') } : {})
      }, orgId)
    ).then(res => {
      if (res?.message?.toLowerCase() === "success") {
        handleClose()
        dispatch(criticalPatientAction(orgId))
        addToast("Patient Added Successfully", {
          appearance: "success",
          autoDismiss: true
        })
        //  dispatch(criticalPatientAction()).then(()=>{
        //   let newPatient = criticalPatient?.filter((patient) => 
        //   (patient?.mobileNumber === data?.mobileNumber || patient?.email?.toLowerCase() === data?.email?.toLowerCase()))
        //   console.log("data", data?.room , data?.bedPosition , data?.unit, criticalPatient)
        //   if(newPatient && newPatient[0]?.userType==="facility" && data?.room && data?.bedPosition && data?.unit )
        //   {
        //       dispatch(postFacilityPatientAddressAction(newPatient[0]?.id, newPatient[0]?.facilityId, 
        //         {
        //           unit:data?.unit,
        //           room:data?.room,
        //           bedLocation:data?.bedPosition     
        //         }))
        //   }
        //  
        //  })
      }
    })
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleTelephoneFocus = () => {
    setIsTelephoneFocused(true);
  };

  const handleTelephoneBlur = () => {
    setIsTelephoneFocused(false);
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    return formatPhoneNumber(value)
  };

  const facilityOptions = advancedFiltersOptions?.filter(i => i.userType === "facility") || []
  return (
    <Modal
      width={800}
      footer={null}
      open={addPatientShow}
      onCancel={handleClose}
      title={<h4 className="text-xl">Add Patient</h4>}>
      <form className="pt-4" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={20}>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-1" htmlFor="input-firstName">
              First Name <span className="text-danger">*</span>
            </label>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-firstName"
                  status={errors.firstName ? "error" : undefined}
                  suffix={patientValidateError?.message ? <ExclamationCircleFilled className="text-[#E10505] text-sm" /> : null}
                  value={field.value}
                  onChange={(e) => {
                    if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                      field.onChange(e.target.value)
                      setFirstName((e.target.value).trim())
                    }
                  }}
                // {...field}
                />
              )}
            />
            {errors.firstName ? (
              <small className="text-danger">{errors.firstName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-1" htmlFor="input-middleName">
              Middle Name
            </label>
            <Controller
              name="middleName"
              control={control}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-middleName"
                  status={errors.middleName ? "error" : undefined}
                  {...field}
                  onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.middleName ? (
              <small className="text-danger">{errors.middleName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-1" htmlFor="input-lastName">
              Last Name <span className="text-danger">*</span>
            </label>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-lastName"
                  status={errors.lastName ? "error" : undefined}
                  suffix={patientValidateError?.message ? <ExclamationCircleFilled className="text-[#E10505] text-sm" /> : null}
                  value={field.value}
                  onChange={(e) => {
                    if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                      field.onChange(e.target.value)
                      setLastName((e.target.value).trim())
                    }
                  }}
                // {...field}
                />
              )}
            />
            {errors.lastName ? (
              <small className="text-danger">{errors.lastName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-1" htmlFor="input-userName">
              Username
            </label>
            <Controller
              name="userName"
              control={control}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-userName"
                  status={errors.userName ? "error" : undefined}
                  {...field}
                  onChange={e => /^\S+$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-1" htmlFor="input-email">
              Email{" "}
              {getValues("mobileNumber").length === 0 ? (
                <span className="text-danger">*</span>
              ) : null}
            </label>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  type="email"
                  id="input-email"
                  status={errors.email ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.email ? <small className="text-danger">{errors.email.message}</small> : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-1" htmlFor="input-mobileNumber">
              Mobile Number{" "}
              {getValues("email").length === 0 ? <span className="text-danger">*</span> : null}
            </label>
            <Controller
              name="mobileNumber"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const { ref, ...rest } = field
                return (
                  <PatternFormat
                    format="(###) ###-####"
                    className={`formatted-mobile-input flex px-[11px] py-[7px] text-[1rem] w-full  border border-${errors.mobileNumber ? "danger" : isFocused ? "primary" : "#dfdfdf"
                      }  rounded-lg focus:outline-none`}
                    id="input-mobileNumber"
                    {...rest}
                    onChange={e => rest.onChange(handleMobileChange(e))}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                )
              }
              }
            />
            {errors.mobileNumber ? (
              <small className="text-danger">{errors.mobileNumber.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4 relative">
            <label className="mb-1" htmlFor="input-dob">
              DOB <span className="text-danger">*</span>
            </label>
            <Controller
              name="dob"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={date => {
                    field.onChange(date)
                    setDob(date)
                  }}
                  dateFormatCalendar={"MMM yyyy"}
                  maxDate={Date.now()}
                  showPopperArrow={false}
                  placeholderText="Date of Birth"
                  minDate={moment(new Date()).subtract(150, "years")._d}
                  withPortal
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  className={`border p-3 rounded w-100 h-[40px] ${errors.dob ? "border-danger" : ""
                    }`}
                // {...field}
                />
              )}
            />
            {patientValidateError?.message ? <ExclamationCircleFilled className="absolute top-10 right-[21px] text-[#E10505] text-sm" /> : null}
            {errors.dob ? <small className="text-danger">{errors.dob.message}</small> : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-1" htmlFor="input-gender">
              Gender at time of birth<span className="text-danger">*</span>
            </label>
            <Controller
              name="gender"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  size="large"
                  className="w-100"
                  status={errors.gender ? "error" : undefined}
                  options={[
                    {
                      value: "male",
                      label: "Male"
                    },
                    {
                      value: "female",
                      label: "Female"
                    }
                  ]}
                  {...field}
                />
              )}
            />
            {errors.gender ? (
              <small className="text-danger block">{errors.gender.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-1" htmlFor="input-telephone">
              Telephone
            </label>
            <Controller
              name="telephone"
              control={control}
              render={({ field }) => {
                const { ref, ...rest } = field
                return (
                  <PatternFormat
                    format="(###) ###-####"
                    className={`formatted-input-telephone flex p-2 text-[1rem] w-full  border border-${errors.telephone ? "danger" : isTelephoneFocused ? "primary" : "#dfdfdf"
                      }  rounded-lg focus:outline-none`}
                    id="input-telephone"
                    {...rest}
                    onChange={e => rest.onChange(handleMobileChange(e))}
                    onFocus={handleTelephoneFocus}
                    onBlur={handleTelephoneBlur}
                  />
                )
              }
              }
            />
            {errors.telephone ? (
              <small className="text-danger">{errors.telephone.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-1" htmlFor="input-teleExtension">
              Telephone Extension
            </label>
            <Controller
              name="teleExtension"
              control={control}
              render={({ field }) => (
                <Input
                  size="large"
                  type="number"
                  id="input-teleExtension"
                  status={errors.teleExtension ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.teleExtension ? (
              <small className="text-danger">{errors.teleExtension.message}</small>
            ) : null}
          </Col>
          <Col xs={24} className="mb-4">
            <label className="mb-1" htmlFor="input-patientType">
              Patient Type <span className="text-danger">*</span>
            </label>
            <Controller
              name="patientType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  size="large"
                  className="w-100"
                  status={errors.patientType ? "error" : undefined}
                  options={[
                    {
                      value: "facility",
                      label: "Facility"
                    },
                    {
                      value: "outpatient",
                      label: "Outpatient"
                    }
                  ]}
                  {...field}
                  onChange={e => {
                    field.onChange(e)
                    setUserTypes(e)
                  }}
                />
              )}
            />

            {errors.patientType ? (
              <small className="text-danger block">{errors.patientType.message}</small>
            ) : null}
          </Col>

          {userTypes === "facility" ? (
            <>
              <Col xs={24} className="mb-4">
                <label className="mb-1" htmlFor="input-facility">
                  Facility Name<span className="text-danger">*</span>
                </label>
                <Controller
                  name="facility"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      size="large"
                      className="w-100"
                      id="input-facility"
                      status={errors.facility ? "error" : undefined}
                      options={facilityOptions?.map(address => ({
                        value: address.facilityId,
                        label: address.name
                      }))}
                      {...field}
                      onChange={e => {
                        field.onChange(e)
                        setFacilityName(e)
                      }}
                    />
                  )}
                />
                {errors.facility ? (
                  <small className="text-danger">{errors.facility.message}</small>
                ) : null}
              </Col>
              {/* {getValues('facility').length ? (
            <>
           <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-unit">
              Unit 
            </label>
            <Controller
              name="unit"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  className="w-100"
                  status={errors.unit ? "error" : undefined}
                  size="large"
                  id="input-unit"
                  options={getUnits(facilityOptions, getValues("facility"))?.filterOptions?.map(
                    u => ({
                      value: u.units.unit,
                      label: u.units.unit
                    })
                  )}
                  {...field}
                />
              )}
            />
            {errors.unit ? <small className="text-danger">{errors.unit.message}</small> : null}
          </Col>
          {getValues('unit').length ? (
          <>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-room">
              Room 
            </label>
            <Controller
              name="room"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  className="w-100"
                  status={errors.room ? "error" : undefined}
                  size="large"
                  id="input-room"
                  options={getRooms(
                    facilityOptions,
                    getValues("facility"),
                    getValues("unit")
                  )?.room?.map(r => ({
                    value: r,
                    label: r
                  }))}
                  {...field}
                />
              )}
            />
            {errors.room ? <small className="text-danger">{errors.room.message}</small> : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-bedPosition">
              Bed Position 
            </label>
            <Controller
              name="bedPosition"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  className="w-100"
                  status={errors.bedPosition ? "error" : undefined}
                  size="large"
                  id="input-bedPosition"
                  options={getBedPositions(
                    facilityOptions,
                    getValues("facility"),
                    getValues("unit")
                  )?.bedPositions?.map(r => ({
                    value: `${r}_bed`,
                    label: r
                  }))}
                  {...field}
                />
              )}
            />
            {errors.bedPosition ? (
              <small className="text-danger">{errors.bedPosition.message}</small>
            ) : null}
          </Col> </>):null}</>):null}  */}
            </>
          ) : userTypes === "outpatient" ? (
            <>
              <Col xs={24} md={12} className="mb-4">
                <label className="mb-1" htmlFor="input-street">
                  Street <span className="text-danger">*</span>
                </label>
                <Controller
                  name="street"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-street"
                      status={errors.street ? "error" : undefined}
                      {...field}
                    />
                  )}
                />
                {errors.street ? (
                  <small className="text-danger">{errors.street.message}</small>
                ) : null}
              </Col>
              <Col xs={24} md={12} className="mb-4">
                <label className="mb-1" htmlFor="input-city">
                  City <span className="text-danger">*</span>
                </label>
                <Controller
                  name="city"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-city"
                      status={errors.city ? "error" : undefined}
                      {...field}
                      onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                    />
                  )}
                />
                {errors.city ? <small className="text-danger">{errors.city.message}</small> : null}
              </Col>
              <Col xs={24} md={12} className="mb-4">
                <label className="mb-1" htmlFor="input-state">
                  State <span className="text-danger">*</span>
                </label>
                <Controller
                  name="state"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-state"
                      status={errors.state ? "error" : undefined}
                      {...field}
                      onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                    />
                  )}
                />
                {errors.state ? (
                  <small className="text-danger">{errors.state.message}</small>
                ) : null}
              </Col>
              <Col xs={24} md={12} className="mb-4">
                <label className="mb-1" htmlFor="input-zipcode">
                  Zipcode <span className="text-danger">*</span>
                </label>
                <Controller
                  name="zipcode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      type="number"
                      id="input-zipcode"
                      status={errors.zipcode ? "error" : undefined}
                      {...field}
                      onChange={e => /^\d{0,5}$/.test(e.target.value) && field.onChange(e)}
                    />
                  )}
                />
                {errors.zipcode ? (
                  <small className="text-danger">{errors.zipcode.message}</small>
                ) : null}
              </Col>
            </>
          ) : null}

          {error ? (
            <Col className="mb-4" xs={24}>
              <p className="text-danger font-semibold">{error}</p>
            </Col>
          ) : patientValidateError?.message ? (
            <Col className="mb-4" xs={24}>
              <p className="text-danger font-semibold">
                <ExclamationCircleFilled className="text-[#E10505]" />{" "}
                {patientValidateError?.message}
              </p>
            </Col>
          ) : null}
          <Col xs={24}>
            <div className="flex flex-wrap gap-3">
              <Button size="large" type="primary" htmlType="submit" disabled={loading || patientValidateError?.message}>
                {loading ? "Submitting..." : "Submit"}
              </Button>
              <Button size="large" danger onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default AddPatientPhaseOne
