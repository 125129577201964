import React, { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { getGlucoseDetails } from "../../redux/actions/patientAction"

import moment from "moment"
import { Alert, Card, Table, Slider, Radio, DatePicker } from "antd"

import ReactApexChart from "react-apexcharts"
import Loading from "../layouts/Loading/Loading"
import { USA_DATE_FORMAT } from "../../utils/usaDateFormat"
import PatientGlucoseMeterProgressbar from "./PatientGlucoseMeterProgressbar"

const dateFormat = value => {
  return moment(value * 1000).format(`${USA_DATE_FORMAT} [at] hh:mm A`)
  // const date = new Date(value * 1000)
  // return `${`0${date.getMonth() + 1}`.slice(-2)}/${`0${date.getDate()}`.slice(
  //   -2
  // )}/${date.getFullYear()}`
}

const userTimeFormat = value => {
  return moment(value).format(`${USA_DATE_FORMAT} [at] hh:mm A`)
}

// const marks = {
//   70: {
//     label: <p className="font-medium text-danger">Critical</p>,
//     style: {
//       textAlign: "left",
//       marginLeft: "1rem",
//       marginTop: "0.5rem"
//     }
//   },
//   100: {
//     label: <p className="font-medium text-[#f59e0b]">Low</p>,
//     style: {
//       marginTop: "0.5rem"
//     }
//   },
//   130: {
//     label: <p className="font-medium text-[#22c55e]">Normal</p>,
//     style: {
//       marginTop: "0.5rem"
//     }
//   },
//   150: {
//     label: <p className="font-medium text-[#f59e0b]">High</p>,
//     style: {
//       marginTop: "0.5rem"
//     }
//   },
//   180: {
//     label: <p className="font-medium text-danger">Critical</p>,
//     style: {
//       marginTop: "0.5rem"
//     }
//   }
// }

const columns = [
  {
    key: "glucoseLevel",
    dataIndex: "glucoseLevel",
    title: "Glucose Level (mg/dl)"
  },
  {
    key: "readingType",
    title: "Reading Type",
    dataIndex: "readingType",
    render: (reading, { readingType }) =>
      !readingType ? "After Meal" : `${reading.charAt(0).toUpperCase() + reading.slice(1)} Meal`
  },
  {
    key: "activeDate",
    title: "Taken At",
    dataIndex: "activeDate",
    render: (_, { createdAt, readingDate }) =>
      readingDate ? userTimeFormat(readingDate) : dateFormat(createdAt)
  }
]

const lineChartOptions = (series, categories) => ({
  series,
  options: {
    chart: {
      type: "line",
      zoom: {
        enabled: false
      }
    },
    colors: ["#d7a66d"],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "straight"
    },
    xaxis: {
      categories
    }
  }
})

const STATE_ERR_MSG = "No reading has been taken for Glucose in past 30 days"
const SEVEN_DAYS_ERR_MSG = "No reading has been taken for Glucose in past 7 days"
const YEAR_ERR_MSG = "No reading has been taken for Glucose in past 365 days"
const MONTH_ERR_MSG = "No reading has been taken for Glucose in past 30 days"
const NO_ERR_MSG = "No reading has been taken for Glucose"

const PatientGlucoseMeterComponent = props => {
  const { deviceData, patientId, currentDate, durationObj } = props

  const dispatch = useDispatch()
  const { patientGlucoseDetail, loading } = useSelector(state => state.healthGlucose)
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)

  const [dateRange, setDateRange] = useState(null)
  const [errorMsg, setErrorMsg] = useState(STATE_ERR_MSG)
  const [programId, setProgramId] = useState(null)
  const [glucoData, setGlucoData] = useState([])
  const [duration, setDuration] = useState("month")
  const [graphSeries, setGraphSeries] = useState([])
  const [graphCategories, setGraphCategories] = useState([])

  useEffect(() => {
    if (!patientId || !patientMonitoringData || !duration) return

    // const program = patientMonitoringData?.rpm[0]?.programs?.find(
    //   device => device?.programId  === RPM_DEVICES[2].programId
    // )
    const selectedProgram = patientMonitoringData?.["rpm"]
      ?.map((programs, index) =>
        programs?.programs?.length ? programs?.programs?.map(program => program) : null
      )
      .flat(Infinity)
    const program = selectedProgram?.find(device => device?.programName === "glucose machine")

    if (!program) return
    else setProgramId(program?.programId)
    if (programId) {
      dispatch(
        getGlucoseDetails({
          patientId,
          endDate: currentDate,
          startDate: durationObj[duration],
          programId
        })
      )
    }
  }, [dispatch, patientId, duration, patientMonitoringData, programId])

  useEffect(() => {
    if (!patientGlucoseDetail?.length) {
      setGlucoData([])
      switch (duration) {
        case "week":
          setErrorMsg(SEVEN_DAYS_ERR_MSG)
          break
        case "year":
          setErrorMsg(YEAR_ERR_MSG)
          break
        case "month":
          setErrorMsg(MONTH_ERR_MSG)
          break
        default:
          setErrorMsg(NO_ERR_MSG)
      }
    } else {
      const formattedGraphDate = []
      const series = [
        {
          name: "Glucose Level",
          data: []
        }
      ]
      let decrement = patientGlucoseDetail
        ?.sort(
          (a, b) =>
            new Date(b?.readingDate || b?.createdAt) - new Date(a?.readingDate || a?.createdAt)
        )
        ?.map((reading, index) => ({ ...reading, index }))
      setGlucoData(decrement)
      const increment = [...decrement]?.reverse()

      increment !== undefined &&
        increment?.forEach((reading, index) => {
          const graphDate = new Date(reading?.readingDate)
          const formattedDate =
            `${`0${graphDate.getMonth() + 1}`.slice(-2)}` +
            `/${`0${graphDate.getDate()}`.slice(-2)}/${graphDate.getFullYear()}`
          formattedGraphDate.push(formattedDate)
          series[0].data.push(reading.glucoseLevel)
        })
      setGraphSeries(series)
      setGraphCategories(formattedGraphDate)
    }
  }, [patientGlucoseDetail, duration])

  const handleRangeChange = (date, dateString) => {
    setDateRange(date)
    if (!date) {
      setDuration("month")
      dispatch(
        getGlucoseDetails({
          patientId,
          programId,
          startDate: durationObj[duration],
          endDate: currentDate
        })
      )
    } else if (date && date?.length === 2) {
      setDuration(null)
      dispatch(
        getGlucoseDetails({
          patientId,
          programId,
          startDate: moment.utc(dateString[0]).startOf("day").toDate().toISOString(),
          endDate: moment.utc(dateString[1]).endOf("day").toDate().toISOString()
        })
      )
    }
  }

  const handleDuration = e => {
    setDuration(e.target.value)
    if (dateRange !== null) setDateRange(null)
  }

  return (
    <Card title="">
      <div>
        <div className="flex flex-col items-start justify-center gap-3 sm:flex-row sm:justify-between sm:items-center sm:gap-0 mb-4">
          <h2 className="text-2xl">Glucose Meter Reading</h2>
          {glucoData.length > 0 ? null :
            <div className="my-3 pb-2">
              <p className="font-semibold mb-2">Duration Pickers:</p>
              <div className="flex items-center gap-4">
                <Radio.Group value={duration} onChange={handleDuration}>
                  <Radio.Button value="week">Week</Radio.Button>
                  <Radio.Button value="month">Month</Radio.Button>
                  <Radio.Button value="year">Year</Radio.Button>
                </Radio.Group>
                <DatePicker.RangePicker
                  disabledDate={current => current > moment()}
                  value={dateRange}
                  onChange={handleRangeChange}
                />
              </div>
            </div>
          }
        </div>
        {loading ? (
          <Loading />
        ) : glucoData && glucoData.length ? (
          <>
            <div>
              <PatientGlucoseMeterProgressbar
                patientId={patientId}
                glucoData={glucoData}
              />
            </div>
            <Card title="Patient Reading Chart" className="my-5"
              extra={<div className="my-3 pb-2">
                <p className="font-semibold mb-2">Duration Pickers:</p>
                <div className="flex items-center gap-4">
                  <Radio.Group value={duration} onChange={handleDuration}>
                    <Radio.Button value="week">Week</Radio.Button>
                    <Radio.Button value="month">Month</Radio.Button>
                    <Radio.Button value="year">Year</Radio.Button>
                  </Radio.Group>
                  <DatePicker.RangePicker
                    disabledDate={current => current > moment()}
                    value={dateRange}
                    onChange={handleRangeChange}
                  />
                </div>
              </div>}
            >
              <ReactApexChart
                height={300}
                type="line"
                {...lineChartOptions(graphSeries, graphCategories)}
              />
            </Card>
            <Card title="Patient Reading">
              <Table columns={columns} dataSource={glucoData} rowKey="index" />
            </Card>
          </>
        ) : (
          <Alert type="error" message={errorMsg} />
        )}
      </div>
    </Card>
  )
}

export default PatientGlucoseMeterComponent
