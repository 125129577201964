export const GET_ALL_PATIENT_STATUS_REQUEST = "GET_ALL_PATIENT_STATUS_REQUEST";
export const GET_ALL_PATIENT_STATUS_SUCCESS = "GET_ALL_PATIENT_STATUS_SUCCESS";
export const GET_ALL_PATIENT_STATUS_FAIL = "GET_ALL_PATIENT_STATUS_FAIL";

export const UPDATE_PATIENT_STATUS_REQUEST = "UPDATE_PATIENT_STATUS_REQUEST";
export const UPDATE_PATIENT_STATUS_SUCCESS = "UPDATE_PATIENT_STATUS_SUCCESS";
export const UPDATE_PATIENT_STATUS_FAIL = "UPDATE_PATIENT_STATUS_FAIL";
export const UPDATE_PATIENT_STATUS_RESET = "UPDATE_PATIENT_STATUS_RESET"

export const GET_SHIPPING_STATUS_REQUEST = "GET_SHIPPING_STATUS_REQUEST";
export const GET_SHIPPING_STATUS_SUCCESS = "GET_SHIPPING_STATUS_SUCCESS";
export const GET_SHIPPING_STATUS_FAIL = "GET_SHIPPING_STATUS_FAIL";

export const UPDATE_SHIPPING_STATUS_REQUEST = "UPDATE_SHIPPING_STATUS_REQUEST";
export const UPDATE_SHIPPING_STATUS_SUCCESS = "UPDATE_SHIPPING_STATUS_SUCCESS";
export const UPDATE_SHIPPING_STATUS_FAIL = "UPDATE_SHIPPING_STATUS_FAIL";
export const UPDATE_SHIPPING_STATUS_RESET = "UPDATE_SHIPPING_STATUS_RESET";
