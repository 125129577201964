import React from "react"

import { useSelector } from "react-redux"

import { Tabs } from "antd"
import { UserOutlined, SafetyOutlined } from "@ant-design/icons"

import Profile from "./Profile"
import Security from "./Security"

const items = [
  {
    key: "1",
    label: (
      <div className="flex items-center">
        <UserOutlined />
        Profile
      </div>
    ),
    children: <Profile />
  },
  {
    key: "2",
    label: (
      <div className="flex items-center">
        <SafetyOutlined />
        Security
      </div>
    ),
    children: <Security />
  }
]

const AccountComponent = () => {
  const { userInfo } = useSelector(state => state.userInfo)

  return userInfo ? <Tabs defaultActiveKey="1" items={items} /> : null
}

export default AccountComponent
