import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"

import { Card, Button } from "antd"

import PatientFilters from "./PatientFilters"
import PatientTable from "../Dashboard/PatientTable"
import AddPatientModalPhaseOne from "./AddPatientPhaseOne"

import { sortBy } from "../../utils/sortBy"
import {RiAddCircleFill} from "react-icons/ri"

import { useDispatch, useSelector } from "react-redux"
import { getProviderList } from "../../redux/actions/providerAction"
import { criticalPatientAction } from "../../redux/actions/dashboardAction"
import { getMonitorListTypeAction } from "../../redux/actions/devicesActions"
import { getCaretakerListAction } from "../../redux/actions/caretakerActions"
import {
  getFilterUsersAction,
  getOnboardingPatientAction,
  resetFilterUsersAction
} from "../../redux/actions/ccmActions"
import moment from "moment"
import Loading from "../layouts/Loading/Loading"
import { savePatientAdvanceFilter } from "../../redux/actions/patientAction"
import { PATIENT_DIAGNOSTIC_RESET } from "../../redux/types/PatientConstant"

const PatientComponent = () => {
  const state = useLocation()
  const [sortColumn] = useState({
    path: sortBy.AtoZ,
    order: 1
  })
  const [searchQuery, setSearchQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(1) // eslint-disable-line
  const [addPatientShow, setAddPatientShow] = useState(false)
  const [sortValue, setSortValue] = useState("title")

  const dispatch = useDispatch()
  const {orgId, orgName} = useParams()

  const filter = useSelector(state => state.filter)
  const userInfo = useSelector(state => state.userInfo)

  const { loading, criticalPatient } = useSelector(state => state.criticalPatient)
  const {patientAdvFilter} = useSelector(state => state.patientAdvFilter)
  const [sortedData, setSortedData] = useState([])
  const [tempLoading, setTempLoading] = useState(false) // eslint-disable-line
  const [filterUserType, setFilterUserType] = useState([])
  const [allPatientFiltered, setAllPatientFiltered] = useState([])
  const [isFilteredPatient, setIsFilteredPatient] = useState(false)
  const [filteredWithoutSearch, setFilteredWithoutSearch] = useState([])

  useEffect(() => {
    // if (userInfo) {
    //    criticalPatient === undefined && !criticalPatient?.length && dispatch(criticalPatientAction());
    // }
    if (
      (userInfo &&
        filter?.getFilterUsersData === undefined &&
        filter?.loading === false &&
        state?.state?.mark === undefined) ||
      (criticalPatient === undefined && !criticalPatient?.length)
      // && filter?.getFilterUsersDataThroughDashboard === undefined
    ) {
      criticalPatient === undefined && !criticalPatient?.length && dispatch(criticalPatientAction(orgId))
    }
  }, [userInfo, criticalPatient])

  // const handleSort = (path, order) => {
  //     let temp = [];
  //     if (path === sortBy.AtoZ) {
  //         temp = sortedData?.sort((a, b) => (a?.firstName?.toLowerCase().trim() > b?.firstName?.toLowerCase().trim() ? 1 : -1));
  //     }
  //     if (path === sortBy.ZtoA) {
  //         temp = sortedData?.sort((a, b) => (a?.firstName?.toLowerCase().trim() < b?.firstName?.toLowerCase().trim() ? 1 : -1));
  //     }
  //     setSortedData(temp);
  //     setSortColumn({ path, order });
  // };
  // console.log('sorted Data', sortedData)

  const handleSearch = query => {
    setSearchQuery(query)
    setCurrentPage(1)
  }

  const showSearchedData = () => {
    let allPatients = []
    const query = searchQuery?.toLowerCase()
    if (filter?.getFilterUsersData && patientAdvFilter?.userType !== "outpatient") {
      if (searchQuery) {
        allPatients = filter?.getFilterUsersData?.filter(m => {
          const fullName = `${m?.firstName}${m?.middleName || ""}${m?.lastName}`.toLowerCase()
          const reversedFullName = `${m?.lastName}${m?.middleName || ""}${m?.firstName}`.toLowerCase()
          const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()
          return (
            m?.firstName?.toLowerCase().includes(query) ||
            m?.middleName?.toLowerCase().includes(query) ||
            m?.lastName?.toLowerCase().includes(query) ||
            m?.email?.toLowerCase().includes(query) ||
            String(m?.mobileNumber)?.toLowerCase().includes(query) ||
            m?.userName?.toLowerCase().includes(query) ||
            m?.id?.toLowerCase().includes(query) ||
            fullName.includes(trimmedSearchValue) ||
            reversedFullName.includes(trimmedSearchValue)
          )
        })
      } else {
        allPatients = filter?.getFilterUsersData
      }
      setSortValue("title")
    } else if (criticalPatient !== undefined) {
      if (searchQuery) {
        let tempAllPatient = []
        if (sortedData?.length === 0) {
          tempAllPatient = isFilteredPatient ? filteredWithoutSearch : criticalPatient
        } else {
          tempAllPatient = sortedData
        }
        allPatients = tempAllPatient.filter(m => {
          const fullName = `${m.firstName}${m?.middleName || ""}${m.lastName}`.toLowerCase()
          const reversedFullName = `${m.lastName}${m?.middleName || ""}${m.firstName}`.toLowerCase()
          const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()
          return (
            m?.firstName?.toLowerCase().includes(query) ||
            m?.middleName?.toLowerCase().includes(query) ||
            m?.lastName?.toLowerCase().includes(query) ||
            m?.email?.toLowerCase().includes(query) ||
            String(m?.mobileNumber)?.toLowerCase().includes(query) ||
            m?.userName?.toLowerCase().includes(query) ||
            m?.id?.toLowerCase().includes(query) ||
            fullName.includes(trimmedSearchValue) ||
            reversedFullName.includes(trimmedSearchValue)
          )
        })
      } else if (patientAdvFilter?.userType ) {
        allPatients = (isFilteredPatient ? filteredWithoutSearch : criticalPatient)?.filter(
          patient => patient?.userType === patientAdvFilter?.userType
        )
      } else if (isFilteredPatient) {
        allPatients = filteredWithoutSearch
      } else {
        allPatients = criticalPatient
      }
    }
    allPatients = allPatients.map((patient) => {
      if (patient?.manualLog?.length) {
        let ccmLogCount = 0
        let rpmLogCount = 0
        let manualLogCount = 0
        if (moment(patient?.manualLogActiveTime).month() === moment().month() && moment(patient?.manualLogActiveTime).year() === moment().year()) {
          patient?.manualLog?.map((manual) => {
            if (manual?.careType === 'ccm' && moment(manual?.careDate).month() === moment().month() && moment(manual?.careDate).year() === moment().year()) {
              ccmLogCount += manual?.count
            }
            else if (manual?.careType === 'rpm' && moment(manual?.careDate).month() === moment().month() && moment(manual?.careDate).year() === moment().year()) {
              rpmLogCount += manual?.count
            }
          })
          patient = { ...patient, "rpmManualLogCount": rpmLogCount, "ccmManualLogCount": ccmLogCount }
        } else {
          patient = { ...patient, "rpmManualLogCount": rpmLogCount, "ccmManualLogCount": ccmLogCount, "manualLogCount": manualLogCount }
        }
      }
      if (patient?.rpmActiveDate?.length !== 0 && moment(patient?.rpmActiveDate).month() === moment().month()
        && moment(patient?.rpmActiveDate).year() === moment().year()) {
        return { ...patient, "monthlyLastestTotalReadingRPM": patient?.rpmReadingCount }
      } else {
        return { ...patient, "monthlyLastestTotalReadingRPM": 0 }
      }
    })
    let temp = []
    if (sortColumn.path === sortBy.AtoZ) {
      temp = allPatients?.sort((a, b) =>
        a?.firstName?.toLowerCase().trim() > b?.firstName?.toLowerCase().trim() ? 1 : -1
      )
    }
    if (sortColumn.path === sortBy.ZtoA) {
      temp = allPatients?.sort((a, b) =>
        a?.firstName?.toLowerCase().trim() < b?.firstName?.toLowerCase().trim() ? 1 : -1
      )
    }
    setSortedData(temp)
    setAllPatientFiltered(temp)
    if (temp?.length === criticalPatient?.length)
      setFilterUserType(temp) // this is added to handle the advancce filter condition 
  }

  useEffect(() => {
    showSearchedData()
  }, [filter, criticalPatient, searchQuery, patientAdvFilter]) //eslint-disable-line

  const applyFilters = (type, data) => {
    dispatch(savePatientAdvanceFilter(data))
    setCurrentPage(1)
    if (type === "normal" && data?.facilityId) {
      dispatch(getFilterUsersAction(data, orgId))
    } else if (type === "normal" && !data?.facilityId) {
      setSortedData(filterUserType?.filter(patient => patient?.userType === data?.userType))
    } else {
      getOnBoardingPatients()
    }
  }

  useEffect(() => {
    dispatch({ type: PATIENT_DIAGNOSTIC_RESET })
  }, [])
  
  const getOnBoardingPatients = async () => {
    await dispatch(resetFilterUsersAction()).then(() => {
      //  dispatch(getOnboardingPatientAction(userInfo?.userInfo?.orgId))
    })
  }

  // if (sortColumn.path === sortBy.AtoZ) {
  //     sortedData = allPatients?.sort((a, b) => (a?.firstName?.toLowerCase().trim() > b?.firstName?.toLowerCase().trim() ? 1 : -1));
  // }
  const resetFilters = async cb => {
    setIsFilteredPatient(false)
    setAllPatientFiltered([])
    await dispatch(resetFilterUsersAction())
    if (cb) {
      cb()      
    }
  }

  // if (sortColumn.path === sortBy.ZtoA) {
  //     sortedData = allPatients?.sort((a, b) => (a?.firstName?.toLowerCase().trim() < b?.firstName?.toLowerCase().trim() ? 1 : -1));
  // }

  const sortReadingCount = val => {
    const tempData = []
    setIsFilteredPatient(true)
    const countExistsArr = allPatientFiltered.filter(
      x =>
        x?.rpmReadingCount !== null &&
        x?.rpmReadingCount !== undefined &&
        x?.rpmActiveDate &&
        moment(x?.rpmActiveDate).month() === moment().month() &&
        moment(x?.rpmActiveDate).year() === moment().year()
    )
    const noCountArr = allPatientFiltered.filter(
      x => x?.rpmReadingCount === undefined || x?.rpmReadingCount === null
    )
    const ascCountArr = countExistsArr.sort((a, b) => a?.rpmReadingCount - b?.rpmReadingCount)
    const descCountArr = countExistsArr.sort((a, b) => b?.rpmReadingCount - a?.rpmReadingCount)
    if (val === "asc") {
      const sd = noCountArr.concat(ascCountArr)
      // console.log(sd)
      setTempLoading(true)
      setSortedData([...tempData])
      setFilteredWithoutSearch([...sd])
      setTimeout(() => {
        setTempLoading(false)
        setSortedData([...sd])
      }, 1500)
      //    setSortedData([...sd])
    } else {
      const sd = descCountArr.concat(noCountArr)
      // console.log(sd)
      setTempLoading(true)
      setSortedData([...tempData])
      setFilteredWithoutSearch([...sd])
      setTimeout(() => {
        setTempLoading(false)
        setSortedData([...sd])
      }, 1500)
      //    setSortedData([...sd])
    }
  }
  const sortLatestReading = val => {
    setIsFilteredPatient(true)
    const tempData = []
    const dateExistsArr = allPatientFiltered?.filter(
      x =>
        x?.rpmActiveDate !== null &&
        x?.rpmActiveDate !== undefined &&
        x?.rpmActiveDate !== "" &&
        x?.rpmActiveDate &&
        moment(x?.rpmActiveDate).month() === moment().month() &&
        moment(x?.rpmActiveDate).year() === moment().year()
    )
    const noDateArr = sortedData?.filter(
      x => x?.rpmActiveDate === undefined && x?.rpmActiveDate === null && x?.rpmActiveDate === ""
    )
    const ascDateArr = dateExistsArr.sort(
      (a, b) => new Date(a?.rpmActiveDate) - new Date(b?.rpmActiveDate)
    )
    const descDateArr = dateExistsArr.sort(
      (a, b) => new Date(b?.rpmActiveDate) - new Date(a?.rpmActiveDate)
    )
    if (val === "asc") {
      const sd = noDateArr.concat(ascDateArr)
      // console.log(sd)
      setTempLoading(true)
      setSortedData([...tempData])
      setFilteredWithoutSearch([...sd])
      setTimeout(() => {
        setTempLoading(false)
        setSortedData([...sd])
      }, 1500)
      //    setSortedData([...sd])
    } else {
      const sd = descDateArr.concat(noDateArr)
      // console.log(sd)
      setTempLoading(true)
      setSortedData([...tempData])
      setFilteredWithoutSearch([...sd])
      setTimeout(() => {
        setTempLoading(false)
        setSortedData([...sd])
      }, 1500)
      //    setSortedData([...sd])
    }
  }

  const filterCriticalReading = val => {
    setIsFilteredPatient(true)
    const countExistsArr = allPatientFiltered.filter(x => x?.criticalStatus === true)
    setTempLoading(true)
    setTimeout(() => {
      setTempLoading(false)
      setSortedData([...countExistsArr])
      setFilteredWithoutSearch([...countExistsArr])
    }, 1500)
  }

  const handleAddPatientClose = () => setAddPatientShow(false)

  const handleAddPatientShow = () => {
    // dispatch(getMonitorListTypeAction())
    //  dispatch(getCaretakerListAction())
    //  dispatch(getProviderList())
    setAddPatientShow(true)
  }

  useEffect(() => {
    if (state?.state?.mark === undefined) {
     // dispatch(resetFilterUsersAction())
    }
  }, [])

  return (
    <>
        <Card
        title={<h3 className="text-2xl bold mb-2"> Patients</h3>}
        className="mt-4"
        headStyle={{ paddingTop: "16px", paddingBottom: "16px" }}
        extra={
          userInfo?.userInfo?.grantType === "caretaker_all" ||
          userInfo?.userInfo?.grantType === "caretaker_register_patient" ? (
            <Button size="large" type="primary" onClick={handleAddPatientShow}>
             <div className="flex justify-center items-center">
                <RiAddCircleFill className="mr-2"/> Add Patient
              </div>
            </Button>
          ) : null
        }>
        <PatientFilters
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          setSearchQuery={setSearchQuery}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
          sortReadingCount={sortReadingCount}
          sortLatestReading={sortLatestReading}
          filterCriticalReading={filterCriticalReading}
          sortValue={sortValue}
          setSortValue={setSortValue}
        />
        <PatientTable loading={loading || tempLoading || filter?.loading} sortedData={sortedData} />
      </Card>
      <AddPatientModalPhaseOne
        addPatientShow={addPatientShow}
        setAddPatientShow={setAddPatientShow}
        handleAddPatientShow={handleAddPatientShow}
        handleAddPatientClose={handleAddPatientClose}
      />
    </>
  )
}

export default PatientComponent
