import { useEffect, useMemo, useState } from "react"

import moment from "moment"
import DatePicker from "react-datepicker"
import { useToasts } from "react-toast-notifications"
import { Modal, Input, Row, Col, Button, Radio, Switch } from "antd"

import { useDispatch, useSelector } from "react-redux"
import {
  getPatientInfo,
  getPatientOptoutAction,
  postPatientOptoutAction,
  UpdatePatientAction,
  patientDetailsValidateAction,
  patientDetailsValidateResetAction
  // getPatientAddress
} from "../../../redux/actions/patientAction"
import { getPatientStatusAction } from "../../../redux/actions/patientStatusAction"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import debounce from 'lodash/debounce';
import { ExclamationCircleFilled } from "@ant-design/icons"

import Loading from "../../layouts/Loading/Loading"

import "./patientModal.css"
import { PatternFormat } from "react-number-format"
import formatPhoneNumber from "../../../utils/formatPhoneNumber"

const { TextArea } = Input

const emailRegex =
  /^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/
const alphaRegex = /^[a-zA-Z\s]+$/

const schema = yup
  .object()
  .shape(
    {
      dob: yup.string().required("Invalid DOB"),
      gender: yup.string().required("Invalid Gender"),
      // email: yup.string().email().required("Invalid Email"),
      userName: yup.string().matches(/^[a-zA-Z0-9]*$/, "Only alphabets and numbers are allowed"),
      // mobileNumber: yup.string().min(10).max(10).required("Invalid Phone Number"),
      middleName: yup.string().test("checkMiddleName", "", (value, { createError, path }) => {
        if (value.length && !alphaRegex.test(value)) {
          return createError({
            path,
            message: "Middle Name should contain alphabets only"
          })
        } else {
          return true
        }
      }),
      lastName: yup
        .string()
        .min(1)
        .max(100)
        .matches(alphaRegex, "Last Name should contain alphabets only")
        .required("Invalid Last Name"),
      firstName: yup
        .string()
        .min(1)
        .max(100)
        .matches(alphaRegex, "First Name should contain alphabets only")
        .required("Invalid First Name"),
      telephone: yup.string().when({
        is: value => value && value.length > 0,
        then: () => yup.string().required("Invalid Telephone")
      }),
      teleExtension: yup.mixed().when("telephone", {
        is: value => value && value.length > 0,
        then: () => yup.string().min(1).max(5).required("Invalid Extension")
      }),
      email: yup
        .string()
        .matches(emailRegex, "Invalid email address")
        .when("mobileNumber", {
          is: value => !value || value.length === 0,
          then: () =>
            yup
              .string()
              .matches(emailRegex, "Invalid email address")
              .required("Enter either Email or mobileNumber"),
          otherwise: () =>
            yup.string().test("checkEmail", "", (value, { createError, path }) => {
              if (value.length && !emailRegex.test(value)) {
                return createError({
                  path,
                  message: "Invalid email address"
                })
              } else {
                return true
              }
            })
        }),
      mobileNumber: yup
        .string()
        .when("email", {
          is: value => !value || value.length === 0,
          then: () => yup.string().required("Enter either Email or mobileNumber"),
          otherwise: () =>
            yup.string().test("mobileNumber", (value, { createError, path }) => {
              if (value.length && value.replace(/[^\d]/g, '').length !== 10) {
                return createError({
                  path,
                  message: "Mobile Number should be of 10 digits"
                })
              } else {
                return true
              }
            })
        }),
    },
    ["email", "mobileNumber"]
  )
  .required()

const OPTOUT_SUCCESS_MSG = "Patient Opted Out Successfully"
const OPTOUT_ERROR_MSG = "Something went wrong"
const OPTIN_SUCCESS_MSG = "Patient Opted In Successfully"
const PATIENT_UPDT_SUCCESS_MSG = "Patient Updated Successfully"

const UpdatePatientBasicDetails = props => {
  const { updatePatientShow, setUpdatePatientShow, handleUpdatePatientClose, patientOptOut } = props

  const [priorDate, setPriorDate] = useState()
  const [isFocused, setIsFocused] = useState(false);
  const [isTelephoneFocused, setIsTelephoneFocused] = useState(false);
  const [reason, setReason] = useState("")
  const [isOptout, setIsOptout] = useState(false)
  const [initialOptoutFlag, setInitialOptoutFlag] = useState(false)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [dob, setDob] = useState(null)
  const [debounceTimeout, setDebounceTimeout] = useState(800);

  const dispatch = useDispatch()
  const { loading, error } = useSelector(state => state.updatePatient)
  const { patientLoading, patientInfoDetail } = useSelector(state => state.patientInfo)
  const { loading: postPatientOptOutLoading } = useSelector(state => state.postPatientOptOut)

  const { error: patientValidateError } = useSelector(state => state.patientDetailsValidate)

  const { addToast } = useToasts()

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      dob: moment(patientInfoDetail?.dob).format("MM/DD/YYYY") ?? "",
      email: patientInfoDetail?.email || "",
      userName: patientInfoDetail?.userName || "",
      gender: patientInfoDetail?.gender,
      telephone: patientInfoDetail?.telephone || "",
      mobileNumber: patientInfoDetail?.mobileNumber || "",
      lastName: patientInfoDetail?.lastName,
      firstName: patientInfoDetail?.firstName,
      teleExtension: patientInfoDetail?.extension || "",
      middleName: patientInfoDetail?.middleName || ""
    }
  })

  const debounceFetcher = useMemo(() => {
    const validatePatient = (validationData) => {
      if (!validationData?.firstName || !validationData?.lastName || !validationData?.dob) {
        return
      }
      if (patientInfoDetail?.firstName !== validationData?.firstName || patientInfoDetail?.lastName !== validationData?.lastName || patientInfoDetail?.dob !== validationData?.dob) {
        dispatch(patientDetailsValidateAction(validationData))
      } else if (patientInfoDetail?.firstName === validationData?.firstName && patientInfoDetail?.lastName === validationData?.lastName && patientInfoDetail?.dob === validationData?.dob) {
        dispatch(patientDetailsValidateResetAction())
      }
    }
    return debounce(validatePatient, debounceTimeout);
  }, [dispatch, debounceTimeout]);

  useEffect(() => {
    if (firstName && lastName && dob) {
      const validationData = {
        firstName: firstName,
        lastName: lastName,
        dob: moment(dob)?.format("YYYY-MM-DD")
      }
      debounceFetcher(validationData)
    }
  }, [firstName, lastName, dob])

  const handleClose = () => {
    reset()
    if (patientOptOut === null || patientOptOut?.optOut === false) {
      setReason("")
    }
    if (patientOptOut?.optOut) {
      setReason(patientOptOut?.notes)
    }
    setIsOptout(patientOptOut?.optOut)
    dispatch(patientDetailsValidateResetAction())
    setDob(null)
    setFirstName("")
    setLastName("")
    handleUpdatePatientClose()
  }

  useEffect(() => {
    if (!updatePatientShow) {
      dispatch({ type: 'UPDATE_PATIENT_RESET' })
    }
    setFirstName(patientInfoDetail?.firstName,)
    setLastName(patientInfoDetail?.lastName)
    setDob(moment(patientInfoDetail?.dob).format("MM/DD/YYYY") ?? "")
  }, [updatePatientShow])

  useEffect(() => {
    if (patientOptOut?.optOut) {
      setIsOptout(patientOptOut?.optOut)
      setReason(patientOptOut?.notes)
      setInitialOptoutFlag(patientOptOut?.optOut)
    } else if (patientOptOut?.optOut === false) {
      setIsOptout(patientOptOut?.optOut)
      setReason("")
    }
  }, [patientOptOut])

  // useEffect(() => {
  //   dispatch(getPatientAddress(patientInfoDetail?.id))
  // }, [])

  useEffect(() => {
    if (patientInfoDetail?.dob !== undefined) {
      const manageDate = moment(patientInfoDetail?.dob).subtract(150, "years")
      setPriorDate(manageDate._d)
    }
  }, [patientInfoDetail?.dob])

  const onSubmit = async data => {
    const {
      dob,
      email,
      gender,
      userName,
      lastName,
      firstName,
      middleName,
      telephone,
      mobileNumber,
      teleExtension
    } = data
    const otpOutData = {
      "optOut": isOptout,
      "notes": reason,
    }
    if (!initialOptoutFlag && isOptout) {
      const res = await dispatch(postPatientOptoutAction(patientInfoDetail?.id, otpOutData))
      if (res?.status === 200) {
        addToast(OPTOUT_SUCCESS_MSG, {
          appearance: "success",
          autoDismiss: true
        })
        setUpdatePatientShow(false)
        dispatch(getPatientInfo({ patientId: patientInfoDetail?.id }))
        dispatch(getPatientOptoutAction(patientInfoDetail?.id))
        dispatch(getPatientStatusAction(patientInfoDetail?.id))
        return
      } else {
        addToast(OPTOUT_ERROR_MSG, {
          appearance: "error",
          autoDismiss: true
        })
        return
      }
    }
    if (initialOptoutFlag && !isOptout) {
      const res = await dispatch(postPatientOptoutAction(patientInfoDetail?.id, otpOutData))
      if (res?.status === 200) {
        setReason("")
        addToast(OPTIN_SUCCESS_MSG, {
          appearance: "success",
          autoDismiss: true
        })
        setUpdatePatientShow(false)
        dispatch(getPatientInfo({ patientId: patientInfoDetail?.id }))
        dispatch(getPatientOptoutAction(patientInfoDetail?.id))
        dispatch(getPatientStatusAction(patientInfoDetail?.id))
        return
      } else {
        addToast(OPTOUT_ERROR_MSG, {
          appearance: "error",
          autoDismiss: true
        })
        return
      }
    }
    if (initialOptoutFlag && isOptout && reason !== patientOptOut?.notes) {
      const res = await dispatch(postPatientOptoutAction(patientInfoDetail?.id, otpOutData))
      if (res?.status === 200) {
        addToast(OPTOUT_SUCCESS_MSG, {
          appearance: "success",
          autoDismiss: true
        })
        setUpdatePatientShow(false)
        dispatch(getPatientInfo({ patientId: patientInfoDetail?.id }))
        dispatch(getPatientOptoutAction(patientInfoDetail?.id))
        return
      } else {
        addToast(OPTOUT_ERROR_MSG, {
          appearance: "error",
          autoDismiss: true
        })
        return
      }
    }
    const result = dispatch(
      UpdatePatientAction({
        gender,
        lastName,
        firstName,
        id: patientInfoDetail.id,
        dob: moment(dob).format(),
        countryCode: patientInfoDetail?.countryCode.toUpperCase(),
        ...(middleName.length
          ? {
            middleName
          }
          : {
            middleName: null
          }),
        ...(userName.length ? { userName } : { userName: `${firstName}${middleName}${lastName}` }),
        ...(telephone.length ? { telephone: data?.telephone?.replace(/[^\d]/g, ''), extension: teleExtension } : {}),
        ...(email && email.length ? { email } : {}),
        ...(mobileNumber.length ? { mobileNumber: data?.mobileNumber?.replace(/[^\d]/g, '') } : {})
      })
    )
    result && result?.then(res => {
      if (res?.status === 200 || res?.status === 201) {
        setUpdatePatientShow(false)
        addToast(PATIENT_UPDT_SUCCESS_MSG, {
          appearance: "success",
          autoDismiss: true
        })
        const patientId = { patientId: patientInfoDetail?.id }
        dispatch(getPatientInfo(patientId))
      }
    })
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const handleTelephoneFocus = () => {
    setIsTelephoneFocused(true);
  };

  const handleTelephoneBlur = () => {
    setIsTelephoneFocused(false);
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    return formatPhoneNumber(value)
  };

  return (
    <Modal
      width={800}
      footer={null}
      onCancel={handleClose}
      open={updatePatientShow}
      title={<h4 className="text-xl">Update Patient</h4>}>
      {patientLoading ? (
        <Loading minHeight="80vh" />
      ) : (
        <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={20}>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-1" htmlFor="input-firstName">
                First Name <span className="text-danger">*</span>
              </label>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-firstName"
                    status={errors.firstName ? "error" : undefined}
                    suffix={patientValidateError?.message ? <ExclamationCircleFilled className="text-[#E10505] text-sm" /> : null}
                    // onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                    value={field.value}
                    onChange={(e) => {
                      /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e.target.value)
                      setFirstName((e.target.value).trim())
                    }}
                  // {...field}
                  />
                )}
              />
              {errors.firstName ? (
                <small className="text-danger">{errors.firstName.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-1" htmlFor="input-middleName">
                Middle Name
              </label>
              <Controller
                name="middleName"
                control={control}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-middleName"
                    status={errors.middleName ? "error" : undefined}
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.middleName ? (
                <small className="text-danger">{errors.middleName.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-1" htmlFor="input-lastName">
                Last Name <span className="text-danger">*</span>
              </label>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-lastName"
                    status={errors.lastName ? "error" : undefined}
                    {...field}
                    // onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                    suffix={patientValidateError?.message ? <ExclamationCircleFilled className="text-[#E10505] text-sm" /> : null}
                    value={field.value}
                    onChange={(e) => {
                      /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e.target.value)
                      setLastName((e.target.value).trim())
                    }}
                  />
                )}
              />
              {errors.lastName ? (
                <small className="text-danger">{errors.lastName.message}</small>
              ) : null}
            </Col>{" "}
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-1" htmlFor="input-userName">
                Username
              </label>
              <Controller
                name="userName"
                control={control}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-userName"
                    status={errors.userName ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.userName ? <small className="text-danger">{errors.userName.message}</small> : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-1" htmlFor="input-email">
                Email <span className="text-danger">*</span>
              </label>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    type="email"
                    id="input-email"
                    status={errors.email ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.email ? <small className="text-danger">{errors.email.message}</small> : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-1" htmlFor="input-mobileNumber">
                Mobile Number <span className="text-danger">*</span>
              </label>
              <Controller
                name="mobileNumber"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  const { ref, ...rest } = field
                  return (
                    <PatternFormat
                      format="(###) ###-####"
                      className={`formatted-mobile-input flex p-2 text-[1rem] w-full  border border-${errors.mobileNumber ? "danger" : isFocused ? "primary" : "#dfdfdf"
                        }  rounded-lg focus:outline-none`}
                      id="input-mobileNumber"
                      {...rest}
                      onChange={e => rest.onChange(handleMobileChange(e))}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />)
                }
                }
              />
              {errors.mobileNumber ? (
                <small className="text-danger">{errors.mobileNumber.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4 relative">
              <label className="mb-1" htmlFor="input-dob">
                DOB <span className="text-danger">*</span>
              </label>
              <Controller
                name="dob"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    required
                    selected={moment(field.value).toDate()}
                    dateFormat={"MM/dd/yyyy"}
                    onChange={date => {
                      field.onChange(date)
                      setDob(date)
                    }}
                    dateFormatCalendar={"MMM yyyy"}
                    minDate={priorDate}
                    maxDate={Date.now()}
                    showPopperArrow={false}
                    placeholderText="Date of Birth"
                    withPortal
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    className={`border p-3 rounded w-100 h-[40px] ${errors.dob ? "border-danger" : ""}`}
                  // {...field}
                  />
                )}
              />
              {patientValidateError?.message ? <ExclamationCircleFilled className="absolute top-10 right-[21px] text-[#E10505] text-sm" /> : null}
              {errors.dob ? <small className="text-danger">{errors.dob.message}</small> : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-1" htmlFor="input-gender">
                Gender at time of birth <span className="text-danger">*</span>
              </label>
              <Controller
                name="gender"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Radio.Group {...field} className="block">
                    <Radio value="male">Male</Radio>
                    <Radio value="female">Female</Radio>
                  </Radio.Group>
                )}
              />
              {errors.gender ? (
                <small className="text-danger block">{errors.gender.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-1" htmlFor="input-telephone">
                Telephone
              </label>
              <Controller
                name="telephone"
                control={control}
                render={({ field }) => {
                  const { ref, ...rest } = field
                  return (
                    <PatternFormat
                      format="(###) ###-####"
                      className={`formatted-input-telephone flex p-2 text-[1rem] w-full  border border-${errors.telephone ? "danger" : isTelephoneFocused ? "primary" : "#dfdfdf"
                        }  rounded-lg focus:outline-none`}
                      id="input-telephone"
                      {...rest}
                      onChange={e => rest.onChange(handleMobileChange(e))}
                      onFocus={handleTelephoneFocus}
                      onBlur={handleTelephoneBlur}
                    />
                  )
                }
                }
              />
              {errors.telephone ? (
                <small className="text-danger">{errors.telephone.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-1" htmlFor="input-teleExtension">
                Telephone Extension
              </label>
              <Controller
                name="teleExtension"
                control={control}
                render={({ field }) => (
                  <Input
                    type="number"
                    size="large"
                    id="input-teleExtension"
                    status={errors.teleExtension ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.teleExtension ? (
                <small className="text-danger">{errors.teleExtension.message}</small>
              ) : null}
            </Col>
            <Col className="mb-4 flex justify-start items-center gap-2 text-lg" xs={24}>
              <span className="text-lg font-semibold">Service Status</span>
              <Switch
                checkedChildren="Denied"
                unCheckedChildren="Active"
                // className="bg-[#007AFF]"
                checked={isOptout}
                onChange={checked => setIsOptout(checked)}
                style={isOptout ? { backgroundColor: "#A8A8A8" } : { backgroundColor: "#007AFF" }}
              />
            </Col>
            {isOptout &&
              <Col className="mb-4 flex justify-start items-center gap-2 text-lg" xs={24}>
                <TextArea
                  value={reason}
                  showCount
                  maxLength={100}
                  minLength={10}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Please specify the reason for denial(10 to 100 characters)"
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                />
              </Col>
            }
            {error ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-semibold">{error}</p>
              </Col>
            ) : patientValidateError?.message ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-semibold">
                  <ExclamationCircleFilled className="text-[#E10505]" />{" "}
                  {patientValidateError?.message}
                </p>
              </Col>
            ) : null}
            <Col xs={24}>
              <div className="flex flex-wrap gap-3">
                <Button size="large" danger onClick={handleClose}>
                  Cancel
                </Button>
                <Button size="large" type="primary" htmlType="submit" disabled={loading || postPatientOptOutLoading || (reason.length <= 10 && isOptout) || patientValidateError?.message}>
                  {(loading || postPatientOptOutLoading) ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      )}
    </Modal>
  )
}

export default UpdatePatientBasicDetails
