import { useParams } from "react-router-dom"

import moment from "moment"
import { DatePicker, List, Modal, Button, Divider, Spin } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { getLatestReadings } from "../../../redux/actions/patientAction"

const monthFormat = "YYYY/MM"

const ReadingDiv = props => {
  const {
    readingMonth,
    setReadingMonth,
    showReadingModal,
    setShowReadingModal,
    patientLatestReading,
    currentMonthReading
  } = props

  const patientId = useParams()
  const dispatch = useDispatch()

  const handleReadingModalClose = () => {
    dispatch(getLatestReadings(patientId.id))
    setShowReadingModal(false)
  }

  const onChange = (date, dateString) => {
    const year = dateString.slice(0, 4)
    const month = parseInt(dateString.slice(5, 7))
    setReadingMonth(month)
    dispatch(getLatestReadings(patientId.id, month, year))
  }

  const { loading } = useSelector(state => state.patientReading)

  // const currMonth = new Date().getMonth() + 1
  // const currYear = new Date().getFullYear()

  const disableDate = current => {
    return current > moment()
  }

  return (
    <Modal
      footer={null}
      open={showReadingModal}
      onCancel={() => setShowReadingModal(false)}
      title={<h4 className="text-xl">Reading Count</h4>}>
      <div className="pt-4">
        {loading ? <div className="d-flex justify-content-center align-items-center my-1">
          <Spin size="large" />
        </div> :
          <p>
            {moment()
              .month(
                patientLatestReading && patientLatestReading.length
                  ? patientLatestReading[0]?.month - 1
                  : readingMonth - 1
              )
              .format("MMMM")}{" "}
            Reading Count:
            <span className="pl-2 text-current font-bold"> {patientLatestReading?.length ||
              patientLatestReading?.count ||
              (patientLatestReading?.[0]?.month === moment().month() ||
                moment().month() + 1 === readingMonth
                ? currentMonthReading
                : 0)}</span>
          </p>}
        <DatePicker
          disabledDate={disableDate}
          picker="month"
          onChange={onChange}
          format={monthFormat}
          className="mt-4 w-full"
        />

        <div className="flex flex-wrap items-center gap-3 mt-4">
          <Button type="primary" onClick={() => setShowReadingModal(false)}>
            Confirm
          </Button>
          <Button danger onClick={handleReadingModalClose}>
            Reset
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ReadingDiv
