const axios = require("axios")

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

axios.defaults.headers.common["time-zone"] = `${timezone}`

// let token = JSON.parse(localStorage.getItem("token"));

const token = window.token || JSON.parse(localStorage.getItem("token"))

if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`

// 4 min expire time.
axios.defaults.timeout = 240000

exports.axiosLengthyGet = (url, config) => axios.get(url, config)
