export const GET_RTM_PAIN_MANAGEMENT_DATA_REQUEST = "GET_RTM_PAIN_MANAGEMENT_DATA_REQUEST"
export const GET_RTM_PAIN_MANAGEMENT_DATA_SUCCESS = "GET_RTM_PAIN_MANAGEMENT_DATA_SUCCESS"
export const GET_RTM_PAIN_MANAGEMENT_DATA_FAIL = "GET_RTM_PAIN_MANAGEMENT_DATA_FAIL"

export const POST_RTM_PAIN_MANAGEMENT_DATA_REQUEST = "POST_RTM_PAIN_MANAGEMENT_DATA_REQUEST"
export const POST_RTM_PAIN_MANAGEMENT_DATA_SUCCESS = "POST_RTM_PAIN_MANAGEMENT_DATA_SUCCESS"
export const POST_RTM_PAIN_MANAGEMENT_DATA_FAIL = "POST_RTM_PAIN_MANAGEMENT_DATA_FAIL"

export const GET_RTM_PAIN_MANAGEMENT_READING_REQUEST = "GET_RTM_PAIN_MANAGEMENT_READING_REQUEST"
export const GET_RTM_PAIN_MANAGEMENT_READING_SUCCESS = "GET_RTM_PAIN_MANAGEMENT_READING_SUCCESS"
export const GET_RTM_PAIN_MANAGEMENT_READING_FAIL = "GET_RTM_PAIN_MANAGEMENT_READING_FAIL"

export const GET_RTM_ADDICTION_MEDICINE_DATA_REQUEST = "GET_RTM_ADDICTION_MEDICINE_DATA_REQUEST"
export const GET_RTM_ADDICTION_MEDICINE_DATA_SUCCESS = "GET_RTM_ADDICTION_MEDICINE_DATA_SUCCESS"
export const GET_RTM_ADDICTION_MEDICINE_DATA_FAIL = "GET_RTM_ADDICTION_MEDICINE_DATA_FAIL"

export const POST_RTM_ADDICTION_MEDICINE_DATA_REQUEST = "POST_RTM_ADDICTION_MEDICINE_DATA_REQUEST"
export const POST_RTM_ADDICTION_MEDICINE_DATA_SUCCESS = "POST_RTM_ADDICTION_MEDICINE_DATA_SUCCESS"
export const POST_RTM_ADDICTION_MEDICINE_DATA_FAIL = "POST_RTM_ADDICTION_MEDICINE_DATA_FAIL"

export const GET_RTM_ADDICTION_MEDICINE_READING_REQUEST =
  "GET_RTM_ADDICTION_MEDICINE_READING_REQUEST"
export const GET_RTM_ADDICTION_MEDICINE_READING_SUCCESS =
  "GET_RTM_ADDICTION_MEDICINE_READING_SUCCESS"
export const GET_RTM_ADDICTION_MEDICINE_READING_FAIL = "GET_RTM_ADDICTION_MEDICINE_READING_FAIL"

export const GET_RTM_MENTAL_HEALTH_DATA_REQUEST = "GET_RTM_MENTAL_HEALTH_DATA_REQUEST"
export const GET_RTM_MENTAL_HEALTH_DATA_SUCCESS = "GET_RTM_MENTAL_HEALTH_DATA_SUCCESS"
export const GET_RTM_MENTAL_HEALTH_DATA_FAIL = "GET_RTM_MENTAL_HEALTH_DATA_FAIL"

export const POST_RTM_MENTAL_HEALTH_DATA_REQUEST = "POST_RTM_MENTAL_HEALTH_DATA_REQUEST"
export const POST_RTM_MENTAL_HEALTH_DATA_SUCCESS = "POST_RTM_MENTAL_HEALTH_DATA_SUCCESS"
export const POST_RTM_MENTAL_HEALTH_DATA_FAIL = "POST_RTM_MENTAL_HEALTH_DATA_FAIL"

export const GET_RTM_MENTAL_HEALTH_READING_REQUEST = "GET_RTM_MENTAL_HEALTH_READING_REQUEST"
export const GET_RTM_MENTAL_HEALTH_READING_SUCCESS = "GET_RTM_MENTAL_HEALTH_READING_SUCCESS"
export const GET_RTM_MENTAL_HEALTH_READING_FAIL = "GET_RTM_MENTAL_HEALTH_READING_FAIL"
