export const getThemeReducer = (
  state = {
    isDark: false
    // isDark: JSON.parse(localStorage.getItem("isDark")) || false
  },
  action
) => {
  switch (action.type) {
    case "DARK_MODE_ACTION":
      return { isDark: action.payload }
    default:
      return state
  }
}
