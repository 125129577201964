import { axiosGet, axiosPost } from "../axios"
import {
  PROVIDER_ADD_FAIL,
  PROVIDER_ADD_REQUEST,
  PROVIDER_ADD_SUCCESS,
  PROVIDER_KPI_FAIL,
  PROVIDER_KPI_REQUEST,
  PROVIDER_KPI_SUCCESS,
  PROVIDER_LIST_FAIL,
  PROVIDER_LIST_REQUEST,
  PROVIDER_LIST_SUCCESS,
  PROVIDER_NPI_FAIL,
  PROVIDER_NPI_REQUEST,
  PROVIDER_NPI_SUCCESS,
  PROVIDER_PATIENT_LIST_FAIL,
  PROVIDER_PATIENT_LIST_REQUEST,
  PROVIDER_PATIENT_LIST_SUCCESS,
  SINGLE_PROVIDER_FAIL,
  SINGLE_PROVIDER_REQUEST,
  SINGLE_PROVIDER_SUCCESS
} from "../types/providerConstant"
import { userLogoutAction } from "./userActions"

export const getProviderList = (orgId) => async dispatch => {
  try {
    dispatch({
      type: PROVIDER_LIST_REQUEST
    })
    if(!orgId)  return
    const { data } = await axiosGet(`/api/cs/provider/orgId/${orgId}/profiles`)

    dispatch({
      type: PROVIDER_LIST_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PROVIDER_LIST_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getProviderKPI = orgId => async dispatch => {
  try {
    dispatch({
      type: PROVIDER_KPI_REQUEST
    })

    const { data } = await axiosGet(`v1/getProviderListKPIDB?orgId=${orgId}`)

    dispatch({
      type: PROVIDER_KPI_SUCCESS,
      payload: data
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PROVIDER_KPI_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getProviderPatientList = providerId => async dispatch => {
  try {
    dispatch({
      type: PROVIDER_PATIENT_LIST_REQUEST
    })

    const { data } = await axiosGet(
      `/admin/v1/getUsersForProvider?providerId=${providerId.providerId}`
    )

    dispatch({
      type: PROVIDER_PATIENT_LIST_SUCCESS,
      payload: data.result
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PROVIDER_PATIENT_LIST_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const providerNPIAction = providerNPI => async dispatch => {
  try {
    dispatch({ type: PROVIDER_NPI_REQUEST })

    const { data } = await axiosGet(`support/v1/searchNPI?npi=${providerNPI}`)
    dispatch({ type: PROVIDER_NPI_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PROVIDER_NPI_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
    error?.response?.status === 401 && dispatch(userLogoutAction())
  }
}

export const getSingleProviderAction = provider => async dispatch => {
  try {
    dispatch({ type: SINGLE_PROVIDER_REQUEST })

    // const {data} = await axiosGet(`v1/getDoctorProfileForUser?doctorId=${providerId}`);
    dispatch({ type: SINGLE_PROVIDER_SUCCESS, payload: provider })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: SINGLE_PROVIDER_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const addProviderAction =
  ({
    email,
    phoneNumber,
    userName,
    firstName,
    middleName,
    lastName,
    orgId,
    gender,
    npi,
    specality,
    taxonomyData,
    image,
    countryCode,
    addressDetails,
    primaryAddress,
    mailingAddress
  }) =>
  async dispatch => {
    try {
      dispatch({
        type: PROVIDER_ADD_REQUEST
      })

      const formData = {
        email,
        phoneNumber,
        firstName,
        userName,
        middleName,
        lastName,
        image,
        orgId,
        gender,
        npiNumber: npi.toString(),
        specality,
        taxonomyData,
        primaryAddress,
        mailingAddress,
        countryCode,
        addressDetails
      }

      const { data } = await axiosPost(`/admin/v1/registerProvider`, formData, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      dispatch({
        type: PROVIDER_ADD_SUCCESS,
        payload: data
      })
      return data
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PROVIDER_ADD_FAIL,
        payload:
          error.response && error.response.data.detail ? error.response.data.detail : error.message
      })
    }
  }

// to add practitioner
export const addPractitionerAction =
  ({
    email,
    phoneNumber,
    userName,
    firstName,
    middleName,
    lastName,
    orgId,
    gender,
    npi,
    specality,
    taxonomyData,
    image,
    countryCode,
    addressDetails,
    primaryAddress,
    mailingAddress,
    providers
  }) =>
  async dispatch => {
    try {
      dispatch({
        type: PROVIDER_ADD_REQUEST
      })

      const formData = {
        email,
        phoneNumber,
        firstName,
        userName,
        middleName,
        lastName,
        image,
        orgId,
        gender,
        npiNumber: npi.toString(),
        specality,
        taxonomyData,
        primaryAddress,
        mailingAddress,
        countryCode,
        addressDetails,
        providers
      }

      const { data } = await axiosPost(`/admin/v1/registerPractitioner`, formData, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      dispatch({
        type: PROVIDER_ADD_SUCCESS,
        payload: data
      })
      return data
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PROVIDER_ADD_FAIL,
        payload:
          error.response && error.response.data.detail ? error.response.data.detail : error.message
      })
    }
  }

// get provider list for practitioners
// export const getProviderListForPractitioners = () => async (dispatch) => {
//     try {
//         dispatch({
//             type: PROVIDER_LIST_REQUEST,
//         });

//         const {data} = await axiosGet(`/providers/v1/getProviderList`);

//         dispatch({
//             type: PROVIDER_LIST_SUCCESS,
//             payload: data?.result,
//         });
//     } catch (error) {
//         error?.response?.status === 401 && dispatch(userLogoutAction())
//         dispatch({
//             type: PROVIDER_LIST_FAIL,
//             payload: error.response && error?.response?.data?.detail,
//         });
//     }
// };
