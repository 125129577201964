import React from "react"

import PatientDetailInfo from "./PatientName"

import "./patient.css"

const PatientDetail = () => {
  return (
    <div className="patient-detail">
      <PatientDetailInfo />
    </div>
  )
}

export default PatientDetail
