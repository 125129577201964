import React from "react"
import { Modal, Button } from "antd"
import TriageFormInput from "../TriageFormInput"
import { useNavigate, useParams } from "react-router-dom"

const LastQAYesNoModal = props => {
  const { showModal, setShowModal, ques, handleQues, confirmPopup, patientInfoDetail } = props
  const navigate = useNavigate()
  const {orgId, orgName} = useParams()

  return (
    <Modal
      open={showModal}
      onCancel={() => {
        // setShowModal(false);
        navigate(`/${orgId}/${orgName}/critical-patient-readings/${patientInfoDetail?.id}`, {
          state: {
            name: `${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName}`
          }
        })
      }}
      footer={null}
      title={<h4 className="text-xl">{ques.name}</h4>}>
      <div className="pt-4">
        <TriageFormInput ques={ques} handleBool={handleQues} />

        <Button type="primary" className="mt-4" onClick={confirmPopup}>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default LastQAYesNoModal
