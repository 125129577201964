import { useEffect } from "react"

import { Card, Row, Col, Avatar } from "antd"

import { useDispatch, useSelector } from "react-redux"

import defaultPractitioner from "../../assets/doctor.png"
import PractitionerAddress from "./PractitionerAddress"
import { getPractitionerAddressAction } from "../../redux/actions/practitionerAction"
import { useLocation } from "react-router-dom"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import moment from "moment"

const PractitionerDetailHeader = ({ practitionerId }) => {
  const dispatch = useDispatch()
  const speciality = useLocation()?.state?.speciality // this is coming from its parent (PractitionerList)

  const { singlePractitioner } = useSelector(state => state.singlePractitioner)

  const profilePicture = singlePractitioner?.profileImage
    ? `data:image/png;base64,${singlePractitioner?.profileImage}`
    : defaultPractitioner

  useEffect(() => {
    if (singlePractitioner) {
      dispatch(getPractitionerAddressAction(practitionerId))
    }
  }, [practitionerId])

  return (
    <>
      <Row gutter={[16, 16]} className="mb-4">
        <Col lg={16} md={24} xs={24}>
          <Card className="shadow-sm h-full" loading={singlePractitioner === undefined}>
            <div className="flex gap-3 flex-wrap justify-between">
              <h4 className="text-2xl">Practitioner Details</h4>
            </div>
            <div className="mt-4 flex gap-16 flex-wrap">
              <div className="flex gap-3">
                <Avatar className="border-2 border-current p-2" size={60} src={profilePicture} />
                <div>
                  <h5 className="mb-1 text-xl capitalize">
                    {singlePractitioner?.firstName?.[0]?.toUpperCase() +
                      singlePractitioner?.firstName.slice(1)}{" "}
                    {singlePractitioner?.middleName} {singlePractitioner?.lastName}
                  </h5>
                  <p className="text-sm text-secondary">
                    {singlePractitioner?.email || "No Email isused for this patient."}
                  </p>
                </div>
              </div>
              <div>
                {singlePractitioner?.userName ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[100px]">User Name : </span>
                    {singlePractitioner?.userName}
                  </div>
                ) : null}
                {singlePractitioner?.gender ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[100px] mr-1">
                      Gender at the time of birth :{" "}
                    </span>
                    {singlePractitioner?.gender}
                  </div>
                ) : null}
              </div>
              <div>
                {singlePractitioner?.gender ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[120px]">DOB : </span>
                    {singlePractitioner?.dob ? moment(singlePractitioner?.dob)?.format("MM-DD-YYYY") : null}
                  </div>
                ) : null}
                {singlePractitioner?.mobileNumber ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[120px]">Mobile Number : </span>
                    {formatPhoneNumber(singlePractitioner?.mobileNumber)}
                  </div>
                ) : null}
                {speciality ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[120px]">Specializations :</span>
                    {speciality?.split("~")?.map((item, index) => (
                      <div className="mr-3" key={index}>
                        <span>{index + 1}. </span>
                        <span>{item}</span>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={8} md={24} xs={24}>
          <PractitionerAddress />
        </Col>
      </Row>
    </>
  )
}

export default PractitionerDetailHeader
