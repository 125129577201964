import { axiosBasicPost, axiosGet, axiosPost } from "../axios"
import {
  EMAIL_VERIFICATION_FAIL,
  EMAIL_VERIFICATION_REQUEST,
  EMAIL_VERIFICATION_SUCCESS,
  FORGET_PASSWORD_CHANGE_FAIL,
  FORGET_PASSWORD_CHANGE_REQUEST,
  FORGET_PASSWORD_CHANGE_SUCCESS,
  FORGET_PASSWORD_OPT_FAIL,
  FORGET_PASSWORD_OPT_REQUEST,
  FORGET_PASSWORD_OPT_SUCCESS,
  UPDATE_EMAIL_FAIL,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_NUMBER_FAIL,
  UPDATE_NUMBER_REQUEST,
  UPDATE_NUMBER_SUCCESS,
  HIDE_PUSH_NOTIFICATION,
  SHOW_PUSH_NOTIFICATION,
  SET_TRIAGE_SESSION,
  SET_CLEAR_SHORT_SESSION,
  SET_FILTER,
  CLEAR_FILTER
} from "../types/utilConstant"
import { userLogoutAction } from "./userActions"
import { USER_LOGIN_FAIL, USER_LOGIN_REQUEST } from "../types/userConstant"
import qs from "qs"
import { loginTypeChecker } from "../../utils/loinTypeChecker"
import { deviceInfo } from "../../utils/fixedInfo"


export const emailVerificationAction = email => async dispatch => {
  try {
    dispatch({
      type: EMAIL_VERIFICATION_REQUEST
    })

    const { data } = await axiosGet(`v1/validateEmail?email=${email}`)

    dispatch({
      type: EMAIL_VERIFICATION_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: EMAIL_VERIFICATION_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error.response
  }
}

export const forgetPasswordProviderAction = userName => async dispatch => {
  try {
    dispatch({
      type: FORGET_PASSWORD_OPT_REQUEST
    })

    let loginTypes = loginTypeChecker(userName)
    let types = deviceInfo()
    const { data } = await axiosBasicPost(`/api/auth/forgotPassword`, {
      userName,
     loginTypes,
     deviceId: types.deviceId,
     deviceModel: types.deviceModel,
     appVersion: types.appVersion
     })

    dispatch({
      type: FORGET_PASSWORD_OPT_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    dispatch({
      type: FORGET_PASSWORD_OPT_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error.response && error?.response?.data?.details
  }
}

export const forgetPasswordLoginAction = (userName, otp) => async dispatch => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST
    })

    let loginTypes = loginTypeChecker(userName)
    const { data } = await axiosBasicPost(
      `/api/auth/validateOTP`,
      {
        userName,
        loginTypes,
        otp
      })

    return data
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return  error.response && error?.response?.data?.details
  }
}

export const forgetPasswordChangeAction = (otp, email, password) => async dispatch => {
  try {
    dispatch({
      type: FORGET_PASSWORD_CHANGE_REQUEST
    })

    const { data } = await axiosPost(`v1/resetForgotPasswordProvider`, {
      otp,
      email,
      password
    })

    dispatch({
      type: FORGET_PASSWORD_CHANGE_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: FORGET_PASSWORD_CHANGE_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const updateEmailAction = (type, userId, email) => async dispatch => {
  try {
    dispatch({ type: UPDATE_EMAIL_REQUEST })
    const { data } = await axiosPost(`/admin/v1/postUpdateUserEmail`, { userId, email })
    dispatch({ type: UPDATE_EMAIL_SUCCESS, payload: data.success })
    if (type === "provider") {
      return (document.location.href = "/provider")
    }
    if (type === "caretaker") {
      return (document.location.href = "/caretaker")
    }
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_EMAIL_FAIL,
      payload: error.response && error.response.data.detail && error.response.data.detail
    })
    return error.response.data.detail
  }
}

export const updateLandlineAction =
  (userId, landlineCode, landNumber, extension) => async dispatch => {
    try {
      dispatch({ type: UPDATE_NUMBER_REQUEST })
      const { data } = await axiosPost(`/admin/v1/postUpdateUserLandnumber`, {
        userId,
        landlineCode,
        landNumber,
        extension
      })
      dispatch({ type: UPDATE_NUMBER_SUCCESS, payload: data.success })
      return data
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: UPDATE_NUMBER_FAIL,
        payload: error.response && error.response.data.detail && error.response.data.detail
      })
      return error.response.data.detail
    }
  }

export const updateNumberAction = (userId, countryCode, phoneNumber) => async dispatch => {
  try {
    dispatch({ type: UPDATE_NUMBER_REQUEST })
    const { data } = await axiosPost(`/admin/v1/postUpdateUserPhonenumber`, {
      userId,
      countryCode,
      phoneNumber
    })
    dispatch({ type: UPDATE_NUMBER_SUCCESS, payload: data.success })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_NUMBER_FAIL,
      payload: error.response && error.response.data.detail && error.response.data.detail
    })
    return error.response.data.detail
  }
}

export const pushNotificationAction = val => dispatch => {
  if (val === true) {
    dispatch({ type: SHOW_PUSH_NOTIFICATION })
  } else {
    dispatch({ type: HIDE_PUSH_NOTIFICATION })
  }
}

export const pushSessionTriageDataAction = val => dispatch => {
  sessionStorage.setItem("triageTimer", JSON.stringify(val))
  dispatch({ type: SET_TRIAGE_SESSION, payload: val })
}
export const pushShortClearAction = val => dispatch => {
  sessionStorage.setItem("shortClearTimer", JSON.stringify(val))
  dispatch({ type: SET_CLEAR_SHORT_SESSION, payload: val })
}

export const setFilterModalValuesAction = val => dispatch => {
  dispatch({ type: SET_FILTER, payload: val })
}
export const clearFilterModalValuesAction = () => dispatch => {
  dispatch({ type: CLEAR_FILTER })
}
