import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import moment from "moment"
import { Modal, List, DatePicker, Button } from "antd"

import { useDispatch, useSelector } from "react-redux"
import {
  getManualTimeAction,
  deleteManualTimeAction,
  saveSelectedDateRange
} from "../../../redux/actions/patientAction"

import { BiTrash, BiEdit } from "react-icons/bi"

import Loading from "../../layouts/Loading/Loading"
import MyDatePicker from "../../../utils/MyDatePicker"
import { USA_DATE_FORMAT } from "../../../utils/usaDateFormat"

const currentDate = moment(new Date()).format("YYYY-MM-DD")
const { RangePicker } = MyDatePicker

const ManualTimeList = props => {
  const {
    setSelectedLog,
    manualListApiCalls,
    showAddManualTimeList,
    setShowUpdateManualTime,
    handleAddManualListClose
  } = props

   const patientId = useParams()
  const dispatch = useDispatch()
  const selectedDateRange = useSelector(state => state.selectedDateRange)
  const [range, setRange] = useState(
    selectedDateRange?.ManualListRange
      ? [selectedDateRange?.ManualListRange?.startDate, selectedDateRange?.ManualListRange?.endDate]
      : [moment(new Date()).subtract(1, "month").startOf("day"), moment(new Date()).endOf("day")]
  )

  useEffect(() => {
    if (manualListApiCalls === true) {
      dispatch(
        getManualTimeAction(
          patientId.id,
          moment(range[0]).format("YYYY-MM-DDTHH:mm:ss"),
          moment(range[1]).format("YYYY-MM-DDTHH:mm:ss")
          // moment(currentDate).subtract(1, "month").startOf("day"),
          // moment(currentDate).add(1, "day").endOf("day")
        )
      )
    }
  }, [showAddManualTimeList, patientId.id])

  const { loading, manualTimeList } = useSelector(state => state.manualList)
  const userInfo = useSelector(state => state.userInfo)

  const handleRangeChange = async (date, dateString) => {
    if (dateString?.[0]?.length && dateString?.[1]?.length)
      setRange([moment(dateString[0]).startOf("day"), moment(dateString[1]).endOf("day")])
  }

  // const handleRangeChange = (date, dateString) => {
  //   setRange(date)
  //   if (date) {
  //     dispatch(
  //       getManualTimeAction(
  //         patientId.id,
  //         moment.utc(dateString[0]).startOf("day").toDate().toISOString(),
  //         moment.utc(dateString[1]).endOf("day").toDate().toISOString()
  //       )
  //     )
  //   }
  // }

  const handleDelete = logId => {
    dispatch(deleteManualTimeAction(patientId.id, logId)).then(res => {
      if (res?.message?.toLowerCase() === "success") {
        dispatch(
          getManualTimeAction(
            patientId.id,
            moment(range[0]).format("YYYY-MM-DDTHH:mm:ss"),
            moment(range[1]).format("YYYY-MM-DDTHH:mm:ss")
            // range
            //   ? moment.utc(new Date(range[0]).toLocaleDateString("en-US")).startOf("day").toDate().toISOString()
            //   : moment(currentDate).subtract(1, "month").format("YYYY-MM-DD"),
            // range
            //   ? moment.utc(new Date(range[1]).toLocaleDateString("en-US")).endOf("day").toDate().toISOString()
            //   : moment(currentDate).add(1, "day").format("YYYY-MM-DD")
          )
        )
      }
    })
  }

  const handleClose = () => {
    setSelectedLog(null)

    // setRange(null)
    handleAddManualListClose()
    setShowUpdateManualTime(false)
  }

  // useEffect(() => {
  //   if (range === null && showAddManualTimeList) {
  //     dispatch(
  //       getManualTimeAction(
  //         patientId.id,
  //         moment(currentDate).subtract(1, "month").format("YYYY-MM-DD"),
  //         moment(currentDate).add(1, "day").format("YYYY-MM-DD")
  //       )
  //     )
  //   }
  // }, [range])

  const handleDateSelection = async () => {
    let res
    if (range && range?.length === 2) {
      dispatch(saveSelectedDateRange("ManualListRange", range?.[0], range?.[1]))
      dispatch(
        getManualTimeAction(
          patientId.id,
          moment(range[0]).format("YYYY-MM-DDTHH:mm:ss"),
          moment(range[1]).format("YYYY-MM-DDTHH:mm:ss")
        )
      )
    } else {
      dispatch(
        getManualTimeAction(
          patientId.id,
          moment.utc().subtract(1, "month").startOf("day").toDate().toISOString(),
          moment.utc().endOf("day").toDate().toISOString()
        )
      )
    }
  }
  // Function to disable dates outside one month range
  const disabledDate = current => {
    if (!range || range.length === 0) {
      // If no range is selected yet, allow all dates
      return false
    }
    const tooLate = range[0] && current.diff(range[0], "days") > 32
    return !!tooLate || current > moment()
  }
  const handleDateReset = async () => {
    setRange([moment(new Date()).subtract(1, "month"), moment(new Date())])
    dispatch(
      saveSelectedDateRange(
        "ManualListRange",
        moment(new Date()).subtract(1, "month"),
        moment(new Date())
      )
    )
    dispatch(
      getManualTimeAction(
        patientId.id,
        moment.utc().subtract(1, "month").startOf("day").toDate().toISOString(),
        moment.utc().endOf("day").toDate().toISOString()
      )
    )
  }
  const handleUpdateClick = row => {
    setSelectedLog(row)
    setShowUpdateManualTime(true)
    setTimeout(() => handleAddManualListClose(), 100)
  }
  const sortedManulList = ([...manualTimeList ?? []]).sort((a, b) => new Date(b.careDate) - new Date(a.careDate)) 

  return (
    <Modal
      footer={null}
      open={showAddManualTimeList}
      onCancel={handleClose}
      title={<h4 className="text-xl">Manual Time List</h4>}>
      <div className="pt-4">
      <RangePicker
          className="mb-4"
          format={USA_DATE_FORMAT}
          value={range}
          onChange={handleRangeChange}
          allowClear={false}
          disabledDate={disabledDate}
        />

        <Button type="primary" onClick={handleDateSelection} disabled={loading} className="ml-2">
          Confirm
        </Button>
        <Button
          type="default"
          onClick={handleDateReset}
          disabled={loading}
          className="ml-2 text-danger border-danger">
          Reset
        </Button>
        {loading ? (
          <Loading />
        ) : manualTimeList !== undefined && manualTimeList?.length ? (
          <>
            <List
              dataSource={sortedManulList}
              renderItem={(values, index) => {
                const isLastItem = manualTimeList.length - 1 === index
                return (
                  <div className={`mb-3 ${!isLastItem ? "border-b-2 border-current pb-3" : ""}`}>
                    <div className="flex items-center gap-2">
                      <span className="min-w-[80px]">Care Type: </span>
                      <span className="font-medium uppercase">
                        {values?.care?.map(care => care).join(", ")}
                      </span>
                    </div>
                    <div className="flex items-center gap-2 mt-1">
                      <span className="min-w-[80px]">Program : </span>
                      <span className="font-medium uppercase">
                        {values?.program?.map(program => program).join(", ")}
                      </span>
                    </div>
                    <div className="flex items-center gap-2 mt-1">
                      <span className="min-w-[80px]">Care Date : </span>
                      <span className="font-medium ">{moment(values?.careDate).format("MMM DD, YYYY HH:mm:ss")}</span>
                    </div>
                    <div className="flex items-center gap-2 mt-1">
                      <span className="min-w-[80px]">Time : </span>
                      <span className="font-medium">{values?.careTime} (in Minutes)</span>
                    </div>
                    {values?.note !== "" && (
                      <div className="flex items-center gap-2 mt-1">
                        <span className="min-w-[80px]">Note : </span>
                        <span className="font-medium">{values?.note}</span>
                      </div>
                    )}
                    {userInfo?.userInfo?.grantType === "caretaker_all" ||
                    userInfo?.userInfo?.grantType === "caretaker_manual_log" ? (
                      <div className="flex items-center gap-2 mt-3">
                        <Button
                          size="small"
                          icon={<BiEdit />}
                          onClick={() => handleUpdateClick(values)}
                        />
                        <Button
                          danger
                          size="small"
                          icon={<BiTrash />}
                          onClick={() => handleDelete(values.id)}
                        />
                      </div>
                    ) : null}
                  </div>
                )
              }}
            />
          </>
        ) : (
          <h3 className="text-xl">No manual entries for patient.</h3>
        )}
      </div>
    </Modal>
  )
}

export default ManualTimeList
