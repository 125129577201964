import axios from "axios"
import { axiosGet, axiosPost } from "../axios"
import {
  ADD_CARETAKER_FAIL,
  ADD_CARETAKER_REQUEST,
  ADD_CARETAKER_SUCCESS,
  CARETAKER_LIST_FAIL,
  CARETAKER_LIST_SUCCESS,
  CARETAKER_PATIENT_FAIL,
  CARETAKER_PATIENT_REQUEST,
  CARETAKER_PATIENT_SUCCESS,
  SINGLE_CARETAKER_FAIL,
  SINGLE_CARETAKER_REQUEST,
  SINGLE_CARETAKER_SUCCESS,
  UPDATE_CARETAKER_FAIL,
  UPDATE_CARETAKER_REQUEST,
  UPDATE_CARETAKER_SUCCESS,
  GET_CARE_TYPES_FAIL,
  GET_CARE_TYPES_REQUEST,
  GET_CARE_TYPES_SUCCESS,
  UPDATE_CARETAKER_MANUAL_TIME_REQUEST,
  UPDATE_CARETAKER_MANUAL_TIME_SUCCESS,
  UPDATE_CARETAKER_MANUAL_TIME_FAIL,
  GET_ORG_ADMIN_REQUEST,
  GET_ORG_ADMIN_SUCCESS,
  GET_ORG_ADMIN_FAIL,
  CARETAKER_CARD_FAIL,
  CARETAKER_CARD_REQUEST,
  CARETAKER_CARD_SUCCESS,
  CARETAKER_LIST_REQUEST,
  GET_CARETAKER_ORG_DETAILS_REQUEST,
  GET_CARETAKER_ORG_DETAILS_SUCCESS,
  GET_CARETAKER_ORG_DETAILS_FAIL
} from "../types/caretakerConstant"

import { userLogoutAction } from "./userActions"

export const getCaretakerCardAction = () => async dispatch => {
  try {
    dispatch({
      type: CARETAKER_CARD_REQUEST
    })

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    const { data } = await axios(`https://jsonplaceholder.typicode.com/users`, config)

    dispatch({
      type: CARETAKER_CARD_SUCCESS,
      payload: data
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: CARETAKER_CARD_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getCaretakerListAction = () => async dispatch => {
  try {
    dispatch({
      type: CARETAKER_LIST_REQUEST
    })

    const { data } = await axiosGet(`admin/v1/getCaretakersForOrg`)

    dispatch({
      type: CARETAKER_LIST_SUCCESS,
      payload: data?.result
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: CARETAKER_LIST_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const postCaretakerAction =
  ({
    firstName,
    middleName,
    lastName,
    userName,
    gender,
    email,
    countryCode,
    phoneNumber,
    addressDetails,
    image,
    orgId,
    grant
  }) =>
  async dispatch => {
    try {
      dispatch({
        type: ADD_CARETAKER_REQUEST
      })

      const { data } = await axiosPost(
        `/admin/v1/registerCaretaker`,
        {
          firstName,
          middleName,
          lastName,
          userName,
          gender,
          email,
          countryCode: "+1",
          phoneNumber,
          addressDetails,
          image,
          orgId,
          grant
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )

      dispatch({
        type: ADD_CARETAKER_SUCCESS,
        payload: data
      })
      return data
    } catch (error) {
      console.log("error?.response", error?.response)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: ADD_CARETAKER_FAIL,
        payload: error.response && error.response.data.detail
      })
      return error.response.data.detail
    }
  }

export const updateCaretakerAction = (userId, newEmail, oldEmail) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_CARETAKER_REQUEST
    })

    const { data } = await axiosPost(`/admin/v1/postUpdateUserEmail`, {
      userId,
      email: newEmail
    })

    dispatch({
      type: UPDATE_CARETAKER_SUCCESS,
      payload: data
    })
    dispatch(singleCaretakerAction(userId))
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_CARETAKER_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
    return error.response
  }
}

export const singleCaretakerAction = careTaker => async dispatch => {
  try {
    dispatch({ type: SINGLE_CARETAKER_REQUEST })
    // const { data } = await axiosGet(`v1/getCaretakerProfile?caretakerId=${careTakerId}`);
    dispatch({ type: SINGLE_CARETAKER_SUCCESS, payload: careTaker })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: SINGLE_CARETAKER_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const caretakerPatientAction = careTakerId => async dispatch => {
  try {
    dispatch({ type: CARETAKER_PATIENT_REQUEST })
    const { data } = await axiosGet(`/api/cs/patients/caretaker/${careTakerId}/fetchAll`)

    dispatch({ type: CARETAKER_PATIENT_SUCCESS, payload: data?.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: CARETAKER_PATIENT_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getCareTypesAction = patientId => async dispatch => {
  try {
    dispatch({
      type: GET_CARE_TYPES_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/patients/caretypes/${patientId}/fetch`)
    dispatch({
      type: GET_CARE_TYPES_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_CARE_TYPES_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const updateCaretakerManualTimeAction = (userId, isManualLog) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_CARETAKER_MANUAL_TIME_REQUEST
    })

    const { data } = await axiosPost(`/admin/v1/updateManualLogGrant`, {
      userId,
      isManualLog
    })

    dispatch({
      type: UPDATE_CARETAKER_MANUAL_TIME_SUCCESS,
      payload: data
    })
    dispatch(singleCaretakerAction(userId))
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_CARETAKER_MANUAL_TIME_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
    return error.response
  }
}

export const getOrgAdmins = () => async dispatch => {
  try {
    dispatch({
      type: GET_ORG_ADMIN_REQUEST
    })
    const { data } = await axiosGet(`/caretaker/v1/getAdminsForOrg`)
    dispatch({
      type: GET_ORG_ADMIN_SUCCESS,
      payload: data?.result
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_ORG_ADMIN_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getCaretakerOrgDetails = () => async dispatch => {
  try {
    dispatch({
      type: GET_CARETAKER_ORG_DETAILS_REQUEST
    })
    const userInfo = localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
    if (userInfo) {
    const { data } = await axiosGet(`/api/cs/org/${userInfo.orgId}/fetch`)

    dispatch({
      type: GET_CARETAKER_ORG_DETAILS_SUCCESS,
      payload: data?.details
    })
    return data 
  }
} catch (error) {
  error?.response?.status === 401 && dispatch(userLogoutAction())
  dispatch({
    type: GET_CARETAKER_ORG_DETAILS_FAIL,
    payload: error?.response && error?.response?.data?.error
  })
}
}
