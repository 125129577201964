import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "antd"
import { getrpmCareplanDataActions } from "../../../../redux/actions/rpmAction"
import Loading from "../../../layouts/Loading/Loading"
import PlanView from "@fitpeo/fit-plan-viewer"
import "./RPMViewQuestion.css"

function RPMOnlyViewQuestion({ patientInfoDetail, diagnosticData, providers, practitioners }) {
  const [questions, setQuestions] = useState()
  const [addedBy, setAddedBy] = useState({});
  const [monitorTypes, setMonitorTypes] = useState([]);
  const [dateOfService, setDateOfService] = useState('');
  const [latestPlanDate, setLatestPlanDate] = useState('');


  const { loading: rpmLoading, getRpmCarePlan } = useSelector(state => state.rpmPatientCarePlan)
  const { loading: monitoringDataLoading, patientMonitoringData } = useSelector(state => state.patientMonitoringData)

  const { id, orgId, orgName } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const providerName = { providerFirstName: patientMonitoringData?.rpm[0].providerfirstName, providerMiddleName: patientMonitoringData?.rpm[0].providerMiddleName, providerLastName: patientMonitoringData?.rpm[0].providerLastName }

  useEffect(() => {
    if (!rpmLoading) {
      dispatch(getrpmCareplanDataActions(id, new Date().getFullYear()))
    }
  }, [id])

  const getRpmPlan = async (rpmData) => {
    if (rpmData) {
      const addingBy = rpmData[0]?.plan?.addingBy;
      setAddedBy(addingBy)
      const planDate = rpmData?.sort((a, b) => new Date(b.planDate) - new Date(a.planDate))[0]?.planDate.slice(0, 10);
      const serviceDate = rpmData?.sort((a, b) => new Date(a.planDate) - new Date(b.planDate))[0]?.planDate.slice(0, 10);
      setLatestPlanDate(planDate)
      setDateOfService(serviceDate)
      if (location?.state?.planId) {
        setQuestions(Object.values(rpmData?.find(p => p.id === location?.state?.planId)?.plan))
      } else {
        setQuestions(Object.values(rpmData?.sort((a, b) => new Date(b.planDate) - new Date(a.planDate))[0]?.plan))
      }
    }
  }

  useEffect(() => {
    getRpmPlan(getRpmCarePlan)
  }, [getRpmCarePlan])

  useEffect(() => {
    let monitorTypes = []
    patientMonitoringData && Object.keys(patientMonitoringData).map((item) => {
      monitorTypes.push(item)
    })

    setMonitorTypes(monitorTypes);
  }, [patientMonitoringData])

  return monitoringDataLoading ? (
    <Loading />
  ) : (
    <>
      <div className="flex justify-end">
        <Button
          type="primary"
          disabled={rpmLoading}
          onClick={() =>
            navigate(`/${orgId}/${orgName}/rpm-view-treatement-print`, {
              state: {
                list: questions && questions?.filter(data => data?.id),
                patientInfoDetail,
                id,
                latestPlanDate,
                dateOfService,
                title: "View Treatement Plan",
                planIcds: diagnosticData,
                providers: providers,
                practitioners: practitioners,
                monitorTypes: monitorTypes
              }
            })
          }>
          Print
        </Button>
      </div>

      {rpmLoading ? (
        <Loading />
      ) : (
        <PlanView
          type="rpm"
          planIcds={diagnosticData}
          patientInfoDetail={patientInfoDetail}
          plan={questions?.filter(data => data?.id)}
          addedBy={addedBy}
          planDate={latestPlanDate}
          providers={providers}
          practitioners={practitioners}
        />
      )}
    </>
  )
}

export default RPMOnlyViewQuestion
