import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { Card, Row, Col } from "antd"

import { useDispatch, useSelector } from "react-redux"
import {
    getPatientDiagnosticsData,
    getPatientInfo,
    getPatientMonitoringData
} from "../../../redux/actions/patientAction"

import Loading from "../../layouts/Loading/Loading"

import moment from "moment"

import "./PCMAdminFollowUpPatient.css"
import HandleApiFail from "../../layouts/APIFail/ApiFail"
import { getrpmDeviceActions as getMonitorTypes } from "../../../redux/actions/rpmAction"
import { getLatestPcmCareplanActions } from "../../../redux/actions/pcmActions"
// import { getMonitorListTypeAction } from "../../../redux/actions/devicesActions"

const PCMAdminFollowUpQuestions = React.lazy(() => import("./components/PCMAdminFollowUpQuestions"))

const MONITORTYPES = "monitorTypes"

const PCMAdminFollowUpPatient = props => {
    const { id, orgId, orgName } = useParams()
    const dispatch = useDispatch()
    const [addingBy, setAddingBy] = useState({})
    const [pcmMonitorId, setPcmMonitorId] = useState(null)
    const [callRestApis, setCallRestApis] = useState(false)
    const [provider, setProvider] = useState([])
    const [practitioner, setPractitioner] = useState([])

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getPatientInfo({ patientId: id }))
            // dispatch(getMonitorListTypeAction())
            dispatch(getLatestPcmCareplanActions({ patientId: id }))
            setCallRestApis(true)
        }
    }, [id]) //eslint-disable-line

    let monitorTypes = JSON.parse(localStorage.getItem(MONITORTYPES))

    const checkMonitorType = async () => {
        if (!monitorTypes?.length) {
            await dispatch(getMonitorTypes())
            monitorTypes = JSON.parse(localStorage.getItem(MONITORTYPES))
        }
        const data = monitorTypes.find(value => value.name === "pcm")
        setPcmMonitorId(data?.programs[0]?.id)
    }

    useEffect(() => {
        if (id !== undefined)
            checkMonitorType()
    }, [monitorTypes, id])

    const { userInfo: adminInfo } = useSelector(state => state.userInfo)
    const { loading: patientLoading, patientInfoDetail } = useSelector(state => state.patientInfo)
    const { loading: pcmCareplanLoading, latestPcmCareplan } = useSelector(state => state.getLatestPcmCareplan)
    // const { monitorTypeList } = useSelector(state => state.monitorTypeList)
    const { patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)
    const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)

    useEffect(() => {
        if (!adminInfo) return

        setAddingBy({
            id: adminInfo?.id,
            name: adminInfo?.middleName
                ? `${adminInfo?.firstName} ${adminInfo?.middleName} ${adminInfo?.lastName}`
                : `${adminInfo?.firstName} ${adminInfo?.lastName}`,
            userType: "caretaker"
        })
    }, [adminInfo])

    // useEffect(() => {
    //   if (monitorTypeList?.length) {
    //     const data = monitorTypeList.find(value => value.name === "ccm")
    //     setCcmMonitorId(data?.programs?.[0]?.id)
    //   }
    // }, [monitorTypeList])

    useEffect(() => {
        if (latestPcmCareplan && callRestApis) {
            if (!latestPcmCareplan?.plan?.planIcds) dispatch(getPatientDiagnosticsData({ patientId: id }))
            if (!latestPcmCareplan?.plan?.providers) dispatch(getPatientMonitoringData({ patientId: id }))
            setCallRestApis(false)
        }
    }, [latestPcmCareplan])

    useEffect(() => {
        let provider = []
        let practitioner = []

        if (latestPcmCareplan?.plan?.providers) provider = latestPcmCareplan?.plan?.providers
        else if (patientMonitoringData?.ccm?.[0]) {
            provider = patientMonitoringData?.ccm?.[0]?.providerId ? [` ${patientMonitoringData?.ccm?.[0]?.providerfirstName}
          ${patientMonitoringData?.ccm?.[0]?.providerMiddleName && patientMonitoringData?.ccm?.[0]?.providerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.providerMiddleName : ""} 
          ${patientMonitoringData?.ccm?.[0]?.providerLastName}`] : []
        }
        if (latestPcmCareplan?.plan?.practitioners) practitioner = latestPcmCareplan?.plan?.practitioners
        else if (patientMonitoringData?.ccm?.[0]) {
            practitioner = patientMonitoringData?.ccm?.[0]?.practitionerId ? [` ${patientMonitoringData?.ccm?.[0]?.practitionerfirstName}
          ${patientMonitoringData?.ccm?.[0]?.practitionerMiddleName && patientMonitoringData?.ccm?.[0]?.practitionerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.practitionerMiddleName : ""} 
          ${patientMonitoringData?.ccm?.[0]?.practitionerLastName}`] : []
        }
        if (latestPcmCareplan?.plan?.provider) {
            provider = [`${latestPcmCareplan?.plan?.provider?.providerFirstName && latestPcmCareplan?.plan?.provider?.providerFirstName !== "" ? " " + latestPcmCareplan?.plan?.provider?.providerFirstName : ""}
          ${latestPcmCareplan?.plan?.provider?.providerMiddleName && latestPcmCareplan?.plan?.provider?.providerMiddleName !== "" ? " " + latestPcmCareplan?.plan?.provider?.providerMiddleName : ""} 
          ${latestPcmCareplan?.plan?.provider?.providerLastName && latestPcmCareplan?.plan?.provider?.providerLastName !== "" ? " " + latestPcmCareplan?.plan?.provider?.providerLastName : ""}`]
        }

        setProvider(provider)
        setPractitioner(practitioner)
    }, [latestPcmCareplan, patientMonitoringData])

    const planIcds =
        latestPcmCareplan?.plan?.planIcds ?? patientDiagnosticData?.map(({ icdCode }) => icdCode)

    return patientLoading || pcmCareplanLoading ? (
        <Loading />
    ) : patientInfoDetail && latestPcmCareplan && addingBy ? (
        <>
            <Card>
                <h4 className="text-3xl mb-4">Patient Details</h4>
                <Row gutter={16}>
                    <Col xs={24} lg={12}>
                        <div className="mb-3">
                            <span className="text-lg">Date of Service : </span>
                            <span className="text-lg ml-2 font-medium">
                                {latestPcmCareplan?.planDate && moment(latestPcmCareplan.planDate).format("MM/DD/YYYY")}
                            </span>
                        </div>
                        <div className="mb-3">
                            <span className="text-lg">Patient Name : </span>
                            <span className="text-lg ml-2 font-medium capitalize">
                                {`${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName
                                    }`}
                            </span>
                        </div>
                        <div className="mb-3">
                            <span className="text-lg">Patient DOB : </span>
                            <span className="text-lg ml-2 font-medium">
                                {patientInfoDetail?.dob && moment(patientInfoDetail?.dob).format("MM/DD/YYYY")}
                            </span>
                        </div>
                        <div className="mb-3">
                            <p className="text-lg block capitalize">
                                Adding By: {`${addingBy?.name} (${addingBy?.userType})`}
                            </p>
                        </div>
                        {/* <div className="mb-3">
              <span className="text-lg">Date of Plan Modified:</span>
              <span className="text-lg ml-2 font-medium">
                {patientInfoDetail?.careplanStats
                  ? moment.unix(patientInfoDetail?.careplanStats?.updatedAt).format("MM/DD/YYYY")
                  : ""}
              </span>
            </div> */}
                    </Col>
                    <Col xs={24} lg={12}>
                        <div className="mb-3">
                            <p className="text-lg block">Monitor Type : PCM</p>
                        </div>
                        <div className="mb-3">
                            {provider && (provider?.length !== 0) && (
                                <p className="text-lg block capitalize">
                                    Provider :{" "}
                                    {provider?.join(", ")}
                                </p>
                            )}
                        </div>
                        <div className="mb-3">
                            {practitioner && (practitioner?.length !== 0) && (
                                <p className="text-lg block capitalize">
                                    Practitioner :{" "}
                                    {practitioner?.join(", ")}
                                </p>
                            )}
                        </div>
                        <div className="mb-3">
                            <p className="text-lg capitalize">
                                Diagnostic(ICD) Codes: {latestPcmCareplan?.plan?.planIcds ?? planIcds?.join(", ")}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Card>

            <React.Suspense fallback={<Loading />}>
                <PCMAdminFollowUpQuestions
                    patientInfoDetail={patientInfoDetail}
                    carePlanData={latestPcmCareplan}
                    planIcds={planIcds}
                    provider={provider}
                    addingBy={addingBy}
                    pcmMonitorId={pcmMonitorId}
                />
            </React.Suspense>
        </>
    ) : (
        <HandleApiFail route={`/${orgId}/${orgName}/pcm/patients`}/>
    )
}

export default PCMAdminFollowUpPatient
