import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

import { Modal, Button, Radio } from "antd"

import { useSelector } from "react-redux"
import { TbDeviceWatchOff } from "react-icons/tb"

const CriticalDeviceSelctor = props => {
  const { show, setShow, handlePatientCriticalStatus, setInput } = props
  const { patientInfoDetail } = useSelector(store => store.patientInfo)

  const [deviceError, setDeviceError] = useState("")
  const [devices, setDevices] = useState([])
  const [criticalDevices, setCriticalDevides] = useState([]) // eslint-disable-line

  useEffect(() => {
    const rpmDevices =
      patientInfoDetail?.moniteringDetails?.filter(x => x?.moniterData?.name === "rpm") || []
    const temp = []
    if (rpmDevices?.length) {
      rpmDevices[0]?.deviceDetails?.forEach(item => {
        temp.push(item?.deviceData?.name)
      })
      setDevices(temp)
    } else {
      setDeviceError("No RPM Devices Found.")
    }
    const tempTwo = []
    patientInfoDetail?.criticalStatus?.types?.forEach(item => {
      tempTwo.push(item?.criticalType)
    })
    setCriticalDevides(tempTwo)
    // console.log('temp',temp)
  }, [patientInfoDetail])

  const [cD, setCd] = useState("")
  const location = useLocation()

  const patientInfo = location?.state?.patientInfo

  const handleDevice = () => {
    handlePatientCriticalStatus(cD)
    setShow(false)
  }
  const handleClose = () => {
    const proceed =
      patientInfo?.criticalStatus === null ? false : patientInfo?.criticalStatus?.status
    if (proceed === false) {
      setInput(false)
      setShow(false)
    } else {
      setShow(false)
    }
  }

  // console.log('cd', criticalDevices)

  return (
    <Modal
      footer={null}
      open={show}
      onCancel={handleClose}
      title={<h4 className="text-xl">Select Device</h4>}>
      <div className="pt-4">
        <Radio.Group value={cD} onChange={e => setCd(e.target.value)}>
          {devices?.length
            ? devices?.map((item, idx) => (
                <div className="mb-3" key={idx}>
                  <Radio value={item}>
                    {item
                      ?.split(" ")
                      ?.map(word => {
                        return word[0].toUpperCase() + word.substring(1)
                      })
                      .join(" ")}
                  </Radio>
                </div>
              ))
            : null}
          {deviceError && (
            <div className="scale-up-center flex justify-center flex-col items-center">
              <h1 className="text-center">
                <TbDeviceWatchOff />
              </h1>
              <h5 className="text-center text-danger mt-5">{deviceError}</h5>
            </div>
          )}
        </Radio.Group>
        <div className="flex flex-wrap items-center gap-3 mt-4">
          <Button danger onClick={handleClose}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleDevice} disabled={!cD}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CriticalDeviceSelctor
