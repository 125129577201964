import React from "react"
import { Button, Modal, Input } from "antd"

const HypertensiveYesAlertModal = ({
  HypertesniveYesAlertQus,
  showHypertensiveYesAlert,
  setHypertensiveYesAlertQus,
  setShowHypertensiveYesAlert
}) => {
  return (
    <Modal
      footer={null}
      open={showHypertensiveYesAlert}
      onCancel={() => setShowHypertensiveYesAlert(false)}
      title={
        <div>
          <p className="font-medium text-xl">Note</p>
          <p>{HypertesniveYesAlertQus?.name}</p>
        </div>
      }>
      <div className="mb-4">
        <Input.TextArea
          value={HypertesniveYesAlertQus?.text}
          onChange={e =>
            setHypertensiveYesAlertQus({ ...HypertesniveYesAlertQus, text: e.target.value })
          }
        />
      </div>
      <Button type="primary" onClick={() => setShowHypertensiveYesAlert(false)}>
        Save
      </Button>
    </Modal>
  )
}

export default HypertensiveYesAlertModal
