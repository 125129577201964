import React from "react"
import { Button, Modal } from "antd"

const HypotensiveNoAlertModal = ({ showHypotensiveNoAlert, setShowHypotensiveNoAlert }) => {
  return (
    <Modal
      footer={null}
      open={showHypotensiveNoAlert}
      onCancel={() => showHypotensiveNoAlert(false)}
      title={<p className="font-medium text-xl">Note</p>}>
      <div className="mb-4">
        <p>
        The answers to these questions will help you and the patient better understand why they may be experiencing an elevated blood pressure reading. Along the way, educate the patient to make healthy lifestyle changes and incorporate any appropriate nursing interventions/recommendations related to lowering blood pressure. Some lifestyle modifications for high blood pressure include: healthy diet, decrease salt/sodium intake, daily exercise, weight loss, reduce caffeine/alcohol/smoking, take blood pressure medication as prescribed.
        </p>
      </div>

      <Button type="primary" onClick={() => setShowHypotensiveNoAlert(false)}>
        Ok
      </Button>
    </Modal>
  )
}

export default HypotensiveNoAlertModal
