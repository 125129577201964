export const CARETAKER_CARD_REQUEST = "CARETAKER_CARD_REQUEST"
export const CARETAKER_CARD_SUCCESS = "CARETAKER_CARD_SUCCESS"
export const CARETAKER_CARD_FAIL = "CARETAKER_CARD_FAIL"
export const CARETAKER_LIST_REQUEST = "CARETAKER_LIST_REQUEST"
export const CARETAKER_LIST_SUCCESS = "CARETAKER_LIST_SUCCESS"
export const CARETAKER_LIST_FAIL = "CARETAKER_LIST_FAIL"
export const ADD_CARETAKER_REQUEST = "ADD_CARETAKER_REQUEST"
export const ADD_CARETAKER_SUCCESS = "ADD_CARETAKER_SUCCESS"
export const ADD_CARETAKER_FAIL = "ADD_CARETAKER_FAIL"

export const UPDATE_CARETAKER_REQUEST = "UPDATE_CARETAKER_REQUEST"
export const UPDATE_CARETAKER_SUCCESS = "UPDATE_CARETAKER_SUCCESS"
export const UPDATE_CARETAKER_FAIL = "UPDATE_CARETAKER_FAIL"

export const SINGLE_CARETAKER_REQUEST = "SINGLE_CARETAKER_REQUEST"
export const SINGLE_CARETAKER_SUCCESS = "SINGLE_CARETAKER_SUCCESS"
export const SINGLE_CARETAKER_FAIL = "SINGLE_CARETAKER_FAIL"

export const CARETAKER_PATIENT_REQUEST = "CARETAKER_PATIENT_REQUEST"
export const CARETAKER_PATIENT_SUCCESS = "CARETAKER_PATIENT_SUCCESS"
export const CARETAKER_PATIENT_FAIL = "CARETAKER_PATIENT_FAIL"

export const GET_CARE_TYPES_REQUEST = "GET_CARE_TYPES_REQUEST"
export const GET_CARE_TYPES_SUCCESS = "GET_CARE_TYPES_SUCCESS"
export const GET_CARE_TYPES_FAIL = "GET_CARE_TYPES_FAIL"

export const UPDATE_CARETAKER_MANUAL_TIME_REQUEST = "UPDATE_CARETAKER_MANUAL_TIME_REQUEST"
export const UPDATE_CARETAKER_MANUAL_TIME_SUCCESS = "UPDATE_CARETAKER_MANUAL_TIME_SUCCESS"
export const UPDATE_CARETAKER_MANUAL_TIME_FAIL = "UPDATE_CARETAKER_MANUAL_TIME_FAIL"

export const GET_ORG_ADMIN_REQUEST = "GET_ORG_ADMIN_REQUEST"
export const GET_ORG_ADMIN_SUCCESS = "GET_ORG_ADMIN_SUCCESS"
export const GET_ORG_ADMIN_FAIL = "GET_ORG_ADMIN_FAIL"

export const GET_CARETAKER_ORG_DETAILS_REQUEST = "GET_ORG_ADMIN_REQUEST"
export const GET_CARETAKER_ORG_DETAILS_SUCCESS = "GET_ORG_ADMIN_SUCCESS"
export const GET_CARETAKER_ORG_DETAILS_FAIL = "GET_ORG_ADMIN_FAIL"