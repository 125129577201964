import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ReactToPrint from 'react-to-print';
import { Card } from "antd";
import { getPatientCaretaker } from "../../../../redux/actions/patientAction";
import RPMOnlyViewForm from "../../RPMAdminQuestions/RPMOnlyViewForm";

const RPMViewPrintV2 = () => {
  const [patientCaretakerData, setPatientCaretakerData] = useState([]);
  const [providerName, setProviderName] = useState();

  const userInfo = useSelector((state) => state.userInfo);
  const { loading: caretakerLoading, patientCaretaker } = useSelector(state => state.patientCaretaker)

  const componentRef = useRef();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const {orgId, orgName} = useParams()

  const list = location.state && location.state.list;
  const patientInfoDetail = location.state && location.state.patientInfoDetail;
  const id = location.state && location.state.id;
  const latestPlanDate = location.state.latestPlanDate;
  const dateOfService = location.state.dateOfService;
  const patientICDCodes = location.state.planIcds;
  const providers = location.state.providers;
  const practitioners = location.state.practitioners;
  const monitorTypes = location.state.monitorTypes;

  useEffect(() => {
    dispatch(getPatientCaretaker(id))
  }, [id])

  useEffect(() => {
    setPatientCaretakerData(patientCaretaker);
  }, [patientCaretaker])

  useEffect(() => {
    if (!list || list === null) {
      navigate('/')
    }
  }, [location.state]);

  useEffect(() => {
    if (providers && (providers?.length !== 0)) {
      setProviderName(providers[0])
    } else if (practitioners && (practitioners?.length !== 0)) {
      setProviderName(practitioners[0])
    }
  }, [providers, practitioners])

  const generatePageNum = () => {
    //let totalPages = Math.ceil(document.body.scrollHeight / 1123);  //842px A4 pageheight for 72dpi, 1123px A4 pageheight for 96dpi, 
    let totalPages = Math.ceil(Object.keys(list).length / 2) + 1
    for (let i = 1; i <= totalPages; i++) {
      let pageNumberDiv = document.createElement("div");
      let pageNumber = document.createTextNode("Page " + i + " of " + totalPages);
      pageNumberDiv.classList.add('page-num-div')
      pageNumberDiv.style.position = "absolute";
      pageNumberDiv.style.top = "calc((" + i + " * (297mm - 0.5px)) - 40px)"; //297mm A4 pageheight; 0,5px unknown needed necessary correction value; additional wanted 40px margin from bottom(own element height included)
      pageNumberDiv.style.height = "16px";
      pageNumberDiv.appendChild(pageNumber);
      let parentDiv = document.getElementById("view-container");
      let originalDiv = document.getElementById("content");
      parentDiv.insertBefore(pageNumberDiv, originalDiv.nextSibling)
      pageNumberDiv.style.left = "calc(100% - (" + pageNumberDiv.offsetWidth + "px + 100px))";
      pageNumberDiv.style.backgroundColor = "#DBDBDB"
      pageNumberDiv.style.paddingBottom = '24px'
      pageNumberDiv.style.borderRadius = "6px"
    }
  }
  useEffect(() => {
    generatePageNum()
  }, [list])

  const styledPdf = `
  @media print {
    html, body {
      height: 100%;
      margin: 0 !important;
      padding: 0 !important;
      overflow: hidden;
      display: table;
      width: 100%;
    }
    .page-break {
      display: table-row;
      page-break-inside: avoid;
      margin-top: 1rem;
    }
  }`

  return (
    <>
      <div className="view-container">
        {/* <SidebarComponent sidebarOpen={sidebarOpen} /> */}
        <div id="content" className="no-hadow">
          {/* <RPMViewNavbar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} userInfo={userInfo} /> */}
          <div
            className="view-container" id="view-container"
            ref={componentRef}
            style={{
              padding: "20px",
              overflowY: "auto",
            }}>
            <div className="d-flex justify-content-between w-100 py-2">
              <h4 className="Ish px-3">Answers</h4>
              <div className="d-flex noprint">
                <div>
                  <button className="btn btn-sm btn-secondary" onClick={() =>
                    navigate(`/${orgId}/${orgName}/rpm-view-treatement/${id}`, {
                      state: {
                        name: {
                          firstName: patientInfoDetail.firstName,
                          middleName: patientInfoDetail.middleName,
                          lastName: patientInfoDetail.lastName,
                        },
                        // fullName: patientInfoDetail.fullName,
                        dob: patientInfoDetail.dob,
                      }
                    })}>Back</button>
                </div>
                <div>
                  <ReactToPrint
                    pageStyle={styledPdf}
                    trigger={() => <button className="btn btn-primary btn-sm mx-2">Print as PDF</button>}
                    content={() => componentRef.current}
                  />
                </div>
                {/* <div>
                            <button className="btn btn-dark btn-sm mx-2" onClick={exportPdf}>Test Print</button>
                        </div> */}
              </div>
            </div>
            <div className="CCMAddChronic no-hadow">
              <Card>
                <h4 className="text-secondary text-2xl">RPM Treatement Plan</h4>
                <h3 className="text-secondary">Patient Details</h3>
                <div className="d-flex justify-content-between">
                  <div>
                    <p>
                      Date of Service :&nbsp;
                      {dateOfService}
                    </p>
                    <p className="capitalize">
                      Patient Name :&nbsp;{patientInfoDetail?.firstName}&nbsp;
                      {patientInfoDetail?.middleName}&nbsp;
                      {patientInfoDetail?.lastName}
                    </p>
                    <p>
                      Patient DOB :&nbsp;
                      {patientInfoDetail?.dob?.split("-")?.reverse()?.join("-")}
                    </p>
                    <p
                      style={{
                        // fontSize: 12,
                        fontWeight: 500,

                        marginBottom: "10px"
                      }}>
                      <div className="d-flex">
                        Monitor Type :&nbsp;
                        {monitorTypes?.map((item, index) => (
                          <p className="w-auto" key={index}>{item.toUpperCase()},&nbsp;&nbsp;</p>
                        ))}
                      </div>
                    </p>
                    <p>
                      Date of Plan Modified :&nbsp;
                      {latestPlanDate}
                    </p>
                  </div>
                  <div className="mx-5">
                    <p>
                      <div className="d-flex flex-wrap">
                        <p>CareTaker :&nbsp;</p>
                        {patientCaretakerData?.map((item, index) => (
                          <p key={index} className="capitalize">
                            {item.userName},&nbsp;&nbsp;
                          </p>
                        ))}
                      </div>
                    </p>
                    {providers && (providers?.length !== 0) && (
                      <p className="mb-2">
                        <span className="capitalize"> Providers :
                          {" "}{providers?.join(", ")}
                        </span>
                      </p>
                    )}
                    {practitioners && (practitioners?.length !== 0) && (
                      <p className="mb-2">
                        <span className="capitalize"> Practitioner :
                          {" "}{practitioners?.join(", ")}
                        </span>
                      </p>
                    )}
                    <p>
                      <div className="d-flex flex-wrap">
                        Diagnostic (ICD)&nbsp;Codes :&nbsp;
                        {patientICDCodes?.map((item) => (
                          <p>{item} , &nbsp;</p>
                        ))}
                      </div>
                    </p>
                  </div>
                </div>
              </Card>
            </div>

            {/* -------------------description div starts---------------------------- */}
            {!caretakerLoading &&
              list?.[3]?.questionaryOptions[2]?.checked === true ?
              <div className="shadow w-100 p-4 card no-hadow desc-div my-5">
                <h4>Description</h4>
                <p><span className="fw-bold text-capitalize">{patientInfoDetail?.firstName}&nbsp;{patientInfoDetail?.middleName}&nbsp;
                  {patientInfoDetail?.lastName}</span> currently has the following Diagnostic(ICD) Codes : &nbsp;
                  {patientICDCodes?.map((item) => (
                    <span className="font-medium">{item} , &nbsp;</span>
                  ))}
                  <br></br>
                  Dr. <span className="fw-bold text-capitalize">{providerName}</span> identified this patient as a good candidate for enrollment into the Remote Patient Monitoring (RPM) program.<br></br>
                  The healthcare provider has recommended the following devices:<br></br>
                  {/* <span className="fw-bold">{patientInfoDetail?.moniteringDetails?.map((item, i) => (
                    item?.moniterData?.name === 'rpm' ?
                      item?.deviceDetails?.map((dev, idx) => (
                        <div className="text-capitalize" key={idx}>{idx + 1}. {dev?.deviceData?.name}</div>
                      ))
                      :
                      null
                  ))}</span> */}
                  The FitPeo devices will be used to collect health data from the patient and to monitor their treatment plan remotely.<br></br>
                  The patient has been provided informed verbal consent for the RPM program at the time of annual enrollment.<br></br>
                  <span className="fw-bold text-capitalize">{userInfo?.userInfo?.firstName}&nbsp;{userInfo?.userInfo?.lastName}</span> spoke with the patient today via (video call, phone call, virtual call) to initiate and review the treatment plan.<br></br>
                  Equipment was mailed to the patient successfully and received on {list?.[4]?.text !== "" ? <span className="fw-bold">{list[4].text}</span> : "--.--.----"} by the patient.<br></br>
                  The patient has been educated on how to properly utilize the equipment and the importance of monitoring their vital signs daily via the FitPeo equipment provided.<br></br>
                  This education was provided on {list?.[4]?.text !== "" ? <span className="fw-bold">{list[4].text}</span> : "--.--.----"}.<br></br>
                  The patient has agreed to the treatment plan and has been provided with our contact information for any questions/concerns.</p>
              </div>
              : list?.[3]?.questionaryOptions[1]?.checked === true ?
                <div className="shadow w-100 p-4 card no-hadow desc-div my-5">
                  <h4>Description</h4>
                  <p><span className="fw-bold text-capitalize">{patientInfoDetail?.firstName}&nbsp;{patientInfoDetail?.middleName}&nbsp;
                    {patientInfoDetail?.lastName}</span> currently has the following Diagnostic(ICD) Codes : &nbsp;
                    {patientICDCodes?.map((item) => (
                      <span className="font-medium">{item} , &nbsp;</span>
                    ))}
                    <br></br>
                    Dr. <span className="fw-bold text-capitalize">{providerName}</span> identified this patient as a good candidate for enrollment into the Remote Patient Monitoring (RPM) program.<br></br>
                    The healthcare provider has recommended the following devices:<br></br>
                    {/* <span className="fw-bold">{patientInfoDetail?.moniteringDetails?.map((item, i) => (
                      item?.moniterData?.name === 'rpm' ?
                        item?.deviceDetails?.map((dev, idx) => (
                          <div className="text-capitalize">{idx + 1}. {dev?.deviceData?.name}</div>
                        ))
                        :
                        null
                    ))}</span> */}
                    The FitPeo devices will be used to collect health data from the patient and to monitor their treatment plan remotely.<br></br>
                    The patient has been provided informed verbal consent for the RPM program at the time of annual enrollment.<br></br>
                    <span className="fw-bold text-capitalize">{userInfo?.userInfo?.firstName}&nbsp;{userInfo?.userInfo?.lastName}</span> spoke with the patient today to initiate and review the treatment plan.<br></br>
                    Equipment was delivered to the patient successfully and received on {list?.[4]?.text !== "" ? <span className="fw-bold">{list[4].text}</span> : "--.--.----"} by the patient.<br></br>
                    The patient has been educated on how to properly utilize the equipment and the importance of monitoring their vital signs daily via the FitPeo equipment provided.<br></br>
                    This education was provided on {list?.[4]?.text !== "" ? <span className="fw-bold">{list[4].text}</span> : "--.--.----"}.<br></br>
                    The patient has agreed to the treatment plan and has been provided with our contact information for any questions/concerns.</p>
                </div>
                :
                list?.[3]?.questionaryOptions[0]?.checked === true ?
                  <div className="shadow w-100 p-4 card no-hadow desc-div my-5">
                    <h4>Description</h4>
                    <p><span className="fw-bold text-capitalize">{patientInfoDetail?.firstName}&nbsp;{patientInfoDetail?.middleName}&nbsp;
                      {patientInfoDetail?.lastName}</span> currently has the following Diagnostic(ICD) Codes : &nbsp;
                      {patientICDCodes?.map((item) => (
                        <span className="font-medium">{item} , &nbsp;</span>
                      ))}
                      <br></br>
                      Dr. <span className="fw-bold text-capitalize">{providerName}</span> identified this patient as a good candidate for enrollment into the Remote Patient Monitoring (RPM) program.<br></br>
                      The healthcare provider has recommended the following devices:<br></br>
                      {/* <span className="fw-bold">{patientInfoDetail?.moniteringDetails?.map((item, i) => (
                        item?.moniterData?.name === 'rpm' ?
                          item?.deviceDetails?.map((dev, idx) => (
                            <div className="text-capitalize">{idx + 1}. {dev?.deviceData?.name}</div>
                          ))
                          :
                          null
                      ))}</span> */}
                      The FitPeo devices will be used to collect health data from the patient and to monitor their treatment plan remotely.<br></br>
                      The patient has been provided informed verbal consent for the RPM program at the time of annual enrollment.<br></br>
                      <span className="fw-bold text-capitalize">{userInfo?.userInfo?.firstName}&nbsp;{userInfo?.userInfo?.lastName}</span> spoke with the patient today to initiate and review the treatment plan.<br></br>
                      Equipment was given to the patient on {list?.[4]?.text !== "" ? <span className="fw-bold">{list[4].text}</span> : "--.--.----"} in the office by <span className="fw-bold text-capitalize">{userInfo?.userInfo?.firstName}&nbsp;{userInfo?.userInfo?.lastName}</span>.<br></br>
                      The patient has been educated on how to properly utilize the equipment and the importance of monitoring their vital signs daily via the FitPeo equipment provided.<br></br>
                      This education was provided on {list?.[4]?.text !== "" ? <span className="fw-bold">{list[4].text}</span> : "--.--.----"}.<br></br>
                      The patient has agreed to the treatment plan and has been provided with our contact information for any questions/concerns.</p>
                  </div>
                  :
                  null
            }
            {/* --------------------description div ends-------------------- */}
            <div className="list-print-div" style={{ counterIncrement: 'page' }}>
              {Object.values(list)?.map((ques, index) => (
                <div className="card w-100 mb-3 shadow p-4 no-hadow mt-4" key={index}
                  style={{ pageBreakAfter: 'auto', color: 'black' }}
                >
                  <h4 className="form_heading fw-bold">{ques?.name}</h4>
                  <h5 className="subHeading">{ques?.title}</h5>

                  <div className="not_applicable">
                    {ques.notApplicable !== undefined && ques.notApplicable ? (
                      <>
                        <label className="d-flex pt-4 text-dark">
                          <input
                            type="checkbox"
                            checked={ques?.notApplicable ? true : false}
                            disabled
                            style={{
                              marginRight: "18px",
                            }}
                          />
                          Not Applicable
                        </label>
                      </>
                    )
                      :
                      ques.anyValue !== undefined && !ques.anyValue ?
                        <div className="not_applicable">
                          <>
                            <label className="d-flex pt-4 text-dark">
                              <input
                                type="checkbox"
                                checked
                                disabled
                                style={{
                                  marginRight: "18px",
                                }}
                              />
                              Not Applicable
                            </label>
                          </>
                        </div>
                        :
                        <RPMOnlyViewForm
                          ques={ques} />
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RPMViewPrintV2
