import {
    GET_PATIENT_BP_CUSTOM_PARAMETER_REQUEST,
    GET_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
    GET_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
    GET_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST,
    GET_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
    GET_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
    GET_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST,
    GET_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
    GET_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL
} from "../types/customParameterConstant";

export const getPatientBpCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PATIENT_BP_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case GET_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, bpCustomParameters: action.payload }
        case GET_PATIENT_BP_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

// oximeter
export const getPatientOxiCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case GET_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, oxiCustomParameters: action.payload }
        case GET_PATIENT_OXI_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

// GLUCOSE METER
export const getPatientGlucoCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case GET_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, glucoCustomParameters: action.payload }
        case GET_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}