import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { Card, Row, Col } from "antd"

import { useDispatch, useSelector } from "react-redux"
import {
  getPatientDiagnosticsData,
  getPatientInfo,
  getPatientMonitoringData
} from "../../../redux/actions/patientAction"
import { getLatestCareplanActions } from "../../../redux/actions/ccmActions"

import Loading from "../../layouts/Loading/Loading"

import moment from "moment"

import "./CCMAdminfollowUpPatient.css"
import HandleApiFail from "../../layouts/APIFail/ApiFail"
import { getrpmDeviceActions as getMonitorTypes } from "../../../redux/actions/rpmAction"
import { USA_DATE_FORMAT } from "../../../utils/usaDateFormat"
// import { getMonitorListTypeAction } from "../../../redux/actions/devicesActions"

const CCMAdminFollowUpQuestions = React.lazy(() => import("./components/CCMAdminFollowUpQuestions"))

const CCMAdminFollowUpPatient = props => {
  const { id, orgId, orgName } = useParams()
  const dispatch = useDispatch()
  const [addingBy, setAddingBy] = useState({})
  const [ccmMonitorId, setCcmMonitorId] = useState(null)
  const [callRestApis, setCallRestApis] = useState(false)
  const [provider, setProvider] = useState([])
  const [practitioner, setPractitioner] = useState([])

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getPatientInfo({ patientId: id }))
      // dispatch(getMonitorListTypeAction())
      dispatch(getLatestCareplanActions({ patientId: id }))
      setCallRestApis(true)
    }
  }, [id]) //eslint-disable-line

  let monitorTypes = JSON.parse(localStorage.getItem("monitorTypes"))

  const checkMonitorType = async () => {
    if (!monitorTypes?.length) {
      await dispatch(getMonitorTypes())
      monitorTypes = JSON.parse(localStorage.getItem("monitorTypes"))
    }
    const data = monitorTypes.find(value => value.name === "ccm")
    setCcmMonitorId(data?.programs[0]?.id)
  }

  useEffect(() => {
    if (id !== undefined)
      checkMonitorType()
  }, [monitorTypes, id])

  const { userInfo: caretakerInfo } = useSelector(state => state.userInfo)
  const { loading: patientLoading, patientInfoDetail } = useSelector(state => state.patientInfo)
  const { loading: careplanLoading, latestCareplan } = useSelector(state => state.getLatestCareplan)
  // const { monitorTypeList } = useSelector(state => state.monitorTypeList)
  const { patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)

  useEffect(() => {
    if (!caretakerInfo) return

    setAddingBy({
      id: caretakerInfo?.id,
      name: caretakerInfo?.middleName
        ? `${caretakerInfo?.firstName} ${caretakerInfo?.middleName} ${caretakerInfo?.lastName}`
        : `${caretakerInfo?.firstName} ${caretakerInfo?.lastName}`,
      userType: "caretaker"
    })
  }, [caretakerInfo])

  // useEffect(() => {
  //   if (monitorTypeList?.length) {
  //     const data = monitorTypeList.find(value => value.name === "ccm")
  //     setCcmMonitorId(data?.programs?.[0]?.id)
  //   }
  // }, [monitorTypeList])

  useEffect(() => {
    if (latestCareplan && callRestApis) {
      if (!latestCareplan?.plan?.planIcds) dispatch(getPatientDiagnosticsData({ patientId: id }))
      if (!latestCareplan?.plan?.providers) dispatch(getPatientMonitoringData({ patientId: id }))
      setCallRestApis(false)
    }
  }, [latestCareplan])

  useEffect(() => {
    let provider = []
    let practitioner = []

    if (latestCareplan?.plan?.providers) provider = latestCareplan?.plan?.providers
    else if (patientMonitoringData?.ccm?.[0]) {
      provider = patientMonitoringData?.ccm?.[0]?.providerId ? [` ${patientMonitoringData?.ccm?.[0]?.providerfirstName}
      ${patientMonitoringData?.ccm?.[0]?.providerMiddleName && patientMonitoringData?.ccm?.[0]?.providerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.providerMiddleName : ""} 
      ${patientMonitoringData?.ccm?.[0]?.providerLastName}`] : []
    }
    if (latestCareplan?.plan?.practitioners) practitioner = latestCareplan?.plan?.practitioners
    else if (patientMonitoringData?.ccm?.[0]) {
      practitioner = patientMonitoringData?.ccm?.[0]?.practitionerId ? [` ${patientMonitoringData?.ccm?.[0]?.practitionerfirstName}
      ${patientMonitoringData?.ccm?.[0]?.practitionerMiddleName && patientMonitoringData?.ccm?.[0]?.practitionerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.practitionerMiddleName : ""} 
      ${patientMonitoringData?.ccm?.[0]?.practitionerLastName}`] : []
    }
    if (latestCareplan?.plan?.provider) {
      provider = [`${latestCareplan?.plan?.provider?.providerFirstName && latestCareplan?.plan?.provider?.providerFirstName !== "" ? " " + latestCareplan?.plan?.provider?.providerFirstName : ""}
      ${latestCareplan?.plan?.provider?.providerMiddleName && latestCareplan?.plan?.provider?.providerMiddleName !== "" ? " " + latestCareplan?.plan?.provider?.providerMiddleName : ""} 
      ${latestCareplan?.plan?.provider?.providerLastName && latestCareplan?.plan?.provider?.providerLastName !== "" ? " " + latestCareplan?.plan?.provider?.providerLastName : ""}`]
    }

    setProvider(provider)
    setPractitioner(practitioner)
  }, [latestCareplan, patientMonitoringData])

  const planIcds =
    latestCareplan?.plan?.planIcds ?? patientDiagnosticData?.map(({ icdCode }) => icdCode)

  return patientLoading || careplanLoading ? (
    <Loading />
  ) : patientInfoDetail && latestCareplan && addingBy ? (
    <>
      <Card>
        <h4 className="text-3xl mb-4">Patient Details</h4>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <div className="mb-3">
              <span className="text-lg">Date of Service : </span>
              <span className="text-lg ml-2 font-medium">
                {latestCareplan?.planDate && moment(latestCareplan.planDate).format(USA_DATE_FORMAT)}
              </span>
            </div>
            <div className="mb-3">
              <span className="text-lg">Patient Name : </span>
              <span className="text-lg ml-2 font-medium capitalize">
                {`${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName
                  }`}
              </span>
            </div>
            <div className="mb-3">
              <span className="text-lg">Patient DOB : </span>
              <span className="text-lg ml-2 font-medium">
                {patientInfoDetail?.dob && moment(patientInfoDetail?.dob).format(USA_DATE_FORMAT)}
              </span>
            </div>
            <div className="mb-3">
              <p className="text-lg block capitalize">
                Adding By: {`${addingBy?.name} (${addingBy?.userType})`}
              </p>
            </div>
            {/* <div className="mb-3">
              <span className="text-lg">Date of Plan Modified:</span>
              <span className="text-lg ml-2 font-medium">
                {patientInfoDetail?.careplanStats
                  ? moment.unix(patientInfoDetail?.careplanStats?.updatedAt).format(USA_DATE_FORMAT)
                  : ""}
              </span>
            </div> */}
          </Col>
          <Col xs={24} lg={12}>
            <div className="mb-3">
              <p className="text-lg block">Monitor Type : CCM</p>
            </div>
            <div className="mb-3">
              {provider && (provider?.length !== 0) && (
                <p className="text-lg block capitalize">
                  Provider :{" "}
                  {provider?.join(", ")}
                </p>
              )}
            </div>
            <div className="mb-3">
              {practitioner && (practitioner?.length !== 0) && (
                <p className="text-lg block capitalize">
                  Practitioner :{" "}
                  {practitioner?.join(", ")}
                </p>
              )}
            </div>
            <div className="mb-3">
              <p className="text-lg capitalize">
                Diagnostic(ICD) Codes: {latestCareplan?.plan?.planIcds ?? planIcds?.join(", ")}
              </p>
            </div>
          </Col>
        </Row>
      </Card>

      <React.Suspense fallback={<Loading />}>
        <CCMAdminFollowUpQuestions
          patientInfoDetail={patientInfoDetail}
          carePlanData={latestCareplan}
          planIcds={planIcds}
          provider={provider}
          addingBy={addingBy}
          ccmMonitorId={ccmMonitorId}
        />
      </React.Suspense>
    </>
  ) : (
    <HandleApiFail route={`/${orgId}/${orgName}/ccm/patients`} />
  )
}

export default CCMAdminFollowUpPatient
