import React from 'react'
import { useNavigate } from 'react-router-dom'
import {RxLinkBreak1} from 'react-icons/rx'

const HandleApiFail = ({message,route}) => {
    const navigate = useNavigate()

  return (
    <div className="text-center my-5">
      <div className='d-flex justify-content-center align-items-center'>
      <RxLinkBreak1 size={50} className='text-secondary'/>
      </div>
    {/* <h3 className="text-danger fw-light">{message}</h3> */}
    <h4 className="text-danger fw-light">Oops! Something Went Wrong.</h4>
    <h5 className="text-secondary fw-light">Please Try Later</h5>
    <button className='btn btn-dark shadow btn-sm mt-2' onClick={()=>navigate(route)}>Back</button>
    </div>
  )
}

export default HandleApiFail