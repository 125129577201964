import React from "react"
import { Modal, Button } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import TriageFormInput from "../TriageFormInput"

const OxiRootQuesModel = props => {
  const { showModal, setShowModal, isRootQues, handleRootQues, confirmPopup, patientInfoDetail } = props
  const navigate = useNavigate()
  const {orgId, orgName} = useParams()

  const handleCancel = () => {
    // setShowModal(false)
    navigate(`/${orgId}/${orgName}/critical-patient-readings/${patientInfoDetail?.id}`, {
      state: {
        name: `${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName}`
      }
    })
  }

  return (
    <Modal
      open={showModal}
      onCancel={handleCancel}
      footer={null}
      title={<h4 className="text-xl">{isRootQues.name}</h4>}>
      <div className="pt-4">
        <TriageFormInput ques={isRootQues} handleNormalCheck={handleRootQues} />
        <div className="flex justify-end gap-3 mt-4">
          <Button danger
            onClick={() => navigate(`/${orgId}/${orgName}/critical-patient-readings/${patientInfoDetail?.id}`, {
              state: {
                name: `${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName}`
              }
            })}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={confirmPopup}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default OxiRootQuesModel
