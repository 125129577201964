import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup"
import { object } from "yup";
import debounce from 'lodash/debounce';
import { useToasts } from "react-toast-notifications"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Col, Input, Modal, Row, Select, Space, Spin } from "antd"
import { GetAdminCaretakerForGroup, GetGroupDetail, GetGroups, UpdateGroup, ValidateGroupName } from "../../redux/actions/groupsActions";

const defaultValues = {
    groupName: "",
    groupMembers: [],
    groupManagers: []
}

const GROUP_NAME_REGEX = /^[a-zA-Z0-9._%+-]+$/;

const schema = object().shape({
    groupName: yup.string().required("Group Name is required").matches(GROUP_NAME_REGEX, "Group Name Can Only Contain a-z, A=Z, 0-9, ., _, %, +"),
    groupMembers: yup.array().min(2, "Group Members are required").required("Group Members are required"),
    groupManagers: yup.array().min(1, "Group Managers are required").required("Group Managers are required")
});

const UPDATE_GROUP_SUCCESS_MSG = "Group updated successfully"
const UPDATE_GROUP_ERROR_MSG = "Failed to update group"

const EditGroup = ({ showEditGroup, setShowEditGroup, selectedGroupDetails }) => {
    const [fetching, setFetching] = useState(false);
    const [membersOptions, setMembersOptions] = useState([])
    const [managersOptions, setManagersOptions] = useState([])
    const [debounceTimeout, setDebounceTimeout] = useState(500);
    const [isvalidGroupName, setIsvalidGroupName] = useState(false)
    const [groupName, setGroupName] = useState("")
    const [selectedMembers, setSelectedMembers] = useState([])

    const dispatch = useDispatch()
    const { addToast } = useToasts()

    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { userInfo } = useSelector(state => state.userInfo)
    const { loading } = useSelector((state) => state.updateGroup)
    const { loading: validateLoading } = useSelector((state) => state.validateGroupName)

    useEffect(() => {
        if (selectedMembers.length > 0) {
            const prevManagers = getValues("groupManagers")
            const managers = selectedMembers?.filter((member) => member?.profileType?.toLowerCase() === "admin")?.map((manager) => manager.value)
            const newmanagers = [...new Set([...prevManagers, ...managers])]
            setValue("groupManagers", newmanagers)
        }
    }, [selectedMembers])

    useEffect(() => {
        if (selectedGroupDetails?.id) {
            const members = selectedGroupDetails?.members?.map((member) => ({
                label: <span className="capitalize">{member?.memberName}</span>,
                value: member.id,
                desc: <>
                    <span className="capitalize">{member?.memberName}</span>
                    {/* {<span className="font-light">{` <${member?.email ?? ""}>`}</span>} */}
                </>,
                profileType: member?.profileType
            }))
            const managers = selectedGroupDetails?.managers?.map((manager) => ({
                label: <span className="capitalize">{manager?.managerName}</span>,
                value: manager.id,
                desc: <>
                    <span className="capitalize">{manager?.managerName}</span>
                    {/* {<span className="font-light">{` <${manager?.email ?? ""}>`}</span>} */}
                </>,
                profileType: manager?.profileType
            }))

            setMembersOptions(members)
            setManagersOptions(members)
            setValue("groupMembers", members?.map((member) => member?.value))
            setValue("groupManagers", managers?.map((manager) => manager?.value))
        }
        if (selectedGroupDetails?.id) {
            setValue("groupName", selectedGroupDetails?.name)
            setGroupName(selectedGroupDetails?.name)
        }
    }, [selectedGroupDetails?.id])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            if (!value) {
                return;
            }
            setFetching(true);

            dispatch(GetAdminCaretakerForGroup(value))
                .then((data) => {
                    if (data?.details?.length !== 0) {
                        const members = data?.details?.map((member) => ({
                            label: <>
                                <span className="capitalize">{`${member?.firstName} ${member?.middleName ? member?.middleName : ""} ${member?.lastName}`}</span>
                            </>,
                            value: member.id,
                            desc: <>
                                <span className="capitalize">{`${member?.firstName} ${member?.middleName ? member?.middleName : ""} ${member?.lastName}`}</span>
                                {<span className="font-light">{` <${member?.email}>`}</span>}
                            </>,
                            profileType: member?.profileType
                        }))
                        setMembersOptions(members);
                    }
                    setFetching(false);
                }).catch((error) => {
                    console.error(error)
                });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [dispatch, debounceTimeout]);

    const validatGroupName = useMemo(() => {
        const loadOptions = (value) => {
            if (!value || value === selectedGroupDetails?.name) {
                return;
            }

            dispatch(ValidateGroupName(value))
                .then((data) => {
                    setIsvalidGroupName(data?.details?.validate)
                }).catch((error) => {
                    console.error(error)
                });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [dispatch, debounceTimeout]);

    useEffect(() => {
        if (groupName !== "") {
            // added 200 ms delay utill errors are not updated
            setTimeout(() => {
                !errors?.groupName?.message && validatGroupName(groupName)
            }, 200)
        }
    }, [groupName, validatGroupName])

    const handleCancel = () => {
        setShowEditGroup(false);
    };


    const handleRemoveManager = (options) => {
        const managers = getValues("groupManagers")
        const optionsIds = options?.map((option) => option?.value)
        if (managers?.every((item) => typeof item === "object")) {
            const filteredManagers = managers?.filter((manager) => optionsIds.includes(manager.value))
            setValue("groupManagers", filteredManagers)
        } else if (managers?.every((item) => typeof item === "string")) {
            const filteredManagers = managers?.filter((manager) => optionsIds.includes(manager))
            setValue("groupManagers", filteredManagers)
        }
        return
    }

    const onSubmit = async (data) => {
        let formData = {
            name: data?.groupName,
            memberIds: [],
            managerIds: []
        }
        if (data?.groupMembers?.every((item) => typeof item === "object")) {
            const memberIds = data?.groupMembers?.map((member) => member?.value)
            formData = {
                ...formData,
                memberIds
            }
        } else if (data?.groupMembers?.every((item) => typeof item === "string")) {
            const memberIds = data?.groupMembers
            formData = {
                ...formData,
                memberIds
            }
        }
        if (data?.groupManagers?.every((item) => typeof item === "object")) {
            const managerIds = data?.groupManagers?.map((manager) => manager?.value)
            formData = {
                ...formData,
                managerIds
            }
        } else if (data?.groupManagers?.every((item) => typeof item === "string")) {
            const managerIds = data?.groupManagers
            formData = {
                ...formData,
                managerIds
            }
        }
        // add admin id to the managers byDefault if not present
        let managerIds = [...formData?.managerIds]
        if (!managerIds?.includes(userInfo?.id)) {
            managerIds = managerIds?.concat(userInfo?.id)
        }
        // add admin id to the members byDefault if not present
        let memberIds = [...formData?.memberIds]
        if (!memberIds?.includes(userInfo?.id)) {
            memberIds = memberIds?.concat(userInfo?.id)
        }
        formData = {
            ...formData,
            managerIds,
            memberIds
        }
        const result = await dispatch(UpdateGroup(formData, selectedGroupDetails?.id))
        if (result?.status === 200) {
            addToast(UPDATE_GROUP_SUCCESS_MSG, { appearance: "success", autoDismiss: true })
            dispatch(GetGroups(userInfo?.id))
            setShowEditGroup(false);
        } else {
            addToast(UPDATE_GROUP_ERROR_MSG, { appearance: "error", autoDismiss: true })
        }
    };

    // const filterOption = (input, option) =>
    //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Modal
            open={showEditGroup}
            footer={null}
            onOk={handleCancel}
            onCancel={handleCancel}
            title={<h4 className="text-2xl">Edit Group Info</h4>}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={16} className="flex flex-col justify-center items-start">
                    <Col className="mb-3 w-full" style={{ minHeight: "90px" }}>
                        <div className="">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="groupName">
                                Group Name <span className="text-danger">*</span>
                            </label>
                            <Controller
                                name="groupName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Input
                                        size="large"
                                        id="groupName"
                                        placeholder="Group Name"
                                        status={errors.groupName ? "error" : undefined}
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e.target.value?.trim());
                                            setGroupName(e.target.value?.trim())
                                        }}
                                    />
                                )}
                            />
                            {errors.groupName ? (
                                <small className="text-danger">{errors.groupName.message}</small>
                            ) : (
                                isvalidGroupName && <small className="text-danger capitalize">Group Name already exists</small>
                            )}
                        </div>
                    </Col>
                    <Col className="mb-3 w-full" style={{ minHeight: "90px" }}>
                        <div className="">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="groupMembers">
                                Group Members <span className="text-danger">*</span>
                            </label>
                            <Controller
                                name="groupMembers"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        className="selectRoot"
                                        size="large"
                                        id="groupMembers"
                                        placeholder="Group Members"
                                        filterOption={false}
                                        notFoundContent={fetching ? <Spin size="small" /> : null}
                                        onSearch={(value) => debounceFetcher(value)}
                                        status={errors.groupMembers ? "error" : undefined}
                                        value={field.value}
                                        onChange={(value, option) => {
                                            field.onChange(value)
                                            setManagersOptions(option);
                                            setSelectedMembers(option)
                                            handleRemoveManager(option)
                                        }}
                                        options={membersOptions}
                                        optionRender={(option) => (
                                            <Space>
                                                <span>
                                                    {option.data.desc}
                                                </span>
                                            </Space>
                                        )}
                                    />
                                )}
                            />
                            {errors.groupMembers ? (
                                <small className="text-danger capitalize">{errors.groupMembers.message}</small>
                            ) : null}
                        </div>
                    </Col>
                    <Col className="mb-3 w-full" style={{ minHeight: "90px" }}>
                        <div className="">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="groupManagers">
                                Group Managers <span className="text-danger">*</span>
                            </label>
                            <Controller
                                name="groupManagers"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        showSearch={false}
                                        style={{ width: '100%' }}
                                        className="selectRoot"
                                        size="large"
                                        id="groupManagers"
                                        placeholder="Group Managers"
                                        // filterOption={filterOption}
                                        notFoundContent={fetching ? <Spin size="small" /> : null}
                                        status={errors.groupManagers ? "error" : undefined}
                                        {...field}
                                        options={managersOptions}
                                        optionRender={(option) => (
                                            <Space>
                                                <span>
                                                    {option.data.desc}
                                                </span>
                                            </Space>
                                        )}
                                    />
                                )}
                            />
                            {errors.groupManagers ? (
                                <small className="text-danger capitalize">{errors.groupManagers.message}</small>
                            ) : null}
                        </div>
                    </Col>
                </Row>
                <Row gutter={16} className="flex justify-end items-center">
                    <Col className="mt-4">
                        <Button
                            type="default"
                            size="large"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col className="mt-4">
                        <Button
                            type="primary"
                            size="large"
                            disabled={isvalidGroupName || validateLoading || groupName === ""}
                            loading={loading}
                            htmlType="submit"
                        >
                            Update
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    )
}

export default EditGroup