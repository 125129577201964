import React, { useState, useEffect } from "react"
import Loading from "../components/layouts/Loading/Loading"
import { Helmet } from "react-helmet"
import PlanView from "@fitpeo/fit-plan-viewer"
import { TempAxiosGet } from "../utils/TokenStorage"
import { convertCareplanToV2 } from "../utils/careplanVersioning"
import { Typography } from "antd"

const MobileCCM = () => {
  const [patientInfoDetail, setPatientInfoDetail] = useState(null)
  const [carePlanData, setCarePlanData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [firstCall, setFirstCall] = useState(false)
  const [providers, setProviders] = useState(null)
  const [practitioners, setPractitioners] = useState(null)
  const [icdCodes, setIcdCodes] = useState(null)

  // useEffect(() => {
  //     setLoading(true)
  //     const timer = setTimeout(() => {
  //         if (window.value !== undefined && window.token !== undefined && firstCall === false) {
  //                 //dispatch(getPatientInfo({ patientId: window.value.patientId }));
  //                 getPatientData(window.value.patientId)
  //                 getCcmPlan(window.value.patientId)
  //                 setFirstCall(true)
  //         }
  //     }, 1000);
  //     setLoading(false)
  //     return () => clearTimeout(timer);

  // }, [window.token]);

  useEffect(() => {
    setTimeout(() => {
      if (window.value !== undefined && window.token !== undefined && firstCall === false) {
        getPatientData(window.value.patientId)
        setFirstCall(true)
      }
    }, 1000)
  }, [window.token]) //eslint-disable-line

  const getCcmPlan = async patientID => {
    const res = await TempAxiosGet(
      window.token,
      `/api/ccm/careplan/comprehensive/latest/${patientID}/fetch`
    )
    setCarePlanData(res?.details)

    // if (res?.details !== null) {
    //   if (!res?.details?.plan?.planIcds) getIcds(patientID)
    //   if (!res?.details?.plan?.provider) getProvider(patientID)
    // }
    if (!res?.details?.plan?.planIcds) getIcds(patientID)
    if (res?.details?.plan?.providers) {
      setProviders(res?.details?.plan?.providers)
      setPractitioners(res?.details?.plan?.practitioners)
    } else if (res?.details?.plan?.provider) {
      setProviders([`${res?.details?.plan?.provider?.providerFirstName && res?.details?.plan?.provider?.providerFirstName !== "" ? " " + res?.details?.plan?.provider?.providerFirstName : ""}
      ${res?.details?.plan?.provider?.providerMiddleName && res?.details?.plan?.provider?.providerMiddleName !== "" ? " " + res?.details?.plan?.provider?.providerMiddleName : ""} 
      ${res?.details?.plan?.provider?.providerLastName && res?.details?.plan?.provider?.providerLastName !== "" ? " " + res?.details?.plan?.provider?.providerLastName : ""}`])
    } else {
      getProvider(patientID)
    }
  }

  const getPatientData = async patientId => {
    const res = await TempAxiosGet(window.token, `/api/cs/patients/${patientId}/profile`)
    if (res?.status === 200) {
      setPatientInfoDetail(res?.details)
      await getCcmPlan(patientId)
      setLoading(false)
    }
  }

  const getIcds = async patientID => {
    const res = await TempAxiosGet(window.token, `/api/cs/patients/diagnostic/${patientID}/fetch`)
    setIcdCodes(res?.details?.map(({ icdCode }) => icdCode))
  }

  const getProvider = async patientID => {
    const res = await TempAxiosGet(window.token, `/api/cs/patients/monitoring/${patientID}/fetch`)
    let provider = []
    let practitioner = []

    if (res?.details?.ccm?.[0]) {
      provider = res?.details?.ccm?.[0]?.providerId ? [` ${res?.details?.ccm?.[0]?.providerfirstName}
      ${res?.details?.ccm?.[0]?.providerMiddleName && res?.details?.ccm?.[0]?.providerMiddleName !== "" ? " " + res?.details?.ccm?.[0]?.providerMiddleName : ""} 
      ${res?.details?.ccm?.[0]?.providerLastName}`] : []
    }
    if (res?.details?.ccm?.[0]) {
      practitioner = res?.details?.ccm?.[0]?.practitionerId ? [` ${res?.details?.ccm?.[0]?.practitionerfirstName}
      ${res?.details?.ccm?.[0]?.practitionerMiddleName && res?.details?.ccm?.[0]?.practitionerMiddleName !== "" ? " " + res?.details?.ccm?.[0]?.practitionerMiddleName : ""} 
      ${res?.details?.ccm?.[0]?.practitionerLastName}`] : []
    }

    setProviders(provider)
    setPractitioners(practitioner)
  }

  return (
    <>
      <Helmet>
        <script>{`
                function fillDetails(jsonData) {
                var content = JSON.parse(decodeURIComponent(jsonData))
                // var content = {"token":"eyJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJzZWxmIiwic3ViIjoiNDMzOTYwOWMtN2M1MC00YTgyLWI2MTctYzBkOGRmZjc3NGVlIiwiZXhwIjoxNzE3NDA2NTAxLCJpYXQiOjE3MTc0MDI5MDEsInNjb3BlIjoiY2FyZXRha2VyX2FsbCJ9.seEg43quzF0JuXOST1SxSk3pdsL1V_u3Iwo1LSJzhBE6deP3SfXcxivchc_q5BDrLLcvKOIQCLfKl7qm6p2PsMKUWhoRW34M5SMAhvi1yFxii6QH0xyA5ZfjTwOEGYMgORcV9kL3zAu2c-WzQHxgHGv5EEcGK9Ch-j81U8sAgo4w3gbC7C0lq2mP_5lvIrh7-bVY6qeeTmCZDIOpPDTx_Vh2vEoNS9-LN33MbyYLFnmUXlJ95JktVAACLRGpMjqpDZjetaV-_KreWir6z2mytk0WSiOBLWTsFpYYQcsR5mSwoZvUqI06DJJdDWjtKbrRpZiizVm-63YM8_UU58nzPw", patientId:"89c1328f-1b76-4622-b03b-cd61d5f441ae"};
                
                window.isMobileVersion = true;
                window.value = content;
                window.token = content.token; 
                }
                `}</script>
      </Helmet>
      {loading & !carePlanData ? (
        <Loading />
      ) : (
        (patientInfoDetail !== undefined && carePlanData && (
          <PlanView
            type="ccm"
            planDate={carePlanData?.planDate}
            providers={providers}
            practitioners={practitioners}
            patientInfoDetail={patientInfoDetail}
            plan={convertCareplanToV2(carePlanData?.plan)}
            planIcds={carePlanData?.plan?.planIcds ?? icdCodes}
            addedBy={carePlanData?.plan?.addedBy}
          />
        )) || (
          <Typography.Title level={4} className="mt-3 text-center">
            No plan found
          </Typography.Title>
        )
      )}
    </>
  )
}

export default MobileCCM
