import { useEffect, useState } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Table, Checkbox, Input, Button, Card, Tooltip } from "antd"
import Loading from "../layouts/Loading/Loading"
import { HiDocumentRemove, HiLightBulb } from "react-icons/hi"
import { getTriageFalseReport } from "../../redux/actions/patientAction"
import ClearModal from "./Triage/clearModal/ClearModal"
import moment from "moment/moment"
import { TbAlertTriangleFilled } from "react-icons/tb";
import FalseAlarmModal from "./Triage/clearModal/FalseAlarmModal"


const CriticalPatientDetails = () => {
    const [criticalPatientData, setCriticalPatientData] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedReadingDetails, setSelectedReadingDetails] = useState([])
    const [checkedDeviceType, setCheckedDeviceType] = useState(null);
    const [disabledDevicesIds, setDisabledDevicesIds] = useState([]);
    const [showClearModal, setShowClearModal] = useState(false);
    const [clearReadingId, setClearReadingId] = useState('');
    const [showFalseAlertModal, setShowFalseAlertModal] = useState(false);
    const { id, orgId, orgName } = useParams()
    const [triageStartedAt, setTriageStartedAt] = useState(null)
    const [selectedDeviceType, setSelectedDeviceType] = useState(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const patientName = location?.state?.name
    const shortClearData = useSelector(state => state.shortClearData)
    const { triageFalseReport: criticalDevices, loading: criticalDevicesLoading } = useSelector(state => state.getTriageFalseReport);

    useEffect(() => {
        dispatch(getTriageFalseReport(id));
    }, [])

    useEffect(() => {
        if (selectedIds?.length === 0) {
            setDisabledDevicesIds([]);
            setCheckedDeviceType('');
        }
        if (selectedIds?.length === 1) {
            const otherDevice = criticalPatientData?.filter((item) => item.deviceType !== checkedDeviceType)
            const otherDeviceTypeIds = otherDevice.map((item) => item.readingId)
            setDisabledDevicesIds(otherDeviceTypeIds)
        }
    }, [selectedIds])

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            const selectedIds = selectedRows?.map((item) => item.readingId);
            const selectedReadingDetails = selectedRows?.map((item) => ({ deviceType: item.deviceType, readingDetails: item.readingDetails, readingId: item.readingId }))
            if (selectedRows?.length > 0) {
                setCheckedDeviceType(selectedRows[0]?.deviceType);
            } else {
                setCheckedDeviceType(null);
            }
            setSelectedIds(selectedIds);
            setSelectedReadingDetails(selectedReadingDetails)
        },
        getCheckboxProps: (record) => ({
            disabled: checkedDeviceType && record?.deviceType !== checkedDeviceType || !record?.status || record?.snooze,
            name: record.name,
        }),
    };

    useEffect(() => {
        let shortClearReadingIds = [];
        if (shortClearData !== undefined && shortClearData !== null && shortClearData !== "undefined" && shortClearData?.length) {
            // let newData = []
            shortClearData?.map(item => {
                if (new Date(item?.currentTime).getTime() > new Date().getTime()) {
                    shortClearReadingIds.push(item?.readingId)
                }
            })
            // shortClearReadingIds = shortClearData?.map(item => item.readingId)
        }
        let newData = [];
        let sno = 1
        criticalDevices?.sort((a, b) => moment(b.receivedAt) - moment(a.receivedAt))?.map(({ deviceType, readingDetails, readingId, receivedAt, resolvedAt, snoozeAt, clearedAt, status }) => {
            if (shortClearReadingIds?.includes(readingId)) {
                newData.push({
                    key: sno,
                    sNo: sno,
                    id: id,
                    selected: false,
                    snooze: true,
                    deviceType: deviceType,
                    readingDetails: readingDetails,
                    readingId: readingId,
                    receivedAt,
                    resolvedAt,
                    snoozeAt,
                    clearedAt,
                    status
                })
            } else {
                newData.push({
                    key: sno,
                    sNo: sno,
                    id: id,
                    selected: false,
                    snooze: false,
                    deviceType: deviceType,
                    readingDetails: readingDetails,
                    readingId: readingId,
                    receivedAt,
                    resolvedAt,
                    snoozeAt,
                    clearedAt,
                    status
                })
            }
            sno += 1
        })
        setCriticalPatientData(newData);
    }, [criticalDevices, shortClearData])

    const handleSnooze = ({ startTime, readingId, readingDetails }) => {
        setSelectedDeviceType(readingDetails?.deviceType)
        setClearReadingId(readingId);
        setShowClearModal(true);
        setTriageStartedAt(startTime)
    }

    const handleFalseAlarm = ({ startTime, readingId, readingDetails }) => {
        setSelectedDeviceType(readingDetails?.deviceType)
        setClearReadingId(readingId);
        setShowFalseAlertModal(true);
        setTriageStartedAt(startTime)
    }

    const columns = [
        {
            title: "S.No.",
            dataIndex: "sNo",
            key: "sNo"
        },
        {
            title: "Device",
            dataIndex: "deviceType",
            key: "deviceType",
            render: (deviceType, { id }) => (
                <p className="capitalize">
                    {deviceType}
                </p >
            ),
            sorter: (a, b) => a?.deviceType.localeCompare(b?.deviceType)
        },
        {
            title: "Reading",
            dataIndex: "readingDetails",
            key: "readingDetails",
            render: (readingDetails, { deviceType }) => (
                <div>
                    {deviceType === "oximeter" &&
                        <div>
                            <p>SpO2 : {readingDetails.spo}</p>
                            <p>Pulse rate : {readingDetails.pulseRate} {readingDetails.pulseUnit}</p>
                        </div>
                    }
                    {deviceType === "blood pressure machine" &&
                        <div>
                            <p>Heart rate : {readingDetails.heartRate} {readingDetails.pulseUnit}</p>
                            <p>Systolic pressure : {readingDetails.systolicPressure} {readingDetails.bloodPressureUnit}</p>
                            <p>Diastolic pressure : {readingDetails.diastolicPressure} {readingDetails.bloodPressureUnit}</p>
                        </div>
                    }
                    {deviceType === "glucose machine" &&
                        <div>
                            <p>Glucose level : {readingDetails.glucoseLevel} {readingDetails.glucoseUnit}</p>
                        </div>
                    }
                </div>
            )
        },
        {
            title: "Received At",
            dataIndex: "receivedAt",
            key: "receivedAt",
            render: (receivedAt) => (
                <div className="w-full flex gap-2">
                    <span>{!receivedAt ? "-" : moment(receivedAt).format("MM/DD/YYYY [at] hh:mm A")}</span>
                </div>
            ),
            sorter: (a, b) => moment(a?.receivedAt) - moment(b?.receivedAt)
        },
        {
            title: "Resolved At",
            dataIndex: "resolvedAt",
            key: "resolvedAt",
            render: (resolvedAt) => (
                <div className="w-full flex gap-2">
                    <span>{!resolvedAt ? "-" : moment(resolvedAt).format("MM/DD/YYYY [at] hh:mm A")}</span>
                </div>
            ),
            sorter: (a, b) => moment(a?.resolvedAt) - moment(b?.resolvedAt)
        },
        {
            title: "Snoozed At",
            dataIndex: "snoozeAt",
            key: "snoozeAt",
            render: (snoozeAt) => (
                <div className="w-full flex gap-2">
                    <span>{!snoozeAt ? "-" : moment(snoozeAt).format("MM/DD/YYYY [at] hh:mm A")}</span>
                </div>
            ),
            sorter: (a, b) => moment(a?.snoozeAt) - moment(b?.snoozeAt)
        },
        {
            title: "Cleared At",
            dataIndex: "clearedAt",
            key: "clearedAt",
            render: (clearedAt) => (
                <div className="w-full flex gap-2">
                    <span>{!clearedAt ? "-" : moment(clearedAt).format("MM/DD/YYYY [at] hh:mm A")}</span>
                </div>
            ),
            sorter: (a, b) => moment(a?.clearedAt) - moment(b?.clearedAt)
        },
        {
            title: "Actions",
            render: (readingDetails, { id, status }) => (
                <div className="w-full flex gap-2">
                    <Tooltip title="Snooze/Clear triage">
                        <Button
                            disabled={disabledDevicesIds?.includes(readingDetails.readingId) || readingDetails.snooze || !status}
                            type="primary"
                            className="mb-2"
                            icon={<HiDocumentRemove size={22} />}
                            onClick={() => handleSnooze({ startTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'), readingId: readingDetails.readingId, readingDetails })}
                        />
                    </Tooltip>
                    <Tooltip title="Create triage">
                        <Button
                            disabled={disabledDevicesIds?.includes(readingDetails.readingId) || readingDetails.snooze || !status}
                            type="primary"
                            icon={<HiLightBulb size={22} />}
                            onClick={() =>
                                // (window.location.href = `/rpm-triage-patient/${id}`)
                                navigate(`/${orgId}/${orgName}/rpm-triage-patient/${id}`, {
                                    state: {
                                        type: checkedDeviceType || readingDetails.deviceType,
                                        readingIds: selectedIds.length !== 0 ? selectedIds : [readingDetails.readingId],
                                        readingDetails: selectedIds.length !== 0 ? selectedReadingDetails : [readingDetails?.readingDetails],
                                        triageStartedAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
                                    }
                                })
                            }
                        />
                    </Tooltip>
                    <Tooltip title="False Alarm">
                        <Button
                            disabled={disabledDevicesIds?.includes(readingDetails.readingId) || readingDetails.snooze || !status}
                            type="primary"
                            className="mb-2"
                            icon={<TbAlertTriangleFilled size={22} />}
                            onClick={() => handleFalseAlarm({ startTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'), readingId: readingDetails.readingId, readingDetails })}
                        />
                    </Tooltip>
                </div>
            )
        }
    ]

    return criticalDevicesLoading ? (
        <div className="loaderLoading">
            <Loading />
        </div>
    ) : (
        <>
            <Card className="rounded-b-none">
                <div className="d-flex justify-content-between">
                    <h3 className="text-xl capitalize hover:text-primary cursor-pointer"
                        onClick={() => {
                            navigate(`/${orgId}/${orgName}/patient/${id}`)
                        }}>
                        {patientName}
                    </h3>
                </div>
            </Card>
            {selectedIds.length !== 0 &&
                <div className="w-full flex justify-between items-center px-6 py-2 bg-[#fff] rounded-md border-[1px] border-[#f0f0f0]">
                    <h4 className="font-semibold">Actions</h4>
                    <h1><span className="font-semibold">Note</span> : Only one type of device should be selected at a time</h1>
                    <span className="flex gap-3 mr-7">
                        <Tooltip title="Snooze/Clear triage">
                            <Button
                                type="primary"
                                className="mb-2"
                                icon={<HiDocumentRemove size={22} />}
                                onClick={() => handleSnooze({ startTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'), readingDetails: selectedReadingDetails?.[0] })}
                            />
                        </Tooltip>
                        <Tooltip title="Create triage">
                            <Button
                                type="primary"
                                icon={<HiLightBulb size={22} />}
                                onClick={() =>
                                    navigate(`/${orgId}/${orgName}/rpm-triage-patient/${id}`, {
                                        state: {
                                            type: checkedDeviceType,
                                            readingIds: selectedIds,
                                            readingDetails: selectedReadingDetails,
                                            triageStartedAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
                                        }
                                    })
                                }
                            />
                        </Tooltip>
                        <Tooltip title="False Alarm">
                            <Button
                                type="primary"
                                className="mb-2"
                                icon={<TbAlertTriangleFilled size={22} />}
                                onClick={() => handleFalseAlarm({ startTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'), readingDetails: selectedReadingDetails?.[0] })}
                            />
                        </Tooltip>
                    </span>
                </div>
            }
            <Table
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                columns={columns}
                scroll={{ x: "max-content" }}
                dataSource={criticalPatientData ? criticalPatientData : []}
            />
            {showClearModal &&
                <ClearModal
                    showClearModal={showClearModal}
                    setShowClearModal={setShowClearModal}
                    selectedIds={selectedIds.length !== 0 ? selectedIds : [clearReadingId]}
                    id={id}
                    triageStartedAt={triageStartedAt}
                    setTriageStartedAt={setTriageStartedAt}
                    checkedDeviceType={selectedDeviceType}
                />
            }
            {showFalseAlertModal &&
                <FalseAlarmModal
                    showFalseAlertModal={showFalseAlertModal}
                    setShowFalseAlertModal={setShowFalseAlertModal}
                    selectedIds={selectedIds.length !== 0 ? selectedIds : [clearReadingId]}
                    id={id}
                    triageStartedAt={triageStartedAt}
                    setTriageStartedAt={setTriageStartedAt}
                    checkedDeviceType={selectedDeviceType}
                />
            }
        </>
    )
}

export default CriticalPatientDetails