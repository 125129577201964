export const PROVIDER_LIST_REQUEST = "PROVIDER_LIST_REQUEST"
export const PROVIDER_LIST_SUCCESS = "PROVIDER_LIST_SUCCESS"
export const PROVIDER_LIST_FAIL = "PROVIDER_LIST_FAIL"
export const PROVIDER_ADD_REQUEST = "PROVIDER_ADD_REQUEST"
export const PROVIDER_ADD_SUCCESS = "PROVIDER_ADD_SUCCESS"
export const PROVIDER_ADD_FAIL = "PROVIDER_ADD_FAIL"
export const PROVIDER_PATIENT_LIST_REQUEST = "PROVIDER_PATIENT_LIST_REQUEST"
export const PROVIDER_PATIENT_LIST_SUCCESS = "PROVIDER_PATIENT_LIST_SUCCESS"
export const PROVIDER_PATIENT_LIST_FAIL = "PROVIDER_PATIENT_LIST_FAIL"
export const PROVIDER_KPI_REQUEST = "PROVIDER_KPI_REQUEST"
export const PROVIDER_KPI_SUCCESS = "PROVIDER_KPI_SUCCESS"
export const PROVIDER_KPI_FAIL = "PROVIDER_KPI_FAIL"

export const PROVIDER_NPI_REQUEST = "PROVIDER_NPI_REQUEST"
export const PROVIDER_NPI_SUCCESS = "PROVIDER_NPI_SUCCESS"
export const PROVIDER_NPI_FAIL = "PROVIDER_NPI_FAIL"

export const SINGLE_PROVIDER_REQUEST = "SINGLE_PROVIDER_REQUEST"
export const SINGLE_PROVIDER_SUCCESS = "SINGLE_PROVIDER_SUCCESS"
export const SINGLE_PROVIDER_FAIL = "SINGLE_PROVIDER_FAIL"

export const UPDATE_PROVIDER_REQUEST = "UPDATE_PROVIDER_REQUEST"
export const UPDATE_PROVIDER_SUCCESS = "UPDATE_PROVIDER_SUCCESS"
export const UPDATE_PROVIDER_FAIL = "UPDATE_PROVIDER_FAIL"
