import React, { useState, useEffect } from "react"
import Loading from "../components/layouts/Loading/Loading"
import { useNavigate } from "react-router-dom"
import AddCareplan from "fit-plan-creator"
import { Helmet } from "react-helmet"
import { TempAxiosGet, TempAxiosPost } from "../utils/TokenStorage"
import { useToasts } from "react-toast-notifications"

const MobileAddCareplan = () => {
  const [answers, setAnswers] = useState([])
  const [patientInfo, setPatientInfo] = useState({})
  const [chronicCat, setChronicCat] = useState([])
  const [loading, setLoading] = useState(false)
  const [firstCall, setFirstCall] = useState(false)
  const { addToast } = useToasts()

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      // if(window.value.chronicCat !== undefined){
      //     setChronicCat(window.value.chronicCat)
      // }
      // if (window.value.patientDetails !== undefined) {
      //     setPatientInfo(window.value.patientDetails)
      // }
      if (window.value !== undefined && window.token !== undefined && firstCall === false) {
        // dispatch(getPatientInfo({ patientId: window.value.patientId }));
        getPatientData(window.value.patientId)
        getCcmPlan(window.value.patientId)
        setFirstCall(true)
      }
      // setLoading(false)
    }, 1000)

    return () => clearTimeout(timer)
  }, [window.value]) //eslint-disable-line

  console.log("ans", answers)
  const getCcmPlan = async patientID => {
    setLoading(true)
    const res = await TempAxiosGet(window.token, `/support/v1/getCCMCareplan?userId=${patientID}`)
    setChronicCat(res?.careplanDetails)
    setLoading(false)
    // let ccData = await dispatch(getccmCareplanDataActions(patientID));
  }
  const getPatientData = async patientId => {
    const res = await TempAxiosGet(
      window.token,
      `/caretaker/v1/getUserProfile?memberId=${patientId}`
    )
    setPatientInfo(res)
  }

  const postAnswers = async () => {
    const res = await TempAxiosPost(
      window.token,
      { userId: patientInfo?.userId, careplan: { ...answers } },
      `/caretaker/v1/postCCMCareplan`
    )
    if (res?.success) {
      addToast("Saved Successfully", { appearance: "success", autoDismiss: true })
    } else {
      addToast("Unable To Save", { appearance: "error", autoDismiss: true })
    }
  }

  useEffect(() => {
    if (answers.length) {
      postAnswers()
    }
  }, [answers]) //eslint-disable-line

  return (
    <div className="mx-3">
      <Helmet>
        <script>{`
                function fillDetails(jsonData) {
                var content = JSON.parse(decodeURIComponent(jsonData))
                //var content = {"token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzNWMyNTA5Y2YzMDUxMTBiYWM1ZjVjNDU5ZjY3MDA1IiwiZXhwIjoxNjcwOTk2MDY4fQ.L4zUfTvRScKH9aovKdEO5SBlqJYAwzoUKpf6LXvo-Ys", patientId:"280101d639295ecb928781f10b7541a7"};
                
                window.isMobileVersion = true;
                window.value = content;
                window.token = content.token;       
        }`}</script>
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <AddCareplan
          patientInfoDetail={patientInfo}
          chronicCategory={chronicCat}
          type="ccm"
          answers={answers}
          setAnswers={setAnswers}
          navigate={navigate}
        />
      )}
    </div>
  )
}

export default MobileAddCareplan
