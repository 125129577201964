import { Modal, Select, Row, Col, Button, Input, Divider, DatePicker } from "antd"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import FloatInput from "../../../utils/FloatInput/FloatInput"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import {
  getPatientStatusAction,
  updatePatientStatusAction,
  updateShipmentStatusAction
} from "../../../redux/actions/patientStatusAction"
import { useToasts } from "react-toast-notifications"
import { UPDATE_PATIENT_STATUS_RESET, UPDATE_SHIPPING_STATUS_RESET } from "../../../redux/types/patientStatusConstant"
import moment from "moment"
import MyDatePicker from "../../../utils/MyDatePicker"
import { getPatientOptoutAction } from "../../../redux/actions/patientAction"

const EquipmentSatusOptionsForPickup = [
  { value: "will_pick_up_from_clinic", label: "Will pick up from clinic" },
  { value: "picked_up_from_clinic", label: "Picked up from clinic" }
]

const EquipmentSatusOptionsForDelivery = [
  { value: "delivery_requested", label: "Delivery requested" },
  { value: "in_transit", label: "In transit" },
  { value: "delivered", label: "Delivered" }
]

const EquipmentSatusOptionsException = [
  { value: "equipment_active", label: "Equipment active" },
  { value: "facing_equipment_issue", label: "Facing equipment issue" },
  { value: "equipment_missing", label: "Equipment missing" }
]

const PatientSatusOptions = [
  { value: "device_received", label: "Device received" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
  { value: "facing_issue", label: "Facing issue" },
  { value: "denied_services", label: "Denied services" }
]

const BooleanOptions = [
  {
    value: "yes",
    label: "Yes"
  },
  {
    value: "no",
    label: "No"
  }
]

const EQUIPMENT_ERR = "Please select equipment status"
const PATINET_STATUS_ERR = "Please select patient status"
const MARKETING_STATUS_ERR = "Please select marketing subcription status"
const BILLING_STATUS_ERR = "Please select billable status"

const STATUS_UPD_SUCCESS_MSG = "Status updated successfully"

const schema = yup
  .object({
    equipmentStatus: yup.string().required(EQUIPMENT_ERR),
    profileStatus: yup.string().required(PATINET_STATUS_ERR),
    marketingStatus: yup.string().required(MARKETING_STATUS_ERR),
    billingStatus: yup.string().required(BILLING_STATUS_ERR)
  })
  .required()

const UpdatePatientStatus = props => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { patientId, statusUpdateShow, handleStatusUpdateClose } = props
  const {
    patientStatus,
    loading: patientStatusLoading,
    error: patientStatusErr
  } = useSelector(state => state.getPatientStatus)
  const {
    updatePatientStatus,
    loading: updateLoading,
    error: updateError
  } = useSelector(state => state.updatePatientStatus)
  const {
    updateShipmentStatus,
    loading: updateShipmentLoading,
    error: updateShipmentError
  } = useSelector(state => state.updateShipmentStatus)
  const {shipmentStatus , loading: shippingtStatusLoading } = useSelector(
    state => state.getShipmentStatus
  )
  const { patientOptOut, loading :optOutLoading } = useSelector(store => store.getPatientOptOut)

  const boolToWord = bool => {
    if (bool === true) return "Yes"
    else if (bool === false) return "No"
    else return
  }
  const WordToBool = word => {
    if (word.toLowerCase() === "yes") return true
    else if (word.toLowerCase() === "no") return false
    else return
  }

  const {
    reset,
    control,
    setValue,
    watch,
    clearErrors,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    setValue(
      "equipmentStatus",
      [
        ...EquipmentSatusOptionsForPickup,
        ...EquipmentSatusOptionsForDelivery,
        ...EquipmentSatusOptionsException
      ].find(
        equipment =>
          equipment.label.toLowerCase().trim() ===
          patientStatus?.equipmentStatus?.toLowerCase()?.trim()
      )?.value || ""
    )
    setValue(
      "profileStatus",
      PatientSatusOptions.find(
        status =>
          status.label.toLowerCase().trim() === patientStatus?.profileStatus?.toLowerCase()?.trim()
      )?.value || ""
    )
    setValue("marketingStatus", boolToWord(patientStatus?.marketingStatus))
    setValue("billingStatus", boolToWord(patientStatus?.billingStatus))
  }, [patientStatusLoading])

  useEffect(() => {
    if (
      patientStatus?.equipmentStatus?.toLowerCase()?.trim() === "in transit" ||
      patientStatus?.equipmentStatus?.toLowerCase()?.trim() === "delivered" ||
      patientStatus?.equipmentStatus?.toLowerCase()?.trim() === "equipment active" ||
      patientStatus?.equipmentStatus?.toLowerCase()?.trim() === "facing equipment issue" ||
      patientStatus?.equipmentStatus?.toLowerCase()?.trim() === "equipment missing"
    ) {
      setValue("shipmentId", shipmentStatus?.shipmentId)
      setValue("deliveredDate", shipmentStatus?.date)
    }

    if(patientStatus?.profileStatus?.toLowerCase()?.trim() === "denied services") {
      setValue("notes", patientOptOut?.notes)
    }
  },[shippingtStatusLoading, patientStatusLoading, optOutLoading])

  const equipmentWatcher = watch("equipmentStatus")
  const patientStatusWatcher = watch("profileStatus")

  const handleClose = () => {
    reset()
    clearErrors()
    dispatch({ type: UPDATE_SHIPPING_STATUS_RESET })
    dispatch({ type: UPDATE_PATIENT_STATUS_RESET })
    handleStatusUpdateClose()
  }
  const onSubmit = async data => {
    let formData = {
      ...(data?.notes ? { notes: data?.notes } : {}),
      profileStatus : data?.profileStatus,
      equipmentStatus: data?.equipmentStatus,
      marketingStatus: WordToBool(data?.marketingStatus),
      billingStatus: WordToBool(data?.billingStatus)
    }
    const result = dispatch(updatePatientStatusAction(patientId, formData))
    result
      ?.then(res => {
        if (res?.status === 200) {
          if (getValues("equipmentStatus") === "in_transit" || getValues("equipmentStatus") === "delivered") {
            let shippingFormData = {
              shipmentId: data?.shipmentId?.trim(),
              ...(getValues("equipmentStatus") === "delivered" && data?.deliveredDate ? { deliveredDate: moment(data.deliveredDate)?.format("YYYY-MM-DDTHH:mm:ss") } : {})
            };
            const shippingResult = dispatch(
              updateShipmentStatusAction(patientId, shippingFormData)
            )
            shippingResult?.then(res => {
              if (res?.status === 200) {
                dispatch(getPatientStatusAction(patientId))
                handleClose()
                addToast(STATUS_UPD_SUCCESS_MSG, {
                  appearance: "success",
                  autoDismiss: true
                })
              }
            })
          } else {
            dispatch(getPatientStatusAction(patientId))
            handleClose()
            addToast(STATUS_UPD_SUCCESS_MSG, {
              appearance: "success",
              autoDismiss: true
            })
          }
          if(data?.profileStatus === "denied_services" ) {
            dispatch(getPatientOptoutAction(patientId))
          }
        }
      })
      .catch(error => {
        console.error(error)
        addToast(updateError || updateShipmentError, {
          appearance: "error",
          autoDismiss: true
        })
      })
  }

  const getEquipmentStatusDrpDwnOpts = () => {
    if (
      shipmentStatus?.shipmentId ||
      patientStatus?.equipmentStatus?.toLowerCase()?.trim() === "delivery requested"
    ) {
      return [...EquipmentSatusOptionsForDelivery, ...EquipmentSatusOptionsException]
    } else if (patientStatus?.equipmentStatus) {
      return [...EquipmentSatusOptionsForPickup, ...EquipmentSatusOptionsException]
    }
    return [
      ...EquipmentSatusOptionsForPickup,
      ...EquipmentSatusOptionsForDelivery,
      ...EquipmentSatusOptionsException
    ]
  }

  return (
    <>
      <Modal
        footer={null}
        open={statusUpdateShow}
        onCancel={handleStatusUpdateClose}
        title={<h4 className="text-xl">Status</h4>}>
        {/* {patientFacilityLoading && loading ? (
        <div className="loaderLoading">
          <Loading />
        </div>
      ) : ( */}
        <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={24}>
            <Col xs={24} className="mb-4">
              <Controller
                name="equipmentStatus"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FloatInput label="Equipment Status">
                    <Select
                      size="large"
                      className="w-100 capitalize"
                      required={true}
                      id="input-equipmentStatus"
                      status={errors.equipmentStatus ? "error" : undefined}
                      options={getEquipmentStatusDrpDwnOpts()}
                      {...field}
                      onChange={e => {
                        field.onChange(e)
                      }}
                      disabled={patientOptOut?.optOut }
                    />
                  </FloatInput>
                )}
              />
              {errors.equipmentStatus ? (
                <small className="text-danger">{errors.equipmentStatus.message}</small>
              ) : null}
            </Col>
            <Col xs={24} className="mb-4">
              <Controller
                name="profileStatus"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FloatInput label="Patient Status">
                    <Select
                      size="large"
                      className="w-100 capitalize"
                      required={true}
                      id="input-profileStatus"
                      status={errors.profileStatus ? "error" : undefined}
                      options={PatientSatusOptions?.map(status => ({
                        value: status.value,
                        label: status.label
                      }))}
                      {...field}
                      onChange={e => {
                        field.onChange(e)
                      }}
                      disabled={patientOptOut?.optOut }
                    />
                  </FloatInput>
                )}
              />
              {errors.profileStatus ? (
                <small className="text-danger">{errors.profileStatus.message}</small>
              ) : null}
            </Col>
            <Col xs={24} className="mb-4">
              <Controller
                name="marketingStatus"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FloatInput label="Marketing Subscription Status">
                    <Select
                      size="large"
                      className="w-100 capitalize"
                      required={true}
                      id="input-marketingStatus"
                      status={errors.marketingStatus ? "error" : undefined}
                      options={BooleanOptions?.map(status => ({
                        value: status.value,
                        label: status.label
                      }))}
                      {...field}
                      onChange={e => {
                        field.onChange(e)
                      }}
                      disabled={patientOptOut?.optOut }
                    />
                  </FloatInput>
                )}
              />
              {errors.marketingStatus ? (
                <small className="text-danger">{errors.marketingStatus.message}</small>
              ) : null}
            </Col>
            <Col xs={24} className="mb-4">
              <Controller
                name="billingStatus"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FloatInput label="Billable Status">
                    <Select
                      size="large"
                      className="w-100 capitalize"
                      required={true}
                      id="input-billingStatus"
                      status={errors.billingStatus ? "error" : undefined}
                      options={BooleanOptions?.map(status => ({
                        value: status.value,
                        label: status.label
                      }))}
                      {...field}
                      onChange={e => {
                        field.onChange(e)
                      }}
                      disabled={patientOptOut?.optOut }
                    />
                  </FloatInput>
                )}
              />
              {errors.billingStatus ? (
                <small className="text-danger">{errors.billingStatus.message}</small>
              ) : null}
            </Col>
            {equipmentWatcher === "in_transit" || equipmentWatcher === "delivered" ? (
              <>
                <Divider />
                <Col xs={24} className="mb-4">
                  <Controller
                    name="shipmentId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FloatInput label="Shipment Id">
                        <Input
                          size="large"
                          className="w-100"
                          required={true}
                          id="input-shipmetentId"
                          status={errors.shipmentId ? "error" : undefined}
                          {...field}
                          onChange={e => {
                            field.onChange(e)
                          }}
                          disabled={patientOptOut?.optOut }
                        />
                      </FloatInput>
                    )}
                  />
                </Col>
              </>
            ) : null}
            {
               equipmentWatcher === "delivered" ? (
                <>
                  <Col xs={24} className="mb-4">
                    <Controller
                      name="deliveredDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                          <MyDatePicker
                          // {...field}
                          value={field.value ? moment(field.value) : null} // Ensure value is in correct moment object format
                          format="MM-DD-YYYY HH:mm"
                            showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                            size="large"
                            className="w-100"
                            id="input-deliveredDate"
                            status={errors.deliveredDate ? "error" : undefined}
                            placeholder="Select delivery date and time"
                            onChange={e => {
                              field.onChange(e)
                            }}
                            disabled={patientOptOut?.optOut }
                          />
                      )}
                    />
                  </Col>
                </>) : null
            }
             {patientStatusWatcher === "denied_services" ? (
              <>
                <Divider />
                <Col xs={24} className="mb-4">
                  <Controller
                    name="notes"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FloatInput label="Reason for opt out">
                        <Input
                          size="large"
                          className="w-100"
                          required={true}
                          id="input-notes"
                          status={errors.notes ? "error" : undefined}
                          {...field}
                          onChange={e => {
                            field.onChange(e)
                          }}
                          disabled={patientOptOut?.optOut }
                        />
                      </FloatInput>
                    )}
                  />
                </Col>
              </>
            ) : null}
            {updateError || updateShipmentError ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-semibold">{updateError || updateShipmentError}</p>
              </Col>
            ) : null}
            <Col xs={24}>
              <div className="flex flex-wrap gap-3 flex-row-reverse">
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={updateLoading || updateShipmentLoading}>
                  Update
                </Button>
                <Button size="large" danger onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </form>
        {/* )} */}
      </Modal>
    </>
  )
}
export default UpdatePatientStatus
