import { axiosGet, axiosPost } from "../axios"
import {
  GET_PRACTITIONER_ADDRESS_FAIL,
  GET_PRACTITIONER_ADDRESS_REQUEST,
  GET_PRACTITIONER_ADDRESS_SUCCESS,
  GET_PRACTITIONER_SPECIALIZATION_FAIL,
  GET_PRACTITIONER_SPECIALIZATION_REQUEST,
  GET_PRACTITIONER_SPECIALIZATION_SUCCESS,
  PRACTITIONER_FILTER_PATIENT_FAIL,
  PRACTITIONER_FILTER_PATIENT_REQUEST,
  PRACTITIONER_FILTER_PATIENT_RESET,
  PRACTITIONER_FILTER_PATIENT_SUCCESS,
  PRACTITIONER_LIST_FAIL,
  PRACTITIONER_LIST_REQUEST,
  PRACTITIONER_LIST_SUCCESS,
  PRACTITIONER_PATIENT_LIST_FAIL,
  PRACTITIONER_PATIENT_LIST_REQUEST,
  PRACTITIONER_PATIENT_LIST_SUCCESS,
  RESET_PRACTITIONER_PATIENT_ADVANCE_FILTER,
  SET_PRACTITIONER_PATIENT_ADVANCE_FILTER,
  SINGLE_PRACTITIONER_FAIL,
  SINGLE_PRACTITIONER_REQUEST,
  SINGLE_PRACTITIONER_SUCCESS
} from "../types/practitionerConstant"

import { userLogoutAction } from "./userActions"

export const getPractitionerList = (orgId) => async dispatch => {
  try {
    dispatch({
      type: PRACTITIONER_LIST_REQUEST
    })
    if (orgId) {
      const { data } = await axiosGet(`/api/cs/practitioner/orgId/${orgId}/profiles`)

      dispatch({
        type: PRACTITIONER_LIST_SUCCESS,
        payload: data?.details
      })
    }
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PRACTITIONER_LIST_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getCaretakerPractitionerList = (orgId) => async dispatch => {
  try {
    dispatch({
      type: PRACTITIONER_LIST_REQUEST
    })
    const userInfo = localStorage.getItem("userInfo")
    if (userInfo && orgId) {
      const caretakerId = JSON.parse(userInfo)?.id
      const { data } = await axiosGet(`/api/cs/caretaker/practitioners/${caretakerId}/org/${orgId}/fetch`)

      dispatch({
        type: PRACTITIONER_LIST_SUCCESS,
        payload: data?.details
      })
    }
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PRACTITIONER_LIST_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getSinglePractitioner = practitionerId => async dispatch => {
  try {
    dispatch({ type: SINGLE_PRACTITIONER_REQUEST })

    const { data } = await axiosGet(`/api/cs/practitioner/${practitionerId}/profile`)
    dispatch({ type: SINGLE_PRACTITIONER_SUCCESS, payload: data.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: SINGLE_PRACTITIONER_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getPractitionerPatientList = practitionerId => async dispatch => {
  try {
    dispatch({
      type: PRACTITIONER_PATIENT_LIST_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/patients/practitioner/${practitionerId}/fetchAll`)

    dispatch({
      type: PRACTITIONER_PATIENT_LIST_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PRACTITIONER_PATIENT_LIST_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getFilterPractitionerPatientAction =
  ({ formData, practitionerId }) =>
  async dispatch => {
    try {
      dispatch({
        type: PRACTITIONER_FILTER_PATIENT_REQUEST
      })
      const { data } = await axiosPost(
        `/api/cs/patients/filters/practitioner/${practitionerId}/fetch`,
        formData
      )
      dispatch({
        type: PRACTITIONER_FILTER_PATIENT_SUCCESS,
        payload: data?.details
      })
      return data?.details
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PRACTITIONER_FILTER_PATIENT_FAIL,
        payload: error.response && error?.response?.data?.details
      })
    }
  }

export const getPractitionerSpecializationAction = practitionerId => async dispatch => {
  try {
    dispatch({ type: GET_PRACTITIONER_SPECIALIZATION_REQUEST })

    const { data } = await axiosGet(`/api/cs/practitioner/specializations/${practitionerId}/fetch`)
    dispatch({ type: GET_PRACTITIONER_SPECIALIZATION_SUCCESS, payload: data.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PRACTITIONER_SPECIALIZATION_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getPractitionerAddressAction = practitionerId => async dispatch => {
  try {
    dispatch({ type: GET_PRACTITIONER_ADDRESS_REQUEST })

    const { data } = await axiosGet(`/api/cs/address/practitioner/${practitionerId}/fetch`)
    dispatch({ type: GET_PRACTITIONER_ADDRESS_SUCCESS, payload: data.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PRACTITIONER_ADDRESS_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const resetFilterPractitionerPatientAction = () => async dispatch => {
  dispatch({
    type: PRACTITIONER_FILTER_PATIENT_RESET
  })
}

export const savePractitionerPatientAdvanceFilter = data => async dispatch => {
  dispatch({
    type: SET_PRACTITIONER_PATIENT_ADVANCE_FILTER,
    payload: data
  })
}

export const resetPractitionerPatientAdvanceFilter = () => async dispatch => {
  dispatch({
    type: RESET_PRACTITIONER_PATIENT_ADVANCE_FILTER
  })
}
