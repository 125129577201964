import React from "react"
import { Button, Modal, Input } from "antd"

const HypotensiveYesAlertModal = ({
  showHypotensiveYesAlert,
  setShowHypotensiveYesAlert,
  HypotesniveYesAlertQus,
  setHypotensiveYesAlertQus
}) => {
  return (
    <Modal
      footer={null}
      open={showHypotensiveYesAlert}
      onCancel={() => setShowHypotensiveYesAlert(false)}
      title={
        <div>
          <p className="font-medium text-xl">Note</p>
          <p>{HypotesniveYesAlertQus?.name}</p>
        </div>
      }>
      <Input.TextArea
        value={HypotesniveYesAlertQus?.text}
        onChange={e =>
          setHypotensiveYesAlertQus({ ...HypotesniveYesAlertQus, text: e.target.value })
        }
      />

      <Button type="primary" onClick={() => setShowHypotensiveYesAlert(false)}>
        Save
      </Button>
    </Modal>
  )
}

export default HypotensiveYesAlertModal
