import { useEffect } from "react"

import { Modal, Button, Radio, Input, Row, Col, Select } from "antd"

import { useDispatch, useSelector } from "react-redux"
import {
  getPatientInfo,
  getPatientVitals,
  postPatientVitals,
  createPatientVitals,
  getPatientMenuConfigAction
} from "../../../redux/actions/patientAction"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { POST_PATIENT_VITALS_RESET } from "../../../redux/types/PatientConstant"
import { useToasts } from "react-toast-notifications"
import { getFtInches } from "../../../utils/heightConversion"

const schemaCreateVitals = yup
  .object({
    weight: yup
      .number()
      .min(10, "Weight shouldn't be less than 10")
      .max(900, "Weight shouldn't be more than 900")
      .typeError("weight is a required field.")
      .required("Weight is a required field."),

    heightFT: yup
      .number()
      .min(1, "Height (Feet) shouldn't be less than 1")
      .typeError("Height (Feet) is a required field.")
      .required("Height (Feet) is a required field."),

    heightIN: yup
      .number()
      .max(11, "Height (Inch) shouldn't be more than 11")
      .typeError("Height (Inch) is a required field.")
      .required("Height (Inch) is a required field.")
  })
  .required()

const schemaUpdateVitals = yup
  .object({
    vitalsType: yup.string().required(),
    weight: yup.number().when("vitalsType", {
      is: value => value === "weight",
      then: () =>
        yup
          .number()
          .min(10, "Weight shouldn't be less than 10")
          .max(900, "Weight shouldn't be more than 900")
          .typeError("Weight is a required field.")
          .required("Weight is a required field.")
    }),

    heightFT: yup.number().when("vitalsType", {
      is: value => value === "height",
      then: () =>
        yup
          .number()
          .min(1, "Height (Feet) shouldn't be less than 1")
          .typeError("Height (Feet) is a required field.")
          .required("Height (Feet) is a required field.")
    }),

    heightIN: yup.number().when("vitalsType", {
      is: value => value === "height",
      then: () =>
        yup
          .number()
          .max(11, "Height (Inch) shouldn't be more than 11")
          .typeError("Height (Inch) is a required field.")
          .required("Height (Inch) is a required field.")
    })
  })
  .required()

const HEIGHT_WEIGHT_UPD_SUCCESS_MSG = "Height & Weight Updated Successfully"
const HEIGHT_WEIGHT_ADD_SUCCESS_MSG = "Height & Weight Added Successfully"

const UpdatePatientVitals = props => {
  const { patientId, patientVitals, updateVitalsShow, handleUpdateVitalsClose } = props
  const { loading, error } = useSelector(state => state.postPatientVitals)

  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(
      patientVitals && patientVitals.length ? schemaUpdateVitals : schemaCreateVitals
    )
  })

  useEffect(() => {
    const subscription = watch("vitalsType")
    return () => (subscription && subscription?.unsubscribe ? subscription.unsubscribe() : null)
  }, [watch, updateVitalsShow])

  useEffect(() => {
    const defaultWeightVal =
      patientVitals && patientVitals.length
        ? [...patientVitals]
          ?.sort((a, b) => new Date(b.date) - new Date(a.date))
          ?.find(v => v.vitalType === "weight")?.value
        : null

    const defaultHeightVal = patientVitals?.length
      ? [...patientVitals]
          ?.sort((a, b) => new Date(b.date) - new Date(a.date))
          ?.find(v => v.vitalType === "height")?.value
      : null

    if (!patientVitals || patientVitals?.length === 0) {
      setValue("vitalsType", undefined)
      setValue("weight", undefined)
    } else {
      setValue("vitalsType", "weight")
      setValue("weight", defaultWeightVal)
      const { feet, inches } = getFtInches(defaultHeightVal)
      setValue("heightFT", feet)
      setValue("heightIN", inches)
    }
  }, [updateVitalsShow, patientVitals, patientVitals?.length])

  useEffect(() => {
    if (errors && Object.keys(errors)?.length) {
      clearErrors()
    }
  }, [getValues("vitalsType")])

  const handleClose = () => {
    handleUpdateVitalsClose()
    reset()
    clearErrors()
    dispatch({ type: POST_PATIENT_VITALS_RESET })
    setValue("vitalsType", undefined)
  }

  const onSubmit = async data => {
    if (patientVitals && patientVitals.length) {
      const obj = { ...patientVitals?.find(v => v.vitalType === data.vitalsType) }
      await dispatch(
        postPatientVitals(patientId, obj?.id, {
          ...(data.vitalsType === "weight"
            ? {
              unit: obj.unit,
              value: data.weight?.toFixed(1),
              vitalType: data.vitalsType
            }
            : {
              unit: obj.unit,
              vitalType: data.vitalsType,
              value: parseInt(parseInt(data.heightFT * 12) + parseInt(data.heightIN))
            })
        })
      ).then(res => {
        if (res?.message?.toLowerCase() === "success") {
          handleClose()
          addToast(HEIGHT_WEIGHT_UPD_SUCCESS_MSG, {
            appearance: "success",
            autoDismiss: true
          })
          dispatch(getPatientInfo({ patientId }))
          dispatch(getPatientVitals(patientId))
        }
      })
    } else {
      console.log(data)
      await dispatch(
        createPatientVitals(patientId, [
          {
            vitalType: "weight",
            unit: "lb",
            value: data.weight?.toFixed(1)
          },
          {
            vitalType: "height",
            unit: "inch",
            value: parseInt(parseInt(data.heightFT * 12) + parseInt(data.heightIN))
          }
        ])
      ).then(res => {
        if (res?.message?.toLowerCase() === "success") {
          handleClose()
          addToast(HEIGHT_WEIGHT_ADD_SUCCESS_MSG, {
            appearance: "success",
            autoDismiss: true
          })
          dispatch(getPatientMenuConfigAction(patientId))
          dispatch(getPatientInfo({ patientId }))
          dispatch(getPatientVitals(patientId))
        }
      })
    }
  }

  return (
    <Modal
      footer={null}
      open={updateVitalsShow}
      onCancel={handleClose}
      title={
        <h4 className="text-xl">
          {patientVitals && patientVitals.length ? "Update Height & Weight" : "Add Height & Weight"}
        </h4>
      }>
      <div className="pt-4">
        {patientVitals && patientVitals.length ? (
          <Controller
            name="vitalsType"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Radio.Group className="mb-4" {...field}>
                <Radio value="weight">Weight</Radio>
                <Radio value="height">Height</Radio>
              </Radio.Group>
            )}
          />
        ) : null}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={20}>
            <Col xs={24} className="mb-4">
              <label
                className={`mb-1 ${getValues("vitalsType") === "height" ? "text-secondary" : ""}`}
                htmlFor="input-weight">
                Weight <span className="text-danger">*</span>
              </label>
              <Controller
                name="weight"
                control={control}
                disabled={getValues("vitalsType") === "height"}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    disabled={getValues("vitalsType") === "height"}
                    suffix="LB"
                    step="any"
                    size="large"
                    type="number"
                    id="input-weight"
                    status={errors.weight ? "error" : undefined}
                    {...field}
                  />
                )}
              />
            </Col>
            <>
              <Col xs={24} md={12}>
                <label
                  className={`mb-1 ${getValues("vitalsType") === "weight" ? "text-secondary" : ""}`}
                  htmlFor="input-heightFT">
                  Height (Feet) <span className="text-danger">*</span>
                </label>
                <Controller
                  name="heightFT"
                  control={control}
                  rules={{ required: true }}
                  disabled={getValues("vitalsType") === "weight"}
                  render={({ field }) => (
                    <Select
                      disabled={getValues("vitalsType") === "weight"}
                      size="large"
                      className="w-100"
                      id="input-heightFT"
                      status={errors.heightFT ? "error" : undefined}
                      options={Array(9)
                        .fill()
                        .map((_, i) => ({
                          value: i + 1,
                          label: i + 1
                        }))}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col xs={24} md={12}>
                <label
                  className={`mb-1 ${getValues("vitalsType") === "weight" ? "text-secondary" : ""}`}
                  htmlFor="input-heightIN">
                  Height (Inch) <span className="text-danger">*</span>
                </label>
                <Controller
                  name="heightIN"
                  control={control}
                  rules={{ required: true }}
                  disabled={getValues("vitalsType") === "weight"}
                  render={({ field }) => (
                  <Select
                      disabled={getValues("vitalsType") === "weight"}
                      size="large"
                      className="w-100"
                      id="input-heightIN"
                      status={errors.heightIN ? "error" : undefined}
                      options={Array(12)
                        .fill()
                        .map((_, i) => ({
                          value: i,
                          label: i
                        }))}
                      {...field}
                    />
                  )}
                />
              </Col>
            </>

            {Object.keys(errors).length ? (
              <Col xs={24}>
                <ul className="mt-3 list-disc pl-4">
                  {Object.keys(errors).map((err, index) => {
                    return (
                      <li key={index} className="text-danger capitalize">
                        {errors[err].message}
                      </li>
                    )
                  })}
                </ul>
              </Col>
            ) : null}
            {error ? (
              <Col className="my-2" xs={24}>
                <p className="text-danger font-semibold">{error}</p>
              </Col>
            ) : null}
            <Col xs={24}>
              <div className="flex flex-wrap gap-3 mt-4">
                <Button size="large" type="primary" htmlType="submit" disabled={loading}>
                  Submit
                </Button>
                <Button size="large" danger onClick={handleUpdateVitalsClose}>
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </Modal>
  )
}

export default UpdatePatientVitals
