import { axiosGet, axiosPost } from "../axios"
import {
  GET_ENROLLMENT_STATS_REQUEST,
  GET_ENROLLMENT_STATS_SUCCESS,
  GET_ENROLLMENT_STATS_FAIL,
  GET_ENROLLMENT_PATIENT_COUNT_REQUEST,
  GET_ENROLLMENT_PATIENT_COUNT_SUCCESS,
  GET_ENROLLMENT_PATIENT_COUNT_FAIL,
  DASHBOARD_KPI_FAIL,
  DASHBOARD_KPI_REQUEST,
  DASHBOARD_KPI_SUCCESS,
  CRITICAL_PATIENT_FAIL,
  CRITICAL_PATIENT_REQUEST,
  CRITICAL_PATIENT_SUCCESS,
  CRITICAL_PATIENT_RESET,
  GET_USER_COUNT_STATS_REQUEST,
  GET_USER_COUNT_STATS_SUCCESS,
  GET_USER_COUNT_STATS_FAIL,
  GET_TRIAGE_STATS_ANALYSIS_REQUEST,
  GET_TRIAGE_STATS_ANALYSIS_SUCCESS,
  GET_TRIAGE_STATS_ANALYSIS_FAIL,
  GET_TRIAGE_STATS_COUNT_REQUEST,
  GET_TRIAGE_STATS_COUNT_SUCCESS,
  GET_TRIAGE_STATS_COUNT_FAIL,
} from "../types/dashboardConstant"
import { userLogoutAction } from "./userActions"

export const getUserCountStatsAction = orgId => async dispatch => {
  const userInfo = localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
  try {
    dispatch({
      type: GET_USER_COUNT_STATS_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/org/stats/caretaker/${userInfo?.id}/org/${orgId}`)
    dispatch({
      type: GET_USER_COUNT_STATS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_USER_COUNT_STATS_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const dashboarKPIAction = () => async dispatch => {
  try {
    dispatch({
      type: DASHBOARD_KPI_REQUEST
    })

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    const { data } = await axiosGet(`/admin/v1/getKPI`, config)

    dispatch({
      type: DASHBOARD_KPI_SUCCESS,
      payload: data
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: DASHBOARD_KPI_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const criticalPatientAction = orgId => async dispatch => {
  try {
    dispatch({
      type: CRITICAL_PATIENT_REQUEST
    })

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }
    const userInfo = localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
    if (userInfo) {
      const { data } = await axiosGet(
        `/api/cs/patients/caretaker/${userInfo.id}/org/${orgId}/fetchAll`,
        config
      )
      dispatch({
        type: CRITICAL_PATIENT_SUCCESS,
        payload: data?.details
      })
    }
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: CRITICAL_PATIENT_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const criticalPatientResetAction = () => async dispatch => {
  dispatch({
    type: CRITICAL_PATIENT_RESET
  })
}

export const filterDashboardAction = (toDate, fromDate, orgId) => async dispatch => {
  try {
    dispatch({
      // type: FILTER_DASHBOARD_REQUEST,
      type: DASHBOARD_KPI_REQUEST
    })

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }
    // const { data } = await axiosGet(`/v1/getDashBoardKPI?startDate=${fromDate}&endDate=${toDate}&orgId=${orgId}`, config);
    const { data } = await axiosGet(
      `/admin/v1/getKPI?startDate=${fromDate}&endDate=${toDate}`,
      config
    )

    dispatch({
      // type: FILTER_DASHBOARD_SUCCESS,
      type: DASHBOARD_KPI_SUCCESS,
      payload: data
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      // type: FILTER_DASHBOARD_FAIL,
      type: DASHBOARD_KPI_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const fetchEnrollmentStatsAction = (dateRange, orgId) => async dispatch => {
  try {
    dispatch({
      type: GET_ENROLLMENT_STATS_REQUEST
    })

    if(!orgId) return
    const { data } = await axiosPost(`/api/cs/patients/enrollment/stats/org/${orgId}/fetch`,  {
      status: [
        "signup_pending",
        "signup_initiated",
        "signed_up",
        "device_received",
        "denied_services",
        "facing_issue",
        "active",
        "inactive"
      ],
      dateRange
    })

    dispatch({
      type: GET_ENROLLMENT_STATS_SUCCESS,
      payload: data?.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_ENROLLMENT_STATS_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const enrollmentPatientCountAction = (date, orgId) => async dispatch => {
  try {
    dispatch({
      type: GET_ENROLLMENT_PATIENT_COUNT_REQUEST
    })

    if(!orgId) return
    const { data } = await axiosPost(`/api/cs/org/enrollment/track/org/${orgId}/fetch`, date)

    dispatch({
      type: GET_ENROLLMENT_PATIENT_COUNT_SUCCESS,
      payload: data?.details
    })
    return data
  }
  catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_ENROLLMENT_PATIENT_COUNT_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const triageStatsAnalysisAction = (caretakerId, date, orgId) => async dispatch => {
  try {
    dispatch({
      type: GET_TRIAGE_STATS_ANALYSIS_REQUEST
    })

    if(!orgId) return
    const { data } = await axiosPost(`/api/rpm/readings/triage/stats/analysis/${caretakerId}/org/${orgId}/fetch`, date)

    dispatch({
      type: GET_TRIAGE_STATS_ANALYSIS_SUCCESS,
      payload: data?.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_TRIAGE_STATS_ANALYSIS_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const triageStatsCountAction = (caretakerId, date, orgId) => async dispatch => {
  try {
    dispatch({
      type: GET_TRIAGE_STATS_COUNT_REQUEST
    })

    if(!orgId)  return
    const { data } = await axiosPost(`/api/rpm/readings/triage/stats/${caretakerId}/org/${orgId}/fetch`, date)

    dispatch({
      type: GET_TRIAGE_STATS_COUNT_SUCCESS,
      payload: data?.details
    })
    return data
  }
  catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_TRIAGE_STATS_COUNT_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}