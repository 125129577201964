import React, { useEffect, useState } from "react"

import { Alert, Card, Table, Radio, DatePicker } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { getBpDetails } from "../../redux/actions/patientAction"

import ReactApexChart from "react-apexcharts"
import moment from "moment"
import PatientProgressbar from "./PatientProgressbar"
import Loading from "../layouts/Loading/Loading"
import { USA_DATE_FORMAT } from "../../utils/usaDateFormat"

const dateFormat = value => {
  return moment(value * 1000).format(`${USA_DATE_FORMAT} [at] hh:mm A`)
  // const date = new Date(value * 1000)
  // return `${`0${date.getMonth() + 1}`.slice(-2)}/${`0${date.getDate()}`.slice(
  //   -2
  // )}/${date.getFullYear()}`
}

const userTimeFormat = value => {
  return moment(value).format(`${USA_DATE_FORMAT} [at] hh:mm A`)
}

const columns = [
  {
    key: "heartRate",
    title: "Heart Rate",
    dataIndex: "heartRate"
  },
  {
    title: "SYS",
    key: "systolicPressure",
    dataIndex: "systolicPressure"
  },
  {
    title: "DIA",
    key: "diastolicPressure",
    dataIndex: "diastolicPressure"
  },
  {
    key: "activeDate",
    title: "Taken At",
    dataIndex: "activeDate",
    render: (_, { createdAt, readingDate }) =>
      readingDate ? userTimeFormat(readingDate) : dateFormat(createdAt)
  }
]

const lineChartOptions = (series, categories) => ({
  series,
  options: {
    chart: {
      type: "line",
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "straight"
    },
    xaxis: {
      categories
    }
  }
})

const PatientBPComponent = props => {
  const { deviceData, patientId, currentDate, durationObj, setBpCustomParameterTab } = props
  const [dateRange, setDateRange] = useState(null)
  const [errorMsg, setErrorMsg] = useState(
    "No reading has been taken for Blood Pressure in past 30 days"
  )
  const [programId, setProgramId] = useState(null)

  const [bpData, setBpData] = useState([])
  const [duration, setDuration] = useState("month")
  const [graphSeries, setGraphSeries] = useState([])
  const [graphCategories, setGraphCategories] = useState([])

  const dispatch = useDispatch()
  const { patientBpDetail, loading } = useSelector(state => state.healthBp)
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)

  useEffect(() => {
    if (!patientId || !patientMonitoringData || !duration) return
    // const program = patientMonitoringData?.rpm[0]?.programs?.find(
    //   device => device?.programId  === RPM_DEVICES[3].programId
    // )
    const selectedProgram = patientMonitoringData?.["rpm"]
      ?.map((programs, index) =>
        programs?.programs?.length ? programs?.programs?.map(program => program) : null
      )
      .flat(Infinity)
    const program = selectedProgram?.find(device => device?.programName === "blood pressure machine")
    if (!program) return
    else setProgramId(program?.programId)
    if (programId) {
      dispatch(
        getBpDetails({
          patientId,
          endDate: currentDate,
          startDate: durationObj[duration],
          programId
        })
      )
    }
  }, [patientId, duration, patientMonitoringData, programId])

  useEffect(() => {
    if (!patientBpDetail?.length) {
      setBpData([])
      switch (duration) {
        case "week":
          setErrorMsg("No reading has been taken for Blood Pressure in past 7 days")
          break
        case "year":
          setErrorMsg("No reading has been taken for Blood Pressure in past 365 days")
          break
        case "month":
          setErrorMsg("No reading has been taken for Blood Pressure in past 30 days")
          break
        default:
          setErrorMsg("No reading has been taken for Blood Pressure")
      }
    } else {
      const formattedGraphDate = []
      const series = [
        {
          name: "High Pressure",
          data: []
        },
        {
          name: "Low Pressure",
          data: []
        },
        {
          name: "Heart Rate",
          data: []
        }
      ]
      let decrement = patientBpDetail
        ?.sort(
          (a, b) =>
            new Date(b?.readingDate || b?.createdAt) - new Date(a?.readingDate || a?.createdAt)
        )
        ?.map((reading, index) => ({ ...reading, index }))
      setBpData(decrement)

      const increment = [...decrement]?.reverse()

      increment !== undefined &&
        increment?.forEach((reading, index) => {
          const graphDate = new Date(reading?.readingDate)
          const formattedDate =
            `${`0${graphDate.getMonth() + 1}`.slice(-2)}` +
            `/${`0${graphDate.getDate()}`.slice(-2)}/${graphDate.getFullYear()}`
          formattedGraphDate.push(formattedDate)
          series[0].data.push(reading.systolicPressure)
          series[1].data.push(reading.diastolicPressure)
          series[2].data.push(reading.heartRate)
        })
      setGraphSeries(series)
      setGraphCategories(formattedGraphDate)
    }
  }, [patientBpDetail, duration])

  const handleRangeChange = (date, dateString) => {
    setDateRange(date)
    if (!date) {
      setDuration("month")
      dispatch(
        getBpDetails({
          patientId,
          programId,
          startDate: durationObj[duration],
          endDate: currentDate
        })
      )
    } else if (date && date?.length === 2) {
      setDuration(null)
      dispatch(
        getBpDetails({
          patientId,
          programId,
          startDate: moment.utc(dateString[0]).startOf("day").toDate().toISOString(),
          endDate: moment.utc(dateString[1]).endOf("day").toDate().toISOString()
        })
      )
    }
  }
  const handleDuration = e => {
    setDuration(e.target.value)
    if (dateRange !== null) setDateRange(null)
  }

  return (
    <Card title="">
      <div className="patient-bp-data">
        <div className="flex flex-col items-start justify-center gap-3 sm:flex-row sm:justify-between sm:items-center sm:gap-0 mb-4">
          <h2 className="text-xl">Blood Pressure Reading</h2>
          {bpData?.length > 0 ? null :
            <div className="my-3 pb-2">
              <p className="font-semibold mb-2">Duration Pickers:</p>
              <div className="flex items-center gap-4">
                <Radio.Group value={duration} onChange={handleDuration}>
                  <Radio.Button value="week">Week</Radio.Button>
                  <Radio.Button value="month">Month</Radio.Button>
                  <Radio.Button value="year">Year</Radio.Button>
                </Radio.Group>
                <DatePicker.RangePicker
                  disabledDate={current => current > moment()}
                  value={dateRange}
                  onChange={handleRangeChange}
                />
              </div>
            </div>
          }
        </div>
        {loading ? (
          <Loading />
        ) : bpData && bpData.length ? (
          <>
            <div>
              {/* <div className="flex gap-4 mb-5">
                <div className="border-[1px] border-current rounded p-4 text-center">
                  <h6 className="text-secondary mb-2">SYS/DIA mmHg</h6>
                  <p className="font-bold text-4xl mb-0">
                    {bpData?.[0]?.systolicPressure} / {bpData?.[0]?.diastolicPressure}
                  </p>
                </div>
                <div className="border-[1px] border-current rounded p-4 text-center">
                  <h6 className="text-secondary mb-2">BPM</h6>
                  <p className="font-bold text-4xl mb-0">{bpData?.[0]?.heartRate}</p>
                </div>
              </div> */}
              <PatientProgressbar
                patientId={patientId}
                bpData={bpData}
                setBpCustomParameterTab={setBpCustomParameterTab}
              />
            </div>
            <Card title="Patient Reading Chart" className="my-4"
              extra={<div className="my-3 pb-2">
                <p className="font-semibold mb-2">Duration Pickers:</p>
                <div className="flex items-center gap-4">
                  <Radio.Group value={duration} onChange={handleDuration}>
                    <Radio.Button value="week">Week</Radio.Button>
                    <Radio.Button value="month">Month</Radio.Button>
                    <Radio.Button value="year">Year</Radio.Button>
                  </Radio.Group>
                  <DatePicker.RangePicker
                    disabledDate={current => current > moment()}
                    value={dateRange}
                    onChange={handleRangeChange}
                  />
                </div>
              </div>}
            >
              <ReactApexChart
                height={300}
                type="line"
                {...lineChartOptions(graphSeries, graphCategories)}
              />
            </Card>
            <Card title="Patient Reading">
              <Table columns={columns} dataSource={bpData} rowKey="index" />
            </Card>
          </>
        ) : (
          <Alert type="error" message={errorMsg} />
        )}
      </div>
    </Card>
  )
}

export default PatientBPComponent
