import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import moment from "moment"
import { Button, Table, Tooltip, Card, DatePicker } from "antd"
import { useDispatch, useSelector } from "react-redux"
import {
  getccmCareplanDataActions,
  getCcmArchivePlansActions
} from "../../../redux/actions/ccmActions"
import { EyeOutlined } from "@ant-design/icons"
import { saveSelectedDateRange } from "../../../redux/actions/patientAction"
import MyDatePicker from "../../../utils/MyDatePicker"

const { RangePicker } = MyDatePicker
import { USA_DATE_FORMAT } from "../../../utils/usaDateFormat"

const setRows = arr => {
  const newData = []
  let sno = 1
  arr?.map(row => {
    const { id, planDate, plan } = row
    newData.push({
      planId: id,
      planDate,
      plan,
      key: sno,
      sNo: sno
    })
    sno += 1
    return id
  })
  return newData
}

const CcmPlanHistoryList = () => {
  const [ccmData, setCcmData] = useState([])

  const { id, orgId, orgName } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { loading: ccmLoading } = useSelector(state => state.ccmPatientCarePlan)
  const { loading: ccmArchiveLoading } = useSelector(state => state.ccmArchivePlans)

  const selectedDateRange = useSelector(state => state.selectedDateRange)

  const [range, setRange] = useState(
    selectedDateRange?.CCMPlanHistoryRange
      ? [
          selectedDateRange?.CCMPlanHistoryRange?.startDate,
          selectedDateRange?.CCMPlanHistoryRange?.endDate
        ]
      : [moment(new Date()).subtract(1, "month").startOf("day"), moment(new Date()).endOf("day")]
  )
  // useEffect(() => {
  //   if (id) {
  //     dispatch(getccmCareplanDataActions({ patientId: id })).then(({ details }) => {
  //       setCcmData(setRows(details))
  //     })
  //   }
  // }, [dispatch, id])
  useEffect(() => {
    if (id) {
      dispatch(
        getCcmArchivePlansActions({
          patientId: id,
          startDate: moment(range[0]).format("YYYY-MM-DDTHH:mm:ss"),
          endDate: moment(range[1]).format("YYYY-MM-DDTHH:mm:ss")
        })
      ).then(res => {
        // Check if response is iterable
        if (Array.isArray(res)) {
          setCcmData(setRows([...res]))
        } else {
          console.error("Response is not iterable:", res)
        }
      })
    }
  }, [])

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: "Plan Date",
      dataIndex: "planDate",
      key: "planDate",
      render: planDate => <p>{moment(planDate).format(USA_DATE_FORMAT)}</p>,
      sorter: (a, b) => a.planDate.localeCompare(b.planDate)
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, { plan }) => (
        <p className="capitalize">
          {plan?.addedBy ? `${plan?.addedBy?.name} (${plan?.addedBy?.userType})` : ""}
        </p>
      )
    },
    {
      title: "Actions",
      render: (_, { planId, planDate, plan }) => (
        <Tooltip title="View Plan">
          <Button
            type="primary"
            shape="circle"
            icon={<EyeOutlined />}
            onClick={() =>
              navigate(`/${orgId}/${orgName}/history-view-plan`, { state: { patientId: id, planDate, plan } })
            }
          />
        </Tooltip>
      )
    }
  ]

  // const handleRangeChange = (date, dateString) => {
  //   if (date && date?.length === 2) {
  //     dispatch(
  //       getCcmArchivePlansActions({
  //         patientId: id,
  //         startDate: moment.utc(dateString[0]).startOf("day").toDate().toISOString(),
  //         endDate: moment.utc(dateString[1]).endOf("day").toDate().toISOString()
  //       })
  //     ).then(res => {
  //       setCcmData(setRows([...res]))
  //     })
  //   } else {
  //     dispatch(getccmCareplanDataActions({ patientId: id })).then(({ details }) => {
  //       setCcmData(setRows([...details]))
  //     })
  //   }
  // }
  // Function to disable dates outside one month range
  const disabledDate = current => {
    if (!range || range.length === 0) {
      // If no range is selected yet, allow all dates
      return false
    }
    const tooLate = range[0] && current.diff(range[0], "days") > 32
    return !!tooLate || current > moment()
  }
  const handleDateReset = async () => {
    setRange([moment(new Date()).subtract(1, "month"), moment(new Date())])
    dispatch(
      saveSelectedDateRange(
        "CCMPlanHistoryRange",
        moment(new Date()).subtract(1, "month"),
        moment(new Date())
      )
    )
    dispatch(
      getCcmArchivePlansActions({
        patientId: id,
        startDate: moment.utc().subtract(1, "month").startOf("day").toDate().toISOString(),
        endDate: moment.utc().endOf("day").toDate().toISOString()
      })
    ).then(res => {
      setCcmData(setRows([...res]))
    })
  }
  const handleRangeChange = async (date, dateString) => {
    if (dateString?.[0]?.length && dateString?.[1]?.length)
      setRange([moment(dateString[0]).startOf("day"), moment(dateString[1]).endOf("day")])
  }
  const handleDateSelection = async () => {
    if (range && range?.length === 2) {
      dispatch(saveSelectedDateRange("CCMPlanHistoryRange", range?.[0], range?.[1]))

      dispatch(
        getCcmArchivePlansActions({
          patientId: id,
          startDate: moment(range[0]).format("YYYY-MM-DDTHH:mm:ss"),
          endDate: moment(range[1]).format("YYYY-MM-DDTHH:mm:ss")
        })
      ).then(res => {
        setCcmData(setRows([...res]))
      })
    } else {
      dispatch(
        getCcmArchivePlansActions({
          patientId: id,
          startDate: moment.utc().subtract(1, "month").startOf("day").toDate().toISOString(),
          endDate: moment.utc().endOf("day").toDate().toISOString()
        })
      ).then(res => {
        setCcmData(setRows([...res]))
      })
    }
  }
  return (
    <div>
      <Card
        title={<h4 className="text-xl">Plan History</h4>}
        extra={
          <>
            <RangePicker
              value={range}
              onChange={handleRangeChange}
              allowClear={false}
              format={USA_DATE_FORMAT}
              disabledDate={disabledDate}
            />
            <Button
              type="primary"
              onClick={handleDateSelection}
              disabled={ccmArchiveLoading || ccmLoading}
              className="ml-2">
              Confirm
            </Button>
            <Button
              type="default"
              onClick={handleDateReset}
              disabled={ccmArchiveLoading || ccmLoading}
              className="ml-2 text-danger border-danger">
              Reset
            </Button>
          </>
        }>
        <Table
          size="small"
          columns={columns}
          dataSource={ccmData}
          scroll={{ x: "max-content" }}
          loading={ccmArchiveLoading || ccmLoading}
        />
        <p className="font-semibold">
          Use the Date picker in header to get plans in a specific date range.
        </p>
      </Card>
    </div>
  )
}

export default CcmPlanHistoryList
