import React, { useEffect, useRef } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"

import { Button } from "antd"
import ReactToPrint from "react-to-print"

import { useSelector, useDispatch } from "react-redux"
import { getPatientInfo } from "../../../redux/actions/patientAction"

import Loading from "../../layouts/Loading/Loading"
import PatientPlanHeader from "../../PatientDetails/PatientPlanHeader"
import TriageViewFormInput from "../Triage/weightScaleModals/TriageViewFormInput"

const ViewToPrintTriage = () => {
  const { id, type, orgId, orgName } = useParams()
  const { loading, patientInfoDetail } = useSelector(state => state.patientInfo)
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const location = useLocation()
  const componentRef = useRef()
  const list = location.state && location.state.list

  useEffect(() => {
    if (!list || list === null) {
      navigate(`/${orgId}/${orgName}/rpm-critical-patient`)
    }
  }, [location.state])

  useEffect(() => {
    dispatch(getPatientInfo({ patientId: id }))
  }, [id])

  const generatePageNum = () => {
    //let totalPages = Math.ceil(document.body.scrollHeight / 1123);  //842px A4 pageheight for 72dpi, 1123px A4 pageheight for 96dpi,
    let totalPages = Math.ceil(Object.keys(list ?? {}).length / 2) + 1
    for (let i = 1; i <= totalPages; i++) {
      let pageNumberDiv = document.createElement("div")
      let pageNumber = document.createTextNode("Page " + i + " of " + totalPages)
      pageNumberDiv.classList.add("page-num-div")
      pageNumberDiv.style.position = "absolute"
      pageNumberDiv.style.top = "calc((" + i + " * (297mm - 0.5px)) - 40px)" //297mm A4 pageheight; 0,5px unknown needed necessary correction value; additional wanted 40px margin from bottom(own element height included)
      pageNumberDiv.style.height = "16px"
      pageNumberDiv.appendChild(pageNumber)
      let parentDiv = document.getElementById("view-container")
      let originalDiv = document.getElementById("content")
      parentDiv.insertBefore(pageNumberDiv, originalDiv.nextSibling)
      pageNumberDiv.style.left = "calc(100% - (" + pageNumberDiv.offsetWidth + "px + 100px))"
      pageNumberDiv.style.backgroundColor = "#DBDBDB"
      pageNumberDiv.style.paddingBottom = "24px"
      pageNumberDiv.style.borderRadius = "6px"
    }
  }
  useEffect(() => {
    generatePageNum()
  }, [list])

  return (
    <>
      <div className="wrapper-print">
        <div id="content" className="no-hadow">
          <div
            className="view-container"
            id="view-container"
            ref={componentRef}
            style={{
              padding: "20px",
              overflowY: "auto"
            }}>
            {loading ? (
              <Loading />
            ) : (
              <PatientPlanHeader patientInfoDetail={patientInfoDetail} id={id} title={`${type} Report`} readingDetails={list?.readingDetails}/>
            )}
            <div className="d-flex justify-content-between w-100 py-2">
              <h4 className="Ish px-3 text-xl">Answers</h4>
              <div className="d-flex noprint">
                <div>
                  <Button danger onClick={() => navigate(-1)}>
                    Back
                  </Button>
                </div>
                <div>
                  <ReactToPrint
                    trigger={() => (
                      <Button type="primary" className="mx-2">
                        Print as PDF
                      </Button>
                    )}
                    content={() => componentRef.current}
                  />
                </div>
              </div>
            </div>

            <div className="list-print-div" style={{ counterIncrement: "page" }}>
              {Object.values(list ?? {})?.map((ques, index) => ((
                //below condition to ignore the reading details to questions
                !Array.isArray(ques)  ?
                <div
                  className="card w-100 mb-3 shadow p-4 no-hadow mt-4"
                  key={index}
                  style={{ pageBreakAfter: index % 2 == 0 ? "always" : "avoid", color: "black" }}>
                  <h4 className="form_heading fw-bold">{ques?.name}</h4>
                  <TriageViewFormInput ques={ques} />
                </div>: null)
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewToPrintTriage
