import { axiosGet, axiosPost } from "../axios"
import {
  GET_ALL_ONBOARD_PATIENT_STATISTICS_FAIL,
  GET_ALL_ONBOARD_PATIENT_STATISTICS_REQUEST,
  GET_ALL_ONBOARD_PATIENT_STATISTICS_SUCCESS,
  GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL,
  GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST,
  GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS,
  POST_BULK_MAIL_MESSAGE_PATIENT_FAIL,
  POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST,
  POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS,
  UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_FAIL,
  UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_REQUEST,
  UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_SUCCESS,
  UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL,
  UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST,
  UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS
} from "../types/selfOnboardingConstant"
import { userLogoutAction } from "./userActions"

export const getSelfOnboardingPendingPatientAction = () => async dispatch => {
  try {
    dispatch({
      type: GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST
    })
    const userInfo =
      localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
    const { data } = await axiosGet(
      `/api/cs/bulk-upload/onboardingStatus/caretaker/profiles/${userInfo?.id}/fetch`
    )
    dispatch({
      type: GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL,
      payload: error.response?.data?.details
    })
  }
}

export const getOrgOnboardPatientStatistics = () => async dispatch => {
  try {
    dispatch({
      type: GET_ALL_ONBOARD_PATIENT_STATISTICS_REQUEST
    })
    const userInfo =
      localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
    const { data } = await axiosGet(
      `/api/cs/bulk-upload/onboardingStatus/caretaker/${userInfo?.id}/stats`
    )

    dispatch({
      type: GET_ALL_ONBOARD_PATIENT_STATISTICS_SUCCESS,
      payload: data.details
    })

    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_ALL_ONBOARD_PATIENT_STATISTICS_FAIL,
      payload: error.response?.data?.detail
    })
  }
}

// UPDATE action for bulk caretaker for Active patient
export const updateBulkCaretakerActivePatientAction = formData => async dispatch => {
  try {
    dispatch({ type: UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_REQUEST })
    const userInfo =
      localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
    const { data } = await axiosPost(
      `/api/cs/patients/bulkMapCaretakers/${userInfo?.orgId}/update`,
      formData
    )
    dispatch({
      type: UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_FAIL,
      payload:
        Object.values(error?.response?.data?.details)[0] ?? error?.response?.data?.details?.message
    })
  }
}

// UPDATE action for bulk caretaker
export const updateSelfOnboardingPatientCaretakerAction = formData => async dispatch => {
  try {
    dispatch({ type: UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST })
    const userInfo =
      localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
    const { data } = await axiosPost(
      `/api/cs/bulk-upload/onboardingStatus/caretakers/${userInfo?.orgId}/update`,
      formData
    )
    dispatch({
      type: UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL,
      payload:
        Object.values(error?.response?.data?.details)[0] ?? error?.response?.data?.details?.message
    })
  }
}

// POST action for bulk mail/messages to the patients
export const postBulkMailMessagePatientAction = formData => async dispatch => {
  try {
    dispatch({ type: POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST })

    const { data } = await axiosPost(`/api/cs/bulk-upload/messages`, formData)
    dispatch({
      type: POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_BULK_MAIL_MESSAGE_PATIENT_FAIL,
      payload:
        Object.values(error?.response?.data?.details)[0] ?? error?.response?.data?.details?.message
    })
  }
}
